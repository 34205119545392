<!-- start update jobs -->
<div class="main-container">
    <div class="scrollbar-contain">
        <h1><strong>Update Details</strong></h1>
        <div class="contact-container" fxLayout="row" fxLayoutGap="20px">
            <div class="contact-header" fxLayout="row">
                <h1>Contact</h1>
            </div>
            <div class="contact-details">
                <div>
                    <h1>Company:</h1>
                    <h3>{{jobDetail?.companyName || '--'}}</h3>
                </div>
                <div>
                    <h1>Phone:</h1>
                    <h3>{{(jobDetail?.mobileNumber | mask: '(000) 000-0000') || '--'}}</h3>
                </div>
                <div>
                    <h1>Email:</h1>
                    <h3>{{jobDetail?.email || '--'}}</h3>
                </div>
                <div>
                    <h1>Contact Person:</h1>
                    <h3>{{jobDetail?.firstName || '--'}} {{jobDetail?.lastName}}</h3>
                </div>
            </div>
        </div>
        <div class="form-container">
            <div class="create-jobs">
                <h3><strong>Job</strong></h3>
            </div>
            <div class="who">
                <mat-label><strong>Who can <strong id="can">apply</strong></strong>
                </mat-label>
            </div>
            <div class="individual" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.sm="row" fxLayoutAlign.sm="space-between center" fxLayout.xs="row" fxLayoutAlign.xs="space-between center">
                <div class="imge">
                    <mat-checkbox class="img-a" [(ngModel)]="individual" (input)="showOptions('INDIVIDUAL')">
                    </mat-checkbox>
                    <h3><strong>Individual</strong></h3>
                </div>
                <div class="imges">
                    <mat-checkbox class="img-b" [(ngModel)]="company" (input)="showOptions('COMPANY')"></mat-checkbox>
                    <h3><strong id="company">Company</strong></h3>
                </div>
            </div>
            <div class="form" fxlayout="column">
                <form class="form-page" [formGroup]="updateForm">
                    <mat-label><strong>Job Type:</strong></mat-label>
                    <mat-form-field appearance="outline" class="box">
                        <!-- {{jobCategoryGet}} -->
                        <mat-select formControlName="jobType" [placeholder]="jobCategoryGet ? jobData.jobType.categoryName :''">
                            <!-- <mat-option>{{item?.categoryName}}</mat-option> -->
                            <!-- <mat-option >{{jobData.jobType.categoryName}}
                            </mat-option> -->
                            <mat-option (click)="getSubJobList(item?.id)" [value]="item.id" *ngFor="let item of jobList">
                                {{item?.categoryName}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="subJobList.length">
                        <mat-label><strong>Equipment <strong id="type">Type:</strong></strong>
                        </mat-label>
                        <mat-form-field appearance="outline" class="box">
                            <mat-select formControlName="jobSubType" [placeholder]="subJobCategoryGet ? jobData.subJobType.subCatName :''">

                                <mat-option [value]="item?.id" *ngFor="let item of subJobList">{{item?.subCatName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <!-- <mat-label><strong>Duration <strong id="type">Type:</strong></strong>
                    </mat-label>
                    <mat-form-field appearance="outline" class="box">
                        <mat-select formControlName="durationType">
                            <mat-option [value]="item?.id" *ngFor="let item of durationType">{{item?.durationName}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field> -->
                    <mat-label><strong>Duration:</strong></mat-label>

                    <mat-form-field appearance="outline" class="box">
                        <input type="text" matInput (keypress)="service.numberOnly($event)" placeholder="5" formControlName="duration" min="0" />
                    </mat-form-field>
                    <mat-label><strong>City:</strong></mat-label>
                    <mat-form-field appearance="outline" class="box">
                        <input matInput placeholder="City" formControlName="city" ngx-google-places-autocomplete [(ngModel)]="options" [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" />
                    </mat-form-field>

                    <mat-label><strong>Start Date:</strong></mat-label>
                    <mat-form-field appearance="outline" class="box">
                        <input type="datetime-local" id="date-inp" class="date-point" matInput [min]="todayDate" (keypress)="$event.preventDefault()" formControlName="startDate" />
                        <img src="/assets/images/datepicker.ico" mat-card-sm-image alt="" class="cale-details" type="datetime-local">

                    </mat-form-field>
                    <!-- <mat-label><strong>Equipment <strong id="type">Type:</strong></strong>
                    </mat-label>
                    <mat-form-field appearance="outline" class="box">
                        <input class="new-equip" type="text" (keypress)="service.preventSpace($event)" matInput placeholder="Equipment Type" formControlName="equipmentType" />

                    </mat-form-field> -->
                    <mat-label><strong>Quantity:</strong></mat-label>
                    <mat-form-field appearance="outline" class="box">
                        <input type="text" (keypress)="service.numberOnly($event)" matInput placeholder="4" formControlName="quantity" />
                    </mat-form-field>
                    <mat-label><strong>Payment <strong id="type">Type:</strong></strong>
                    </mat-label>
                    <mat-form-field appearance="outline" class="box">
                        <mat-select formControlName="paymentType">
                            <mat-option [value]="item?.id" *ngFor="let item of paymentType">{{item?.paymentName}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                    <mat-label><strong>Payment:</strong></mat-label>
                    <mat-form-field appearance="outline" class="box">
                        <input type="text" (keypress)="service.numberOnly($event)" matInput placeholder="$4000" formControlName="payment" />
                    </mat-form-field>

                    <div class="notes" fxLayout="row" fxLayoutAlign="space-between" fxLayout.sm="row" fxLayoutAlign.sm="space-between" fxLayout.xs="row" fxLayoutAlign.xs="space-between" style="padding-bottom: 20px;">
                        <mat-label><strong class="notes-text">Notes:</strong></mat-label>
                        <mat-label><strong>Characters Remaining:{{250 - countChar()}}</strong></mat-label>
                    </div>

                    <mat-form-field appearance="none" class="box">
                        <textarea (keypress)="countChar()" formControlName="notes" rows="8" matInput maxlength="250" class="text-area"></textarea>
                    </mat-form-field>
                    <div class="button" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout.sm="column" fxLayoutAlign.sm="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="space-between center">
                        <button mat-button style="border: 1px solid #0000ff" mat-dialog-close="true" class="create-btn" color="primary" routerLink='/created-jobs'>
                            Cancel
                        </button>
                        <button mat-raised-button class="create-btn" color="primary" [mat-dialog-close]="data['showmodal']" [mat-dialog-close]="showmodal" (click)="onUpdate()">
                            Update
                        </button>
                    </div>
                    <div class="button" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout.sm="column" fxLayoutAlign.sm="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="space-between center">
                        <button *ngIf="jobStatus=='OPEN'" mat-button style="border: 1px solid #0000ff" [mat-dialog-close]="jobStatus" class="create-btn" color="primary" (click)="closejobs(closeId)">
                            Close This Job
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- end update jobs-->