<mat-card class="mat-elevation-z0 bdr mat-dialog-container">
    <div class="main">
        <!--Heading -->
        <div class="top-text">
            <mat-card-title class="sign-text">Details </mat-card-title>
        </div>
        <div class="input-field">
            <mat-card class="bord">
                <!-- Heading -->
                <div class="top-text">
                    <h2>Contacts</h2>
                </div>
                <!-- Company Details -->
                <mat-card-header>
                    <mat-card-title>Company:<span class="sub-head">{{viwejobs?.hirerDetails?.companyName || '---'}}</span>
                    </mat-card-title>
                    <mat-card-title>Phone:<span class="sub-head">{{(viwejobs?.hirerDetails?.mobileNumber | mask: '(000)
              000-0000')|| '---'}}</span>
                    </mat-card-title>
                    <mat-card-title>Email:<span class="sub-head">
              {{viwejobs?.hirerDetails?.email || '---'}}</span></mat-card-title>
                    <mat-card-title>Contact Person:<span class="sub-head">
              {{viwejobs?.hirerDetails?.firstName || '--'}} {{viwejobs?.hirerDetails?.lastName || '--'}}</span>
                    </mat-card-title>
                </mat-card-header>
            </mat-card>
        </div>
        <div class="next-border">
            <mat-card class="next-bord">
                <!-- Heading -->
                <div class="top-text">
                    <h2>Job</h2>
                </div>
                <!-- Jobs Details -->
                <mat-card-header>
                    <mat-card-title class="next-head">Job Type:<span class="sub-head">{{viwejobs?.jobType?.categoryName ||
              '---'}}</span></mat-card-title>
                    <mat-card-title class="next-head">Equipment Type:<span class="sub-head">{{viwejobs?.subJobType?.subCatName ||
              '---'}}</span>
                    </mat-card-title>
                    <mat-card-title class="next-head">Duration:<span class="sub-head">{{viwejobs?.duration || '---'}}
             </span></mat-card-title>
                    <mat-card-title class="next-head">Start Date:<span class="sub-head">{{viwejobs?.startDate |
              date:'shortDate' || '---'}}</span>
                    </mat-card-title>
                    <mat-card-title class="next-head">Location:<span class="sub-head">{{viwejobs?.city || '---'}}</span>
                    </mat-card-title>
                    <!-- <mat-card-title class="next-head">Equipment Type:<span class="sub-head">{{viwejobs?.equipmentType ||
              '---'}}</span>
                    </mat-card-title> -->
                    <mat-card-title class="next-head">Quantity:<span class="sub-head">{{viwejobs?.quantity || '---'}}</span>
                    </mat-card-title>
                    <mat-card-title class="next-head">Price:<span class="sub-head">${{viwejobs?.payment || '---'}}</span>
                    </mat-card-title>
                    <mat-card-title class="next-head">Payment Type:<span class="sub-head">{{viwejobs?.paymentTypes?.paymentName ||
              '---'}}</span>
                    </mat-card-title>
                </mat-card-header>
            </mat-card>
        </div>
        <!-- Button -->
        <div class="down-button">
            <button mat-button mat-dialog-close="true" class="cancel">Cancel</button>
        </div>
    </div>
</mat-card>