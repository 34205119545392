import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';
@Component({
  selector: 'app-advertisment',
  templateUrl: './advertisment.component.html',
  styleUrls: ['./advertisment.component.scss']
})
export class AdvertismentComponent implements OnInit {
  advertisment: any = []

  slideConfig = {
    "autoplay": true,
    "autoplaySpeed": 10000,
    "dots": false,
    "loop": true,
    "arrow": true,
    "infinite": true,
    "speed": 3000,
    "slidesToShow": 1,
    "slidesToScroll": 1,
  };
  latitude: any;
  longitude: any;

  constructor(private commonserice: CommonService) { }

  ngOnInit(): void {
    this.getCurrentLocation()
  }
  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((res) => {
        this.latitude = res.coords.latitude;
        this.longitude = res.coords.longitude;
        this.getAdvertisment(this.latitude, this.longitude)
      }, (err) => {
      });
    } else {
      // console.log("Geolocation is not supported by this browser.");
    }
  }
  getAdvertisment(lat, long) {
    let url = `user/advertismentList?latitude=${lat}&longitude=${long}`
    this.commonserice.getApi(url, 0).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.advertisment = res.result.result;
        this.commonserice.hideSpinner()
      }
      else {
        this.commonserice.hideSpinner()
      }
    },

    )
  }

}
