import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from "src/app/provider/common.service";
import { Router } from "@angular/router";
import { MatPaginator } from '@angular/material/paginator';
import { AfterViewInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeUpdateDetailsComponent } from 'src/app/modal/employee/employee-update-details/employee-update-details.component';
import { DeleteComponent } from 'src/app/modal/delete/delete.component';



import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';


export interface DialogData {
  jobId: number
}
@Component({
  selector: 'app-created-jobs',
  templateUrl: './created-jobs.component.html',
  styleUrls: ['./created-jobs.component.scss']
})


export class CreatedJobsComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [
    "JobType",
    "subJobType",
    "WhoCanApply",
    "Duration",
    "Start_date",
    "City",
    "Payment_Type",
    "Payment",
    "JobStatus",
    "Applicant",
    "blank",
    "delete",
  ];

  dataSource: any = [];
  currentPage: number = 0;
  itemPerPage: number = 25;
  length: any;
  pageSize = 25;
  startDate: any = []
  pageEvent: PageEvent;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort;
  constructor(private route: Router, private service: CommonService, public dialog: MatDialog, public datePipe: DatePipe) { }

  ngOnInit(): void {
    this.createdJobs()
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort
  }
  navigateToApplicantInformation(id, date) {
    this.route.navigate(['/applicants-info'], { queryParams: { Id: id, startDate: date } });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  map(id) {
    this.route.navigate(["/google-map"], { queryParams: { Id: id } });
  }

  deleteDialog(id) {
    let dialogConfig = { hasBackdrop: false, skipHide: true };
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: "430px",
      height: "290px",
      autoFocus: false,
      data: { dataKey: 'job', isSuccesfullyDeleted: false, url: "jobs/deleteJob?id=" + id }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.createdJobs()
      }
    });
  }


  createdJobs() {
    let url = "jobs/listJobs?page=" + (this.currentPage + 1) + "&limit=" + this.itemPerPage
    this.service.showSpinner()
    this.service.getApi(url, 1).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.dataSource = res["result"]["rows"]
        this.length = res["result"]["count"]
        let i = 0
        for (let item of res["result"]["rows"]) {
          this.startDate[i] = String(item["startDate"]).replace('Z', '')
          // this.startDate[i] = this.datePipe.transform(item["startDate"])

          i++
        }



        this.service.hideSpinner()
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        // this.service.errorToast(res['responseMessage'])
      }
    }
    )
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {


    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  changePage(event) {
    this.currentPage = this.pageEvent.pageIndex
    this.itemPerPage = this.pageEvent.pageSize
    this.createdJobs()

  }
  viewJob(id,status) {
if(status=='OPEN'){
  const dialogRef = this.dialog.open(EmployeeUpdateDetailsComponent, {
    width: "600px",
    height: "fit-content",
    autoFocus: false,
    data: { jobId: id }
    // maxHeight: "90vh",
  });
  dialogRef.afterClosed().subscribe(result => {

    if (result == false) {
      this.createdJobs()
    }
    else if(result=="OPEN"){
      this.createdJobs()
    }
    ;
  });
}

  }
}
