import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  helpForm: FormGroup;
  profileData: any = []
  status: boolean = false
  email: string
  name: string
  constructor(private router: Router, public dialog: MatDialog, public service: CommonService) { }

  ngOnInit(): void {
    this.helpFormValidation()
  }
  helpFormValidation() {
    this.helpForm = new FormGroup({
      name: new FormControl("", [
        Validators.required,

      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/
        )
      ]),
      subject: new FormControl("", [
        Validators.required,
      ]),

      description: new FormControl("", [
        Validators.required
      ])
    });
    if (this.service.isLoggedIn()) {
      this.getProfile()
    }
  }
  getProfile() {
    let url = `user/getProfile`
    this.service.showSpinner()
    this.service.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.email = res["result"]["email"]
        this.name = `${res["result"]["firstName"] || ''}  ${res["result"]["lastName"] || ''}`
        this.service.hideSpinner()
        this.status = true


      }
      else {
        this.service.hideSpinner()

      }
    })
  }
  helpApi() {
    let url = `user/help?name=${this.helpForm.value.name}&email=${this.helpForm.value.email}&subject=${this.helpForm.value.subject}&description=${this.helpForm.value.description}`
    this.service.showSpinner()
    this.service.postApi(url, {}, 0).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.service.hideSpinner()
        this.service.successToast("Thank You for contact. Our team will contact you shortly")

        if (this.service.isLoggedIn()) {

          this.helpForm.patchValue({
            subject: "",
            description: ""
          })

        }

        else {
          this.helpForm.reset()
        }
        this.router.navigate(['/homepage'])
      }
      else {
        this.service.hideSpinner()
        // this.service.successToast(res["responseMessage"])
      }
    })
  }
  navigateToTermsOfServices() {
    this.router.navigate(['/terms-of-service']),
      this.dialog.closeAll()
  }
}
