import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ApplicantDetailsComponent } from 'src/app/modal/applicant/applicant-details/applicant-details.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/provider/common.service";
import { DeleteComponent } from 'src/app/modal/delete/delete.component';
import { DeclineComponent } from 'src/app/modal/decline/decline.component';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { element } from 'protractor';

@Component({
  selector: 'app-applicants-info',
  templateUrl: './applicants-info.component.html',
  styleUrls: ['./applicants-info.component.scss']
})
export class ApplicantsInfoComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    "Name",
    "Experience",
    "Location",
    "Interviewing",
    "blank",
    "delete",
  ];
  dataSource: any = [];
  currentPage: number = 1;
  length: any;
  itemPerPage: number = 25;
  pageSize = 25;
  pageEvent: PageEvent;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  jobId: any
  interviewScheduled: any = []
  index
  date: any
  dialogClose: any
  constructor(private dialog: MatDialog, private route: ActivatedRoute, private service: CommonService) {
    this.route.queryParams.subscribe((res) => {
      this.jobId = res.Id,
        this.date = res.startDate
    })
  }
  @ViewChild(MatPaginator) paginator: MatPaginator

  ngOnInit(): void {
    this.applicantInformationList()
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;

  }
  id: any
  navigaetToApplicantsInfo(applicantID, id, interview) {
    const dialogRef = this.dialog.open(ApplicantDetailsComponent, {
      width: '400px',
      height: 'max-content',
      autoFocus: false,
      data: { applicantId: applicantID, id: id, startDate: this.date, interviewStatus: interview }
    });
    dialogRef.afterClosed().subscribe(result => {
      var a = setInterval(
        () => {
          this.applicantInformationList()
          clearInterval(a);
        },
        3000

      );
    });
  }



  DeclineJob(id) {
    const dialogRef = this.dialog.open(DeclineComponent, {
      width: '400px',
      height: 'max-content',
      autoFocus: false,
      data: { jobId: id }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result == true) {
       
        this.applicantInformationList()
      }
    });
  }

  applicantInformationList() {

    let url = "jobs/applicantListJob"
    const data = {
      jobId: this.jobId,
      page: this.currentPage,
      limit: this.itemPerPage

    }
    this.service.showSpinner()
    this.service.postApi(url, data, 1).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.dataSource = res["result"]["rows"]
        console.log(this.dataSource);

        // bind address from zip code, appand key address from zip code
        this.dataSource.forEach(element => {
          const data = {
            zipCode: element.applicantDetails.zipCode
          }
          let responseZipCode = []

          let apiCategoryUrl = "user/validateZipCode";
          this.service.postApi(apiCategoryUrl, data, 0).subscribe((res: any) => {
            if (res.statusCode == 200) {
              responseZipCode = res["result"][`${element.applicantDetails.zipCode}`].filter((res) => {
                return res?.country_code == "US"
              })
              element.applicantDetails.address = responseZipCode[0]['city']
              // console.log(element)
            }
          })
        });

        this.length = res["result"]["count"]
        // this.index = 0
       
        // for (let item of res["result"]["rows"]) {
        //   if (item["applyStatus"] == 'PENDING' || item["applyStatus"] == 'DENIED') {
        //     this.interviewScheduled[this.index] = false
        //     // this.approveJob()
        //     // console.log('applicant',this.interviewScheduled[this.index]);

        //   }
        //   else {
        //     this.interviewScheduled[this.index] = true
        //     // console.log('hirer', this.interviewScheduled[this.index])
        //   }
        //   this.index++
        // }
        this.service.hideSpinner()
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    }
    )
  }



  approveJob(id) {

    let url = `jobs/acceptJobRequest?jobApplyId=${id}`
   
    this.service.showSpinner()
    this.service.postApi(url, {}, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.applicantInformationList()
        this.service.hideSpinner();
        //  this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    })
  }
  setPageSizeOptions(setPageSizeOptionsInput: string) {


    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  changePage(event) {
    this.currentPage = this.pageEvent.pageIndex
    this.itemPerPage = this.pageEvent.pageSize
    this.applicantInformationList()

  }
  inteviewerr() {
    this.service.errorToast("You must interview an individual before you can hire them.");
    this.applicantInformationList()
  }
  joberr() {
    this.service.errorToast('This job is closed')
  }

}
