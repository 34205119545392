<div class="main-container wrapper-content">
    <!-- <app-advertisment></app-advertisment> -->

    <mat-card class="mat-elevation-z0 bdr" aria-hidden="true">
        <div class="main">
            <div class="top-text">
                <mat-card-title class="sign-text">Help
                </mat-card-title>
            </div>
            <div class="input-field">
                <form [formGroup]="helpForm">

                    <!-- password Field -->
                    <input type="text" class="user-name" placeholder="Name" formControlName='name' maxlength="32">
                    <!-- eye Password Protector -->

                    <!-- validation -->
                    <div class="error" *ngIf="helpForm.get('name').hasError('required') && (helpForm.get('name').dirty)">
                        *Name is required.</div>
                    <div class="error" *ngIf="helpForm.get('name').hasError('pattern') && (helpForm.get('name').dirty)">
                        *Please enter valid name.</div>

                    <!-- confirm Password Field -->
                    <input type="email" class="user-password" placeholder="Email" formControlName='email' maxlength="75">
                    <!-- eye Password Protector -->
                    <div class="error" *ngIf="helpForm.get('email').hasError('required') && (helpForm.get('email').dirty)">
                        *Email is required.</div>
                    <div class="error" *ngIf="helpForm.get('email').hasError('pattern') && (helpForm.get('email').dirty)">
                        *Please enter valid email.</div>
                    <!-- validation -->

                    <!-- number Field  -->
                    <input type="text" class="user-password" placeholder="Subject" formControlName='subject' maxlength="60">
                    <!-- validation -->
                    <div class="error" *ngIf="helpForm.get('subject').hasError('required') && (helpForm.get('subject').touched ||helpForm.get('subject').dirty)  ">
                        *Subject is required.</div>

                    <textarea type="text" class="user-email" placeholder="Description" rows="10" formControlName='description'></textarea>
                    <div class="error" *ngIf="helpForm.get('description').hasError('required') && (helpForm.get('description').touched ||helpForm.get('description').dirty)  ">
                        *Description is required.</div>
                </form>
            </div>
            <!-- button -->
            <div class="down-button">
                <button mat-dialog-close="true" class="cancel" routerLink='/homepage'>Cancel</button>
                <button class="submit" mat-dialog-close (click)="helpApi()" [disabled]="helpForm.invalid">Submit</button>
            </div>
            <div class="term" (click)="navigateToTermsOfServices()">
                <a>Terms of Service</a>
            </div>
        </div>
    </mat-card>
</div>