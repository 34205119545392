<!-- signin Modal -->
<mat-card class="mat-elevation-z0 bdr mat-dialog-container" aria-hidden="true">
    <div class="main">
        <div class="top-text">
            <mat-card-title class="sign-text">Sign In
            </mat-card-title>
        </div>
        <div class="input-field">
            <form [formGroup]="signinForm">
                <!-- email Field -->
                <input type="text" class="user-name" placeholder="Email" formControlName='username' maxlength="60"
                    autocomplete="new-password">
                <!-- validation -->
                <span class="error"
                    *ngIf="signinForm.get('username').hasError('required') && (signinForm.get('username').touched ||signinForm.get('username').dirty)">
                    *Email id is required.</span>
                <span class="error"
                    *ngIf="signinForm.get('username').hasError('pattern') && (signinForm.get('username').touched||signinForm.get('username').dirty)">
                    *Please enter valid email id.</span>

                <!-- Password Field -->
                <input type="password" class="user-password" [type]="toggleEye ? 'password' : 'text'"
                    placeholder="Password" formControlName='pswd' maxlength="32" autocomplete="new-password">
                <!-- eye Password Protector -->
                <mat-icon matSuffix (click)="toggleEye = !toggleEye">{{toggleEye ? 'visibility_off' :
                    'visibility'}}
                </mat-icon>
                <!-- validation -->
                <span class="error"
                    *ngIf="signinForm.get('pswd').hasError('required') && (signinForm.get('pswd').touched ||signinForm.get('pswd').dirty)">
                    *Password is required.</span>
                <!-- button -->
                <div class="down-button">
                    <button mat-button class="cancel" mat-dialog-close="true">Cancel</button>
                    <button mat-button class="submit" [disabled]="!signinForm.valid" (click)="signIn()"
                        style="color: white;">Submit</button>
                </div>
            </form>
        </div>

        <!-- link Text -->
        <div class="link-text">
            <a mat-dialog-close (click)="signupDialog()">Sign Up</a>
            <img src="./assets/images/Line.png" alt="">
            <a mat-dialog-close (click)="forgotDialog()">Forgot Password</a>
        </div>
        <div class="term">
            <a (click)="navigateToTermsOfServices()">Terms of Service</a>
        </div>
    </div>
</mat-card>