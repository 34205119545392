import { CommonService } from "src/app/provider/common.service";
import { Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { EmployeeDetailsComponent } from "src/app/modal/employee/employee-details/employee-details.component";
import { MatPaginator, PageEvent } from "@angular/material/paginator";

@Component({
  selector: "app-view-jobs",
  templateUrl: "./view-jobs.component.html",
  styleUrls: ["./view-jobs.component.scss"],
})
export class ViewJobsComponent implements OnInit {
  displayedColumns: string[] = [
    "jobType",
    "Duration",
    "startDate",
    "city",
    "paymentType",
    "payment",
    "applicants",
    "view",
  ];
  dataSource: any = [];
  viewJobsData: any;
  id: number = 5
  currentPage: number = 0;
  itemPerPage: number = 25;
  length: any;
  pageSize = 25;
  pageEvent: PageEvent;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  constructor(
    private rout: Router,
    private service: CommonService,
    private dialog: MatDialog
  ) { }
  @ViewChild(MatPaginator) paginator: MatPaginator

  ngOnInit(): void {
    this.viewJobs();
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  map() {
    this.rout.navigate(["/google-map"]);
  }

  viewJobs() {
    let url = "jobs/viewJobs?id=" + this.id;
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.viewJobsData = res["result"]["row"];
        this.length = res["result"]["count"]
        this.service.hideSpinner();
        // this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    });
  }
  navigateToEmployeeDetails() {
    this.dialog.open(EmployeeDetailsComponent, {
      width: "450px",
      height: "580px",
    });
  }
  setPageSizeOptions(setPageSizeOptionsInput: string) {


    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  changePage(event) {
    this.currentPage = this.pageEvent.pageIndex
    this.itemPerPage = this.pageEvent.pageSize
    this.viewJobs()
  }
}

