import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { UserApplyJobComponent } from 'src/app/modal/user-apply-job/user-apply-job.component';
import { CommonService } from 'src/app/provider/common.service';

export interface PeriodicElement {

  subType: string;
  jobType: string;
  equipment: string;
  Duration: string;
  startDate: string;
  city: string;
  paymentType: string;
  payment: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-search-jobs',
  templateUrl: './search-jobs.component.html',
  styleUrls: ['./search-jobs.component.scss']
})


export class SearchJobsComponent implements OnInit, AfterViewInit, OnDestroy {
  search_type = ['All', 'Job type', 'Equipment type', 'Duration', 'Start date', 'City', 'Payment type', 'Payment']
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  displayedColumns: string[] = ['jobType', 'subType', 'equipment', 'Duration', 'startDate', 'city', 'paymentType', 'payment', 'apply'];
  length: any;
  options: any[];
  jobId: any;
  submitted: boolean = false;
  searchJobslist: any;
  dataSource: any = []
  // dataSource = new MatTableDataSource(ELEMENT_DATA);
  currentPage: number = 1;
  pageSize = 25;
  pageEvent: PageEvent;
  itemPerPage: number = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isSerched: boolean = false
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  city: any
  subjobId: any
  distance: any
  lat: any
  lang: any
  job_subJobType: any
  cityName: any
  setAddress: any
  public handleAddressChange(address: Address) {
    this.setAddress = address.formatted_address
    this.options = []
    // console.log(address.formatted_address);
    this.lang = address.geometry.location.lng()
    this.lat = address.geometry.location.lat()
    // console.log('lat ', this.lat, 'long', this.lang);
    this.options.push(address.formatted_address);
  }
  constructor(public router: Router, public commonService: CommonService, public dialog: MatDialog, private route: ActivatedRoute) {
    // isListBySearch() {


  }
  urlRoute(){
   if(this.router.url!='search-jobs'){
      this.router.navigateByUrl('search-jobs')
    }
   
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => {
      this.distance = res.maximumDistance
      this.city = res.city
      this.lat = res.latitude
      this.lang = res.longitude
      this.subjobId = res.subJobTypeId
      this.jobId = res.jobId
      this.searchJob()
    })
    this.job_subJobType = this.jobId && this.subjobId ? this.jobId + ":" + this.subjobId : this.jobId ? this.jobId : null
    // console.log(this.lang , this.lat);
    // if (this.lat === undefined && this.lang === undefined) {
    //   this.getCurrentLocation()
    // }

  }

  searchJob() {

    var a = {
      location: this.setAddress,
      radius: Number(this.distance),
      lat: this.lat,
      lang: this.lang

    }
    localStorage.setItem('searchDetails', JSON.stringify(a))

    if (this.commonService.isLoggedIn()) {
      this.getJobListBySerchAfterlogin()
    }
    else {
      this.getJobListBySerch()
    }
  }
  getCurrentLocation() {

    this.commonService.showSpinner()
    navigator.geolocation.getCurrentPosition((res) => {
      this.lat = res.coords.latitude
      this.lang = res.coords.longitude
      console.log("current", this.lang, this.lat);
      this.searchJob()
    }, (err) => {
      this.commonService.errorToast("Unable to fetch your location")
      this.commonService.hideSpinner()
    })
  }
  getJobListBySerch() {
    let apiReqData = {
      "maximumDistance": Number(this.distance) || 20,
      "latitude": this.lat,
      "longitude": this.lang,
      // "city": this.city,
      "jobField": this.jobId,
      "expertise": this.subjobId,
      "page": this.currentPage,
      "limit": this.itemPerPage
    }
    let apiReqUrl = "user/basicSearchWithoutToken";
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isSerched = true
        this.dataSource = res.result.results
        this.length = res.result.count
        this.commonService.hideSpinner()
        // this.commonService.successToast(res.responseMessage);

      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage);
      }
    }, (err: any) => {
      this.dataSource = []
      // this.commonService.errorToast("No job available");
    }
    )
  }

  //basicSearchWithToken
  getJobListBySerchAfterlogin() {
    let apiReqData = {
      "maximumDistance": Number(this.distance) || 20,
      "latitude": this.lat,
      "longitude": this.lang,
      // "city": this.city,
      "jobField": this.jobId,
      "expertise": this.subjobId,
      "page": this.currentPage,
      "limit": this.itemPerPage
    }
    let apiReqUrl = "user/basicSearchWithToken";
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isSerched = true
        this.dataSource = res.result.results
        // this.dataSource = res.result.results
        this.length = res.result.count
        this.commonService.hideSpinner()
        // this.commonService.successToast(res.responseMessage);
      }
      else {
        this.dataSource = []
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage);
      }
    }, (err: any) => {
      this.dataSource = []
      // this.commonService.errorToast("No job available");
    }
    )
  }


  reset() {
    this.distance = ""
    this.city = ""
    this.jobId = ""
    this.subjobId = ""
    this.getCurrentLocation()
  }
  sortData(data) {
    // this.dataSource.sort()
    let sortedArray = []
    switch (data) {
      case 'Job type':
        sortedArray = this.dataSource.sort((a, b) => (a.jobType.categoryName > b.jobType.categoryName) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'Sub type':
        sortedArray = this.dataSource.sort((a, b) => (a.subJobType.subCatName > b.subJobType.subCatName) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'Equipment':
        sortedArray = this.dataSource.sort((a, b) => (a.equipmentType > b.equipmentType) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'Duration':
        sortedArray = this.dataSource.sort((a, b) => (a.duration > b.duration) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'Start date':
        sortedArray = this.dataSource.sort((a, b) => (a.startDate > b.startDate) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'City':
        sortedArray = this.dataSource.sort((a, b) => (a.city > b.city) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'Payment type':
        sortedArray = this.dataSource.sort((a, b) => (a.paymentTypes.paymentName > b.paymentTypes.paymentName) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'Payment':
        sortedArray = this.dataSource.sort((a, b) => (a.payment > b.payment) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;
      default:
        this.getJobListBySerch()

    }

  }
  applyJobDialog(id, applyStatus) {
    const dialogRef = this.dialog.open(UserApplyJobComponent, {
      width: '450px',
      height: 'max-content',
      autoFocus: false,
      data: { dataKey: id, status: applyStatus }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

      if (result == 'applied') {
        this.searchJob()
      }
    });
  }
  setPageSizeOptions(setPageSizeOptionsInput: string) {


    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }
  changePage(event) {
    this.currentPage = this.pageEvent.pageIndex
    this.itemPerPage = this.pageEvent.pageSize
    this.getJobListBySerch()
    this.getJobListBySerchAfterlogin()
  }
  ngOnDestroy() {
    localStorage.removeItem("searchDetails")

  }
}
