<div class="wrapper-content">
    <!-- <app-advertisment></app-advertisment> -->
    <div class="mat-contact">
        <mat-card class="mat-elevation-z0">
            <div class="contact">
                <h1>{{pageName | titlecase}}</h1>
                <div class="contact-us">
                    <div class="contact-icon">
                        <img src="/assets/images/contact.png" alt="" />
                    </div>
                    <div class="contact-para">
                        <mat-card-content>
                            <p class="first-text" [innerHTML]="sanitizer.bypassSecurityTrustHtml(result?.description)"></p>
                        </mat-card-content>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>