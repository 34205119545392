import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ChangePasswordComponent } from "src/app/modal/auth/change-password/change-password.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CommonService } from "src/app/provider/common.service";
import { EventEmitterService } from "src/app/provider/event-emitter.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})


export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  jobfield: any = [];
  skill: any = [];
  myAccount: any = localStorage.getItem("userType")
  // regExPass =
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  regExEmail =
    /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i;
  // regExFirst = /^[a-zA-Z]{3,20}/;
  // regExLast = /^[a-zA-Z ]{2,14}/;
  regExPhone = /^[0-9]+$/;
  regExZip = /^\d{5}(?:[-\s]\d{4})?$/;
  value: string;
  userlogin: string;
  subCat: any;
  image: any = "assets/loader image/Reload-1s-200px.gif";
  viewValue: string;
  applicantProfile: any;
  hirerProfile: any;
  selected = "steak-0";

  distance: Distance[] = [
    { value: "steak-0", viewValue: "Miles" },
    // { value: "steak-1", viewValue: "Kilometer" },
    // { value: "steak-2", viewValue: "Meter" },
  ];
  profiledata: any;
  zipCode: any;
  zipCodeValidStatus: boolean = false;

  // closeIcon: boolean = false;
  // checkIcon: boolean = false

  constructor(public dialog: MatDialog, private route: Router, public commonService: CommonService, public eventemitter: EventEmitterService) { }

  ngOnInit(): void {
    // console.log(this.commonService.header());

    this.profileForm = new FormGroup({
      company_name: new FormControl("", Validators.required),
      first_name: new FormControl("", [
        Validators.required
      ]),
      last_name: new FormControl("", [
        Validators.required
      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(this.regExEmail),
      ]),
      certficate: new FormControl(""),
      phone: new FormControl("", [Validators.required, Validators.pattern(this.regExPhone)]),
      raduisSearch: new FormControl("", Validators.required),
      zipcode: new FormControl("", [Validators.required]),
      account_type: new FormControl("", Validators.required),
      year: new FormControl("", Validators.required),
      password: new FormControl("", [
        Validators.required
      ]),
      job_field: new FormControl("", Validators.required),
      skills: new FormControl("", Validators.required),
      equipment: new FormControl(""),
    });
    // this.getZipCode()
    this.getProfile();
  }
  changePasswordDialog() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: "490px",
      height: "370px",
      autoFocus: false
    });
  }

  uploadImg($event): void {
    var img = $event.target.files[0];
    this.updateImg(img)
  }

  updateImg(img) {
    var form = new FormData()
    form.append('uploaded_file', img)
    this.image = "assets/loader image/Reload-1s-200px.gif";
    let apiReqUrl = "user/uploadFile"
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(apiReqUrl, form, 1).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.image = res["result"]
        this.commonService.hideSpinner();
        this.commonService.successToast(res["responseMessage"]);
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"]);
      }
    })
  }

  editProfile() {
    let apiReqData = {
      "firstName": String(this.profileForm.value.first_name),
      "lastName": String(this.profileForm.value.last_name),
      "equipment": this.profileForm.value.equipment,
      "certificate": this.profileForm.value.certficate,
      "zipCode": String(this.profileForm.value.zipcode),
      "RadiusSearch": this.profileForm.value.raduisSearch,
      "yearsOfExperience": this.profileForm.value.year,
      "expertise": String(this.profileForm.value.skills),
      "mobileNumber": this.profileForm.value.phone,
      "jobField": String(this.profileForm.value.job_field).split('and')[0],
      "profilePic": String(this.image)
    }
    let apiReqUrl = "user/updateProfile"
    this.commonService.showSpinner();
    this.commonService.putApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.applicantProfile = res.result
        // this.commonService.changeLoginSub(this.applicantProfile.firstName || this.applicantProfile.lastName || this.applicantProfile.profilePic)
        this.commonService.showHeader.next('applicant')
        this.commonService.hideSpinner();
        this.commonService.successToast(res["responseMessage"]);
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"]);
      }
    }, (err: any) => {
      this.commonService.errorToast(err.error.responseMessage);
    })
  }

  verifyPassword() {
    if (!this.profileForm.value.password) {
      this.commonService.errorToast("Please enter your password for upate your profile.")
      return
    }
    let apiReqData = {
      "password": this.profileForm.value.password
    }
    let apiReqUrl = `user/verifyPassword?password=${this.profileForm.value.password}`
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.commonService.hideSpinner()
        this.profile()
        // this.commonService.successToast("Successfully updated")
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast("Please enter the valid password.")
      }

    }, (err: any) => {
      this.commonService.errorToast(err.error.responseMessage);
    })
  }
  updateHireprofile() {

    let apiReqData = {
      "companyName": String(this.profileForm.value.company_name),
      "firstName": String(this.profileForm.value.first_name),
      "lastName": String(this.profileForm.value.last_name),
      "equipment": this.profileForm.value.equipment,
      "zipCode": String(this.profileForm.value.zipcode),
      "mobileNumber": this.profileForm.value.phone,
      "RadiusSearch": this.profileForm.value.raduisSearch,
      "yearsOfExperience": this.profileForm.value.year,
      "expertise": String(this.profileForm.value.skills),
      "jobField": String(this.profileForm.value.job_field).split('and')[0],
      "profilePic": String(this.image)
    }
    let apiReqUrl = "user/updateHirerProfile"
    this.commonService.showSpinner();
    this.commonService.putApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.hirerProfile = res.result;
        // this.commonService.changeLoginSub(this.hirerProfile.firstName || this.hirerProfile.lastName || this.hirerProfile.profilePic)
        this.commonService.showHeader.next('hirer')
        this.commonService.hideSpinner();
        this.commonService.successToast(res["responseMessage"]);
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"]);
      }
    }, (err: any) => {
      this.commonService.errorToast(err.error.responseMessage);
    })
  }

  profile() {
    switch (this.commonService.header()) {
      case 'Hirer':
        this.updateHireprofile()
        break;

      case 'Applicant':
        this.editProfile()
        break;

      case '3rd_party_contractor':
        this.editProfile()
        break;


      default:
        // console.log(this.commonService.header())
        break;
    }
  }


  getProfile() {
    let url = "user/getProfile";
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.profileForm.patchValue({
          company_name: res["result"]["companyName"],
          first_name: res["result"]["firstName"],
          last_name: res["result"]["lastName"],
          email: res["result"]["email"],
          phone: res["result"]["mobileNumber"],
          zipcode: res["result"]["zipCode"],
          certficate: res["result"]["certificate"],
          account_type: res["result"]["account_Types"]["accountName"],
          year: res["result"]["yearsOfExperience"],
          raduisSearch: res["result"]["RadiusSearch"],
          job_field: res["result"]["jobField"],
          skills: res["result"]["expertise"],
          equipment: res["result"]["equipment"]

        });
        this.getZipCode()
        this.jobfields();
        this.image = res["result"]["profilePic"],
          this.profiledata = res["result"];
        this.commonService.hideSpinner();
        // this.commonService.successToast(res["responseMessage"]);
      } else {
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res["responseMessage"]);
      }
    });
  }
  jobfields() {
    let apiCategoryUrl = "jobs/categoryList";
    this.commonService.getApi(apiCategoryUrl, 0).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.jobfield = res["result"]
        let a = (res["result"]).filter((res) => {
          return res["categoryName"] === this.profileForm.value.job_field
        })
        this.subCatField(a[0]?.id)

        this.commonService.hideSpinner();
        // this.commonService.successToast(res["responseMessage"]);
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"]);
      }
    })
  }

  subCatField(id) {
    this.skill = []
    let apiSubCategoryUrl = `jobs/subCategoryList?id=${id}`
    this.commonService.getApi(apiSubCategoryUrl, 0).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.skill = res["result"]
        this.commonService.hideSpinner();
        // this.commonService.successToast(res["responseMessage"]);
      }
      else {
        this.commonService.hideSpinner();
      }
    })
  }
  // zip code validate function
  getZipCode() {
    let apiCategoryUrl = "user/validateZipCode";
    const data = {
      zipCode: this.profileForm.value.zipcode
    }
   
    if (String(data.zipCode).length < 5) {
      return this.zipCodeValidStatus = false
    }
    this.commonService.showSpinner()
    this.commonService.postApi(apiCategoryUrl, data, 0).subscribe((res: any) => {
      if (res.statusCode == 200) {
        // this.checkIcon = true
        // this.closeIcon = false;
        this.zipCode = res["result"]


        this.zipCode = this.zipCode[data.zipCode].filter((res) => {
          return res.country_code == "US"

        })
        console.log(this.zipCode);
        this.commonService.hideSpinner();
        this.zipCodeValidStatus = true;
        // this.commonService.successToast(res["responseMessage"]);
      }
      else {
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res["responseMessage"]);
        this.zipCodeValidStatus = false
      }
    }, err => {

      this.zipCodeValidStatus = false
    })
  }
}



interface Distance { }
