<!-- start slider homepage first coding-->
<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner main">
        <div class="carousel-item active">
            <img class="d-block w-100 tractor" src="https://designandconstructionnetwork.files.wordpress.com/2017/05/hard-hat.png" alt="Third slide">
        </div>
        <div class="carousel-item">
            <img class="d-block w-100 tractor" src="https://www.beekeeper.io/wp-content/uploads/2021/02/Blue-Collar-Workers-Productivity-Tools-Feature.jpg" alt="Fourth slide">
        </div>
        <div class="carousel-item">
            <img class="d-block w-100 tractor" src="https://miro.medium.com/max/1200/0*cqo82nqMT5C_4s6x.jpg" alt="Fifth slide">
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>
<!-- <app-advertisment></app-advertisment> -->

<!-- start coding for Blue Collar jobs for every day hardworking man -->

<div class="page-2">
    <div class="hardworking">
        <div class="every-day">
            <h1><strong>Blue collar jobs for the every day hard working man.</strong> </h1>
        </div>
        <div class="how">
            <h1 class="howit"><strong>How it works?</strong> </h1>
        </div>
    </div>
    <div [ngSwitch]="service.header()">
        <div *ngSwitchCase="'Applicant'" class="profile" fxlayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-between center">
            <div class="signup" *ngIf="!service.isLoggedIn()" (click)="signupDialog()">
                <a class="btns"><img class="circle" src="assets/images/circle.png" alt=""><img class="plus" mat-card-xs-image src="assets/images/plus.png" alt=""></a>
                <h1 *ngIf="!service.isLoggedIn()">Sign Up</h1>

                <h3>Create an account. This will allow you <br>to review and apply for jobs </h3>
            </div>
            <div class="signup" *ngIf="service.isLoggedIn()" routerLink="/profile">
                <a class="btns"><img class="circle" src="assets/images/circle.png" alt=""><img class="plus" mat-card-xs-image src="assets/images/plus.png" alt=""></a>

                <h1 *ngIf="service.isLoggedIn()">Complete Profile</h1>
                <h3>Create an account. This will allow you <br>to review and apply for jobs </h3>
            </div>

            <div class="search-job" (click)="navigateToSearchJob('1')">
                <a class="btns"><img class="circle" src="assets/images/circle.png" alt=""><img class="plus" src="assets/images/search.png" alt=""></a>
                <h1>Search Jobs</h1>
                <h3>Searching for jobs is easy. Set your<br> radius for travel, select your job <br>type, and click on search
                </h3>
            </div>
            <div class="apply" (click)="openSignInDialog()">
                <a class="btns"><img class="circle" src="assets/images/circle.png" alt=""><img class="plus" src="assets/images/right.png" alt=""></a>
                <h1>Apply</h1>
                <h3>Found a job you like. Click on apply and<br> we will forward your contact details to<br> the hiring manager.
                </h3>
            </div>
        </div>
        <!-- case 1 end -->
        <div *ngSwitchCase='"Hirer"' class="profile" fxlayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-between center">
            <div class="signup" *ngIf="!service.isLoggedIn()" (click)="signupDialog()">
                <a class="btns"><img class="circle" src="assets/images/circle.png" alt=""><img class="plus" mat-card-xs-image src="assets/images/plus.png" alt=""></a>
                <h1 *ngIf="!service.isLoggedIn()">Sign Up</h1>

                <h3>Create an account. This will allow you <br>to review and apply for jobs </h3>
            </div>
            <div class="signup" *ngIf="service.isLoggedIn()" routerLink="/profile">
                <a class="btns" routerLink="/profile"><img class="circle" src="assets/images/circle.png" alt=""><img class="plus" mat-card-xs-image src="assets/images/plus.png" alt=""></a>

                <h1 *ngIf="service.isLoggedIn()">Complete Profile</h1>
                <h3>Complete profile. This will allow you <br>to craete and post jobs </h3>
            </div>

            <div class="search-job" routerLink="/closed-jobs">
                <a class="btns"><img class="circle" src="assets/images/circle.png" alt=""><img class="plus" src="assets/images/search.png" alt=""></a>
                <h1>Closed Job</h1>
                <h3>Closed Job. Click on closed to view all closed job.
                </h3>
            </div>
            <div class="apply" routerLink='/created-jobs'>
                <a class="btns"><img class="circle" src="assets/images/circle.png" alt=""><img class="plus" src="assets/images/right.png" alt=""></a>
                <h1>My Jobs</h1>
                <h3>My jobs. Click on jobs and<br>get all your posetd job
                </h3>
            </div>
        </div>
        <!-- case 2 end -->

        <!-- default start -->
        <div *ngSwitchDefault class="profile" fxlayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-between center">
            <div class="signup" *ngIf="!service.isLoggedIn()" (click)="signupDialog()">
                <a class="btns"><img class="circle" src="assets/images/circle.png" alt=""><img class="plus" mat-card-xs-image src="assets/images/plus.png" alt=""></a>
                <h1 *ngIf="!service.isLoggedIn()">Sign Up</h1>

                <h3>Create an account. This will allow you <br>to review and apply for jobs </h3>
            </div>
            <div class="signup" *ngIf="service.isLoggedIn()" routerLink="/profile">
                <a class="btns"><img class="circle" src="assets/images/circle.png" alt=""><img class="plus" mat-card-xs-image src="assets/images/plus.png" alt=""></a>

                <h1 *ngIf="service.isLoggedIn()">Complete Profile</h1>
                <h3>Create an account. This will allow you <br>to review and apply for jobs </h3>
            </div>

            <div class="search-job" (click)="navigateToSearchJob()">
                <a class="btns"><img class="circle" src="assets/images/circle.png" alt=""><img class="plus" src="assets/images/search.png" alt=""></a>
                <h1>Search Jobs</h1>
                <h3>Searching for jobs is easy. Set your<br> radius for travel, select your job <br>type, and click on search
                </h3>
            </div>
            <div class="apply" (click)="openSignInDialog()">
                <a class="btns"><img class="circle" src="assets/images/circle.png" alt=""><img class="plus" src="assets/images/right.png" alt=""></a>
                <h1>Apply</h1>
                <h3>Found a job you like. Click on apply and<br> we will forward your contact details to<br> the hiring manager.
                </h3>
            </div>
        </div>
    </div>

</div>