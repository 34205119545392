<!-- start create jobs coding -->
<div class="main-container">


    <div class="form-container">
        <div class="create-jobs">
            <h1><strong>Create Jobs</strong></h1>
        </div>
        <div class="who">
            <h2><strong>Who can apply</strong></h2>
        </div>
        <div class="individual" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.sm="column" fxLayoutAlign.sm="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="space-between center">
            <h3><strong>Individual</strong></h3>
            <h3><strong>Company</strong></h3>
        </div>
        <div class="icon" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.sm="start" fxLayoutAlign.xs="start" fxLayout.sm="column" fxLayout.xs="column">
            <mat-checkbox class="img-a" [(ngModel)]="individual" (input)="showOptions('INDIVIDUAL')"></mat-checkbox>
            <mat-checkbox class="img-b" [(ngModel)]="company" (input)="showOptions('COMPANY')"></mat-checkbox>
        </div>
        <div class="form" fxlayout="column">
            <form [formGroup]="createJobForm">
                <mat-label><strong>Job Type</strong></mat-label>
                <mat-form-field appearance="outline" class="box">
                    <mat-select formControlName="jobType">
                        <mat-option (click)="getSubJobList(item?.id)" [value]="item.categoryName" *ngFor="let item of jobList">
                            {{item?.categoryName || '---'}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <ng-container *ngIf="subJobList.length">
                    <mat-label><strong>Equipment Type</strong></mat-label>
                    <mat-form-field appearance="outline" class="box">
                        <mat-select formControlName="jobSubType">
                            <!-- <mat-option (click)="getSubJobId(item?.id)" [value]="item?.subCatName" *ngFor="let item of subJobList">
              {{item?.subCatName || '---'}}</mat-option> -->
                            <mat-option [value]="item?.id" *ngFor="let item of subJobList">
                                {{item?.subCatName || '---'}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>

                <!-- <mat-label><strong>Duration Type</strong></mat-label>
                <mat-form-field appearance="outline" class="box">
                    <mat-select formControlName="durationType">
                        <mat-option (click)="getDurationTypeId(item?.id)" [value]="item?.durationName" *ngFor="let item of durationType">{{item?.durationName || '---'}}
                        </mat-option>

                    </mat-select>
                </mat-form-field> -->
                <mat-label><strong>Duration</strong></mat-label>

                <mat-form-field appearance="outline" class="box">
                    <input type="tel" matInput (keypress)="service.numberOnly($event)" #f (keypress)="numberOnlyDuration(f.value)" placeholder="Duration" maxlength="9" formControlName="duration" min="0" autocomplete="off" [value]="createJobForm.value.durationType=='Permanent'?0:''"
                        [readonly]="createJobForm.value.durationType=='Permanent'?true:false" />
                </mat-form-field>
                <mat-label><strong>City</strong></mat-label>
                <mat-form-field appearance="outline" class="box">
                    <!-- <input matInput placeholder="Greely" formControlName="city" (input)="cityLatLong()" /> -->
                    <!-- <input matInput placeholder="Greely" formControlName="city" maxlength="60" autocomplete="off" /> -->
                    <input matInput placeholder="City" formControlName="city" ngx-google-places-autocomplete [(ngModel)]="options" [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" />
                </mat-form-field>
                <!-- <button type="button" mat-raised-button class="find-btn" (click)="checkCity()">Find</button> -->

                <!-- map location heading  -->
                <div class="notes" fxLayout="row" fxLayoutAlign="space-between" fxLayout.sm="row" fxLayoutAlign.sm="space-between" fxLayout.xs="row" fxLayoutAlign.xs="space-between">
                    <mat-label><strong>Job Map Location</strong></mat-label>
                    <!-- location marker -->
                    <!-- <img class="map-icon" (click)="getLocation();getLocationmap()" mat-card-xs-image
              src="./assets/images/Google-map-icon.png" alt=""> -->
                </div>
                <!-- lat-long -->
                <div class="map-Field">
                    <mat-form-field appearance="outline" class="lat">
                        <input type="text" (keypress)="service.numberOnly($event)" maxlength="10" matInput placeholder="Longitude" class="location-field" formControlName="latitude" readonly />
                    </mat-form-field>
                    <!-- longitude field -->
                    <mat-form-field appearance="outline" class="lat">
                        <input type="text" (keypress)="service.numberOnly($event)" maxlength="10" matInput placeholder="Latitude" class="location-field" formControlName="longitude" readonly />
                    </mat-form-field>

                </div>
                <mat-label><strong>Start Date</strong></mat-label>
                <mat-form-field appearance="outline" class="box">
                    <input type="datetime-local" id="date-inp" matInput [min]="todayDate" formControlName="startDate" class="date-point" style="text-transform: lowercase !important;" autocomplete="off" (keypress)="$event.preventDefault()" />
                    <img src="/assets/images/datepicker.ico" mat-card-sm-image alt="" class="cale-details" type="datetime-local">
                </mat-form-field>
                <!-- <mat-label><strong>Equipment Type</strong></mat-label>
                <mat-form-field appearance="outline" class="box">
                    <input type="text" (keypress)="service.preventSpace($event)" matInput placeholder="Equipment Type" formControlName="equipmentType" autocomplete="off" />

                </mat-form-field> -->
                <mat-label><strong>Quantity</strong></mat-label>
                <mat-form-field appearance="outline" class="box">
                    <input type="tel" (keypress)="service.numberOnly($event)" #a (keypress)="numberOnlyQuantity(a.value)" maxlength="9" matInput placeholder="Quantity" formControlName="quantity" autocomplete="off" />
                </mat-form-field>
                <mat-label><strong>Payment Type</strong></mat-label>
                <mat-form-field appearance="outline" class="box">
                    <mat-select formControlName="paymentType">
                        <mat-option (click)=paymentTypeId(item?.id) [value]="item?.paymentName" *ngFor="let item of paymentType">
                            {{item?.paymentName || '---'}}</mat-option>

                    </mat-select>
                </mat-form-field>
                <mat-label><strong>Payment</strong></mat-label>
                <mat-form-field appearance="outline" class="box">
                    <!-- <input type="tel" (keypress)="service.numberOnly($event)" #p (keypress)="numberOnlyPayment(p.value)" maxlength="16" matInput placeholder="$4000" [value]="createJobForm.get('payment').value | currency:'USD' : 'symbol':'1.0-0' " formControlName="payment"
                        autocomplete="off" /> -->
                    <input type="tel" (keypress)="service.numberOnly($event)" #p (keypress)="numberOnlyPayment(p.value)" maxlength="16" matInput placeholder="$4000" formControlName="payment" autocomplete="off" />
                </mat-form-field>
                <!-- map location heading  -->
                <!-- <div class="notes" fxLayout="row" fxLayoutAlign="space-between" fxLayout.sm="row"
          fxLayoutAlign.sm="space-between" fxLayout.xs="row" fxLayoutAlign.xs="space-between">
          <mat-label><strong>Job Map Location</strong></mat-label> -->
                <!-- location marker -->
                <!-- <img class="map-icon" (click)="getLocation();getLocationmap()" mat-card-xs-image
            src="./assets/images/Google-map-icon.png" alt=""> -->
                <!-- </div> -->
                <!-- latitude field -->
                <!-- <div class="map-Field">
          <mat-form-field appearance="outline" class="lat">
            <input type="text" (keypress)="service.numberOnly($event)" maxlength="10" matInput placeholder="Latitude"
              class="location-field" formControlName="latitude" />
          </mat-form-field> -->
                <!-- longitude field -->
                <!-- <mat-form-field appearance="outline" class="lat">
            <input type="text" (keypress)="service.numberOnly($event)" maxlength="10" matInput placeholder="Longitude"
              class="location-field" formControlName="longitude" />
          </mat-form-field>

        </div> -->
                <!-- google map -->
                <!-- <div id="dvMap" *ngIf="getLocationmapvar==true" style="width:100%; height:300px; margin: 10px;">
        </div> -->
                <!-- notes -->
                <div class="notes" fxLayout="row" fxLayoutAlign="space-between" fxLayout.sm="row" fxLayoutAlign.sm="space-between" fxLayout.xs="row" fxLayoutAlign.xs="space-between">
                    <mat-label><strong>Notes(optional)</strong></mat-label>
                    <mat-label><strong>Characters Remaining:{{250 - countChar()}}</strong></mat-label>
                </div>

                <mat-form-field appearance="none" class="box-area">
                    <textarea (keypress)="countChar()" formControlName="notes" matInput maxlength="250" class="text-area"></textarea>
                </mat-form-field>
                <div class="button" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout.sm="column" fxLayoutAlign.sm="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="space-between center">
                    <button type="button" mat-button style="border: 1px solid #0000ff" class="create-btn" color="primary" routerLink="/homepage">
            Cancel
          </button>
                    <button mat-raised-button class="create-btn" [disabled]="createJobForm.invalid" color="primary" (click)="onCreate()">
            Create
          </button>
                    <!-- <button mat-raised-button class="create-btn" color="primary" (click)="onCreate()">
            Create
          </button> -->
                </div>
            </form>
        </div>

    </div>
</div>

<!-- end create jobs coding -->