<div class="wrapper-content">
    <!-- <app-advertisment></app-advertisment> -->

    <mat-card class="mat-elevation-z0">
        <div class="terms-services">
            <h1>{{pageName | titlecase}}</h1>
            <div class="terms-services-content">
                <mat-card-content>
                    <p [innerHtml]="result?.description"> </p>
                </mat-card-content>
            </div>
        </div>
    </mat-card>
</div>