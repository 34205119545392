<!--profile completion alert start-->

<div *ngIf="showPage == 'sorry'">
    <!-- <app-advertisment></app-advertisment> -->

    <div class="alert_message">

        <h1>Sorry!</h1>
        <h1>You Must Have A Completed Profile To Search</h1>
    </div>
</div>
<!--End-->

<!--User Last seen login information Start-->
<div *ngIf="showPage == 'approved'">
    <!-- <app-advertisment></app-advertisment> -->

    <div class="Last_login_info_heading">
        <img class="waving_hand" mat-card-xs-image src="/assets/images/waving-hand-sign.png" alt="" />
        <h1 id="greet-message" *ngIf="profileDetail?.firstName">Hi {{(profileDetail?.firstName +" "+ profileDetail?.lastName) || 'User'}},</h1>
        <h1 id="greet-message" *ngIf="!profileDetail?.firstName">Hi User,</h1>
        <h1>Here Is Your Job Status Board</h1>
    </div>
    <div class="grids">
        <div class="approved-grid">
            <div class="approved">
                <img class="content_icon" mat-card-xs-image src="/assets/images/Group2.png" alt="" />
                <h1>Approved</h1>
            </div>
            <div class="approved_content">
                <mat-grid-list cols="1" rowHeight="300px">
                    <mat-grid-tile>{{approved || 0}}
                        <mat-grid-tile-footer class="grid_content_footer"><a routerLink="/approved-jobs" class="anchor-divider">View</a>
                            <mat-divider vertical class="divider"></mat-divider>
                            <span class="para" (click)="map('approved')">Map</span>
                        </mat-grid-tile-footer>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>

        <div class="approved-grid">
            <div class="Denied">
                <img class="content_icon" mat-card-xs-image src="/assets/images/Group.png" alt="" />
                <h1>Declined</h1>
            </div>
            <div class="denied_content">
                <mat-grid-list cols="1" rowHeight="300px">
                    <mat-grid-tile>{{denied || 0}}
                        <mat-grid-tile-footer class="grid_content_footer"><a routerLink="/denied-jobs" class="anchor-divider">View</a>
                            <mat-divider vertical class="divider"></mat-divider>
                            <span class="para" (click)="map('denied')">Map</span>
                        </mat-grid-tile-footer>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>

        <div class="approved-grid">
            <div class="Pending">
                <img class="content_icon" mat-card-xs-image src="/assets/images/Group1.png" alt="" />
                <h1>Pending</h1>
            </div>
            <div class="pending_content">
                <mat-grid-list cols="1" rowHeight="300px">
                    <mat-grid-tile>{{pending || 0}}
                        <mat-grid-tile-footer class="grid_content_footer"><a routerLink="/pending-jobs" class="anchor-divider">View</a>
                            <mat-divider vertical class="divider"></mat-divider>
                            <span class="para" (click)="map('pending')">Map</span>
                        </mat-grid-tile-footer>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
    </div>
</div>
<!--End-->

<!--Company Last seen login information Start-->
<div *ngIf="showPage == 'hirer'">
    <!-- <app-advertisment></app-advertisment> -->
    <div class="Last_login_info_heading">
        <img class="waving_hand" mat-card-xs-image src="/assets/images/waving-hand-sign.png" alt="" />
        <h1 id="greet-message" *ngIf="profileDetail?.firstName">Hi {{profileDetail.firstName +" "+ profileDetail.lastName || 'User'}},
        </h1>
        <h1 id="greet-message" *ngIf="!profileDetail?.firstName">Hi User,</h1>

        <h1>Here Is Your Job Status Board</h1>
    </div>

    <div class="grids">
        <div class="approved-grid">
            <div class="approved">
                <img class="content_icon" mat-card-xs-image src="/assets/images/Group1.png" alt="" />
                <h1>Open</h1>
            </div>
            <div class="approved_content">
                <mat-grid-list cols="1" rowHeight="300px">
                    <mat-grid-tile>{{open || 0}}
                        <mat-grid-tile-footer class="grid_content_footer"><a routerLink="/open-jobs" class="anchor-divider">View</a>
                            <mat-divider vertical class="divider"></mat-divider>
                            <span class="para" (click)="map('open')">Map</span>
                        </mat-grid-tile-footer>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>

        <div class="approved-grid">
            <div class="Denied">
                <img class="content_icon" mat-card-xs-image src="/assets/images/Group.png" alt="" />
                <h1>Closed</h1>
            </div>
            <div class="denied_content">
                <mat-grid-list cols="1" rowHeight="300px">
                    <mat-grid-tile>{{closed || 0}}
                        <mat-grid-tile-footer class="grid_content_footer"><a routerLink="/closed-jobs" class="anchor-divider">View</a>
                            <mat-divider vertical class="divider"></mat-divider>
                            <span class="para" (click)="map('closed')">Map</span>
                        </mat-grid-tile-footer>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>

    </div>
</div>
<!--End-->