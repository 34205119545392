import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeProfileData = new EventEmitter();
  subsVar: Subscription;
  constructor() { }
  public onInvokeProfileData() {
    this.invokeProfileData.emit();
  }
}
