import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-account-verified',
  templateUrl: './account-verified.component.html',
  styleUrls: ['./account-verified.component.scss']
})
export class AccountVerifiedComponent implements OnInit {
  time : any = 7
  interval : any
  email :any
  accountCode : any
  constructor(private route : Router,public service : CommonService,private activate : ActivatedRoute) { 
    this.activate.queryParams.subscribe((res)=>{
      this.email = res.email
      this.accountCode = res.accountCode
    })
  }

  ngOnInit(): void {
   localStorage.clear()
    this.verifyAccount()
  }
  verifyAccount(){
    let url = `user/verifyAccount?email=${this.email}&accountCode=${this.accountCode}`
    this.service.showSpinner()
    this.service.getApi(url,0).subscribe((res)=>{
      if(res['statusCode']==200){
        this.service.hideSpinner()
        this.startTimer()
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast("Account verified failed.")
        this.route.navigate(['/homepage'])

      }
    })
  }
  startTimer() {
    
    this.interval = setInterval(() => {
      this.time--;
      if(this.time == 0){
        clearInterval(this.interval);
        localStorage.removeItem('isVerfied')
        this.route.navigate(['/homepage'])

      }    
    },1000)
  
    
  }

}
