<div class="main-container">
    <mat-card class="container">
        <h1><strong>Welecome to Blue Collar
                Connect</strong></h1>
        <div class="container" style="width: 40%;"><img src="assets/loader image/verify.gif" mat-card-image width="100%"
                alt=""></div>
        <h2>Account Verified Sucessfully</h2>
        <p><a routerLink="/homepage">Click here</a> to proceed login</p>
        <p>Redirecting to homepage in {{time}} second's</p>
    </mat-card>
</div>