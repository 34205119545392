import { CommonService } from "src/app/provider/common.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { CurrencyPipe } from "@angular/common";
@Component({
  selector: "app-create-job",
  templateUrl: "./create-job.component.html",
  styleUrls: ["./create-job.component.scss"],
})
export class CreateJobComponent implements OnInit {
  @ViewChild('lati') lati: ElementRef;
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  getLocationmapvar: boolean = false
  createJobForm: FormGroup;
  company_location: any;
  price: any;
  quantity: any;
  notes: any;
  duration: any
  latitude: any;
  longitude: any;
  options: any[];
  jobId: any;
  subJobId: any;
  jobfieldId: any;
  // durationTypeId: any;
  paymentId: any;
  userEmail: String;
  userMobile: any;
  center: any

  map: google.maps.Map;//map variable property
  jobList: any = [];
  subJobList: any = [];
  // durationType: any = [];
  paymentType: any = [];
  individual: boolean = true;
  company: boolean = false;
  applyType: boolean = true
  todayDate: any = new Date().toISOString().slice(0, 16)
  @ViewChild('map') mapElement: any;
  marker: any;
  city: any;
  submitted: boolean = false;

  public handleAddressChange(address: Address) {
    this.options = []
    // console.log(address.formatted_address);
    this.latitude = address.geometry.location.lng()
    this.longitude = address.geometry.location.lat()
    this.createJobForm.patchValue({
      latitude: this.latitude,
      longitude: this.longitude
    })
    // console.log('lat ', this.latitude, 'long', this.longitude);
    this.options.push(address.formatted_address);
  }

  constructor(public service: CommonService, private route: Router, private currencyPipe:CurrencyPipe) {
    this.service.loginData.subscribe(res => {

      localStorage.setItem('email', res['email']);
      localStorage.setItem('number', res['mobileNumber']);
    })

  }


  ngOnInit(): void {
    this.createJobForm = new FormGroup({
      jobType: new FormControl("", Validators.required),
      jobSubType: new FormControl(""),
      // durationType: new FormControl("", [Validators.required]),
      duration: new FormControl(""),
      city: new FormControl("", [Validators.required]),
      startDate: new FormControl("",[Validators.required]),
      // equipmentType: new FormControl(""),
      quantity: new FormControl("1"),
      paymentType: new FormControl("", Validators.required),
      payment: new FormControl(""),
      notes: new FormControl(""),
      test: new FormControl(""),
      longitude: new FormControl(""),
      latitude: new FormControl("")

    });

    // use currency pipe USD in payment field
// this.createJobForm.valueChanges.subscribe ( form =>{
//   if(form.payment){
//     this.createJobForm.patchValue({
//       payment : this.currencyPipe.transform(form.payment.replace(/\D/g, '').replace(/^0+/,''),'USD', 'symbol', '1.0-0')
//     },{emitEvent:false})
//   }
  
// });

    // end currency pipe USD in payment field


    this.getLocation() //location function
    this.getJobList();
    // this.getSubJobList();
    // this.getDurationType();
    this.getPaymentType()
    this.createJobForm.valueChanges.subscribe((res) => {
      // console.log('form value', res.city);
      if (res.city != '' && res.city != undefined) {
        this.submitted = true;
      }

    })
  }

  showOptions(applyType) {
    this.individual = applyType == "INDIVIDUAL";
    this.company = applyType == "COMPANY";
  }
  // job list api
  getJobList() {
    let url = "jobs/categoryList";
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.jobList = res["result"];
        this.service.hideSpinner();
        // this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    });
  }

  getLocationmap() {
    this.getLocationmapvar = true

  }

  numberOnlyDuration(val) {

    this.createJobForm.value;

    if (val[0] == 0) {

      this.createJobForm.patchValue({
        duration: 0
      })
      return true
    }


  }

  numberOnlyPayment(val): boolean {


    this.createJobForm.value.payment;

    if (val[0] == 0) {
      this.createJobForm.patchValue({
        payment: null
      })

      return false
    }
    return true


  }
  numberOnlyQuantity(val): boolean {

    this.createJobForm.value.quantity;

    if (val[0] == 0) {
      this.createJobForm.patchValue({
        quantity: null
      })

      return false
    }
    return true


  }
  getSubJobList(id) {
    this.jobId = id
    this.createJobForm.controls['jobSubType'].reset(); // reset the form fields on job type change
    // console.log("getSubJobList")
    let url = "jobs/subCategoryList?id=" + id;
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.subJobList = res["result"];
        // this.getDurationType();
        this.service.hideSpinner();
        // this.service.successToast(res["responseMessage"]);
      } else {
        this.subJobList = []
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    }, (error: any) => {
      this.subJobList = []
    });
  }


  // getDurationType() {
  //   let url = "jobs/durationTypeList";
  //   this.service.showSpinner();
  //   this.service.getApi(url, 1).subscribe((res) => {
  //     if (res["statusCode"] == 200) {
  //       this.durationType = res["result"];
  //       // this.getPaymentType();
  //       this.service.hideSpinner();
  //       // this.service.successToast(res["responseMessage"]);
  //     } else {
  //       this.service.hideSpinner();
  //       this.service.errorToast(res["responseMessage"]);
  //     }
  //   });
  // }
  getPaymentType() {
    let url = "jobs/paymentTypeList";
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.paymentType = res["result"];

        this.service.hideSpinner();
        // this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    });
  }
  onCreate() {
    if (!this.individual && !this.company) {
      this.service.errorToast("Please select job type");
      return;
    }
    if (this.individual && !this.company) {
      this.onCreateJob("Applicant", "jobs/createJob", "Individual")
    }
    else {
      this.onCreateJob("3rd_party_contractor", "jobs/createJob", "Company")
    }
  }


  // getSubJobId(id) {
  //   this.subJobId = id
  // }

  // getDurationTypeId(id) {
  //   this.durationTypeId = id;
  // }
  paymentTypeId(id) {
    this.paymentId = id;
  }

  onCreateJob(jobTpye, url, type) {
    var date = new Date(this.createJobForm.value.startDate).toString()
    date = date.replace("GMT+0530", "UTC")
    let data = {
      jobId: String(this.jobId),
      // subJobId: (this.subJobId),
      subJobId: this.createJobForm.value.jobSubType,
      notes: this.createJobForm.value.notes,
      duration: String(this.createJobForm.value.duration) || '',
      // durationTypeId: String(this.durationTypeId),
      startDate: new Date(date).toISOString(),
      // equipmentType: this.createJobForm.value.equipmentType,
      accountType: jobTpye,
      // applyType: type,
      quantity: String(this.createJobForm.value.quantity ? this.createJobForm.value.quantity : ''),
      paymentTypeId: String(this.paymentId),
      payment: this.createJobForm.value.payment,
      city: String(this.createJobForm.value.city),
      phoneNumber: localStorage.getItem('number'),
      email: localStorage.getItem('email'),
      address: "string",
      latitude: this.createJobForm.value.longitude,
      longitude: this.createJobForm.value.latitude
    }
    var temp = this.service.removeEmptyKey(data)
    console.log(data)
  
    this.service.showSpinner();
    this.service.postApi(url, temp, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.route.navigate(["/created-jobs"]);
        this.service.hideSpinner();
        // this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    },
      // (err: any) => {
      //   this.service.errorToast(err.error.responseMessage);
      // }
    )

  }


  countChar() {
    return String(this.createJobForm.value.notes).length;
  }
  getLocation() {
    navigator.geolocation.getCurrentPosition((res) => {
      this.latitude = res.coords.latitude
      this.longitude = res.coords.longitude
      this.getCenterPostion(this.latitude, this.longitude)
      this.getLatLong()
    }, (err) => {
      this.getIp()
    })
  }
  getCenterPostion(lat, long) {
    this.center = new google.maps.LatLng(lat, long)
    this.marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, long),

      map: this.map,
      animation: google.maps.Animation.BOUNCE,
      icon:
      {
        url: 'assets/img/map-mark.png',
        size: new google.maps.Size(36, 50),
        scaledSize: new google.maps.Size(36, 50),
        anchor: new google.maps.Point(0, 50)
      }
    })

    // this.createJobForm.patchValue({
    //   latitude: lat,
    //   longitude: long
    // })
    this.marker.setMap()
  }
  getIp() {
    var ip
    this.service.getIPAddress().subscribe((res) => {
      this.getLocationCurrent(res["ip"])
    })

  }

  getLocationCurrent(ip) {
    this.service.getLocation("http://www.geoplugin.net/json.gp?ip=" + ip).subscribe((res) => {
      this.latitude = res["geoplugin_latitude"]
      this.longitude = res["geoplugin_longitude"]
      this.getCenterPostion(this.latitude, this.longitude)
    })
  }


  getLatLong() {
    let latitude;
    let longitude;
    // var callme = () => { this.setLatLang() }
    var mapOptions = {
      center: new google.maps.LatLng(this.latitude, this.longitude),
      zoom: 8,
    };
    this.marker.setMap()
    var infoWindow = new google.maps.InfoWindow();
    var latlngbounds = new google.maps.LatLngBounds();
    var map = new google.maps.Map(document.getElementById("dvMap"), mapOptions);
    google.maps.event.addListener(map, 'click', function (e) {
      this.latitude = e.latLng.lat()
      this.longitude = e.latLng.lng()
      if (this.marker && this.marker.setMap) {
        this.marker.setMap(this.map);
      }
      this.marker = new google.maps.Marker({
        position: new google.maps.LatLng(this.latitude, this.longitude),
        animation: google.maps.Animation.BOUNCE,

        map: map,
        icon:
        {
          // url: 'https://icon-library.com/images/current-location-icon/current-location-icon-8.jpg', 
          url: 'assets/images/map-mark.png',
          size: new google.maps.Size(36, 50),
          scaledSize: new google.maps.Size(36, 50),
          anchor: new google.maps.Point(0, 50)
        }

      });

      latitude = this.latitude
      longitude = this.longitude

      callme()

    });
    var callme = () => {
      this.setLatLang(latitude, longitude)
    }

  }

  setLatLang(latitude, longitude) {
    this.createJobForm.patchValue({
      latitude: latitude,
      longitude: longitude
    })

  }
  // cityLatLong() {
  //   console.log("l:363 city lat long")
  //   const data = {
  //     city: this.createJobForm.value.city
  //   }

  //   let apiCategoryUrl = "user/cityLatLong";
  //   this.service.showSpinner()
  //   this.service.postApi(apiCategoryUrl, data, 0).subscribe((res: any) => {

  //     if (res.statusCode == 200) {
  //       this.createJobForm.patchValue({
  //         latitude: this.latitude,
  //         longitude: this.longitude
  //       })
  //       this.city = res["result"]
  //       this.service.hideSpinner();
  //       // this.service.successToast(res["responseMessage"]);
  //     }
  //     else {
  //       this.service.hideSpinner();
  //       this.service.errorToast(res["responseMessage"]);
  //     }
  //   })

  // }
  timer: any
  // checkCity() {
  //   if (!this.createJobForm.value.city) {
  //     return
  //   }
  //   let me = this
  //   clearInterval(this.timer)
  //   this.timer = setTimeout(function () {
  //     me.cityLatLong();
  //   }, 2000);
  // }

}
