import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/provider/common.service';
import { ReportComponent } from '../report/report.component';

@Component({
  selector: 'app-user-apply-job',
  templateUrl: './user-apply-job.component.html',
  styleUrls: ['./user-apply-job.component.scss']
})

export class UserApplyJobComponent implements OnInit {
  hide = true;
  viwejobs: any;
  PostedJobId: any;
  apply: string = "applied"
  viewJobStatus: any;
  showSecondDialog: boolean = false;
  startDate: any;
  showContact1: boolean = false
  showContact: boolean = false
  userType: any
  currUrl: any = window.location.href
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public commonService: CommonService, public dialogRef: MatDialogRef<UserApplyJobComponent>) {
    window.scroll(0, 0)
    if (String(window.location.href).includes("employee-profile")) {
      this.showContact = true

    } else if (String(window.location.href).includes("search-jobs")) {
      this.showContact = true
    }
    else {
      this.showContact = false
    }
    this.userType = localStorage.getItem("userType")
  }

  ngOnInit(): void {
    //  this.viewJobStatus = this.data.status
    window.scroll(0, 0)
    this.jobList()
  }


  viewApplyJob() {
    let apiReqUrl = `user/viewJobsByUser?id=${this.data.dataKey}`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.viwejobs = res.result;
        this.startDate = String(this.viwejobs.startDate).replace('Z', '')
        this.PostedJobId = res.result.id;
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage); 
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    })
  }

  viewApplyJobBeforelogin() {
    let apiReqUrl = `user/viewJobsByUserWithoutSignUp?id=${this.data.dataKey}`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.viwejobs = res.result;
        this.PostedJobId = res.result.id;
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage); 
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    })
  }

  jobList() {
    if (this.commonService.isLoggedIn()) {
      this.viewApplyJob()
    }
    else if (!this.commonService.isLoggedIn()) {
      this.viewApplyJobBeforelogin()
    }
  }

  applyJob() {
    let apiReqUrl = "user/applyJob";
    let apiReqData = {
      postedJobId: String(this.PostedJobId)
    }
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res["statusCode"] == 200) {

        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    }, (err: any) => {
      this.commonService.errorToast(err.error.responseMessage);
    })
  }

  alertApplyJob() {
    if (!this.commonService.isLoggedIn()) {
      this.commonService.infoToast("Please login before apply this job")
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  reportDialog(id) {
    let dialogConfig = { hasBackdrop: false, skipHide: true };
    const dialogRef = this.dialog.open(ReportComponent, {
      width: '600px',
      autoFocus: false,
      data: { jobId: id },
      height: 'max-content',

    });
    this.showSecondDialog = false;
  }
}