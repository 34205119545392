import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { CommonService } from "src/app/provider/common.service";

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {
  dialogRe: any;
  dataSource: any = [];
  constructor(
    private route: Router,
    public service: CommonService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

  }
  //  Delete Jobs API Integration
  deleteJob() {

    let url = `${this.data.url}`
    this.service.showSpinner()
    this.service.deleteApi(url, {}, 1).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.data["isSuccesfullyDeleted"] = true
        this.service.hideSpinner()
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    }
      , (err: any) => {
        this.service.errorToast(err.error.responseMessage);
      })
  }


}
