import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountVerifiedComponent } from 'src/app/pages/verifyAccount/account-verified/account-verified.component';
import { MaterialModule } from 'src/app/material/material.module';



@NgModule({
  declarations: [
    AccountVerifiedComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
  ],

})
export class AccountVerifyModule {
  constructor(){
    
  }
 }
