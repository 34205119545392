<!-- job Search Modal -->
<mat-card class="mat-elevation-z0 bdr mat-dialog-container">
    <div class="main">
        <div class="top-text">
            <mat-card-title class="sign-text">Search
            </mat-card-title>
        </div>
        <div class="input-field">
            <form class="form-detail" [formGroup]="jobSearchForm" (input)="onSubmit()">
                <!-- job Field -->
                <mat-select class="user-select " placeholder="Select Job Type" formControlName="jobType">
                    <!-- <mat-option value="" class="option">Select Job Type </mat-option> -->
                    <mat-option (click)="getSubCategory(item?.categoryName)" [value]="item?.categoryName"
                        *ngFor="let item of category">
                        {{item?.categoryName || '---'}}</mat-option>
                </mat-select>
                <!-- job Sub Type Field -->
                <mat-select class="user-select " formControlName="jobSubType" placeholder="Select Job Sub Type">
                    <!-- <mat-option value="" class="option">
                        Select Job Sub Type </mat-option> -->
                    <!-- <mat-option (click)=getSubCatJobId(item?.id) [value]="item?.subCatName"
                        *ngFor="let item of subCategory">
                        {{item?.subCatName || '---'}}</mat-option> -->
                    <mat-option [value]="item?.subCatName"  *ngFor="let item of subCategory">
                        {{item?.subCatName || '---'}}</mat-option>
                </mat-select>
                <!-- city Field -->
                <!-- <input type="text" class="user-name" placeholder="City" formControlName='city' autocomplete="off"
                    ngx-google-places-autocomplete [(ngModel)]="options" [options]='options' #placesRef="ngx-places"
                    (onAddressChange)="handleAddressChange($event)" [autocomplete]="commonService.isLoggedIn()" (input)="onPress()"
                    [readonly]="commonService.isLoggedIn()"> -->
                    <input type="text" class="user-name" placeholder="City" formControlName='city' autocomplete="off"
                    ngx-google-places-autocomplete [(ngModel)]="options" [options]='options' #placesRef="ngx-places"
                    (onAddressChange)="handleAddressChange($event)"  (input)="onPress()"
                    >


                <!-- distance Field -->
                <input type="text" class="user-name" placeholder="Distance In Miles" [(ngModel)]="radius"
                    (keypress)="commonService.numberOnly($event)" formControlName='distance'
                    [readonly]="commonService.isLoggedIn()">
            </form>
        </div>
        <!-- button -->
        <div class="down-button">
            <button mat-button class="cancel" mat-dialog-close="true">Cancel</button>
            <button mat-button class="submit" (click)="search()">Submit</button>
        </div>

    </div>
</mat-card>