<div class="footer" [ngClass]="showClass ? 'responsive' : ''">
    <hr class="top">
    <div class="footer-cont" fxLayout="row" fxLayoutAlign="space-around center" fxLayout.sm="column" fxLayoutAlign.sm="space-around center" fxLayout.xs="column" fxLayoutAlign.xs="space-around center">
        <div class="heading" fxLayoutAlign="center center" (click)="navigateToHomePage()">
            <img *ngIf="footerLogo" mat-card-xs-image [src]="footerLogo" class="headerimg" alt="Blue Collar Connect">
            <h1 *ngIf="!footerLogo">Blue Collar Connect</h1>
        </div>
        <div class="content" fxLayoutAlign="center center" fxLayout.sm="row" fxLayoutAlign.sm="center center" fxLayout.xs="row" fxLayoutAlign.xs="center center">
            <div class="about" routerLink='/about-us'><a>About Us</a></div>
            <div class="about" routerLink='/contactUs'><a>Contact Us</a></div>
            <div class="about" routerLink='/help'><a>Help</a></div>
            <div class="about" routerLink='/privacy-policy'><a>
                    Privacy Policy</a></div>
            <div class="about" routerLink='/faq'><a>Faq's</a></div>

        </div>
        <div class="images" fxLayoutAlign="center center" fxLayout.sm="row" fxLayoutAlign.sm="center center" fxLayout.xs="row" fxLayoutAlign.xs="center center">
            <a *ngIf="socialLinksList[0]?.socialName == 'Linkedin'" [href]="socialLinksList[0]?.socialLink" target="_blank" rel="noopener"> <img src="assets/images/linkdin.png" alt=""></a>
            <a *ngIf="socialLinksList[1]?.socialName == 'Instagram'" [href]="socialLinksList[1]?.socialLink" target="_blank" rel="noopener"><img src="assets/images/instagram.png" alt=""></a>
            <a *ngIf="socialLinksList[2]?.socialName == 'Youtube'" [href]="socialLinksList[2]?.socialLink" target="_blank" rel="noopener"><img src="assets/images/youtube.png" alt=""></a>
            <a *ngIf="socialLinksList[3]?.socialName == 'Twitter'" [href]="socialLinksList[3]?.socialLink" target="_blank" rel="noopener"> <img src="assets/images/twitter.png" alt=""></a>
            <a *ngIf="socialLinksList[4]?.socialName == 'Facebook'" [href]="socialLinksList[4]?.socialLink" target="_blank" rel="noopener"> <img src="/assets/images/facebook.png" alt=""></a>
        </div>
    </div>
    <hr class="bottom">
    <div class="conditions-terms" fxLayoutAlign="center center" fxLayout.sm="row" fxLayoutAlign.sm="center center" fxLayout.xs="row" fxLayoutAlign.xs="center center"><span class="copyright">@Copyright 2021 <span class="line">|
            </span></span>
        <a class="terms" routerLink='/terms-of-service'>Terms of
            Service</a>
    </div>
</div>