import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  result: any
  pageName: any
  constructor(private service: CommonService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.contactUs()
  }
  contactUs() {
    let url = "user/viewStaticContent?id=2"
    this.service.showSpinner()
    this.service.getApi(url, 0).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.result = res["result"]
        this.pageName = res["result"]["type"]
        this.pageName = String(this.pageName).split(/(?=[A-Z])/).join(" ")
        this.service.hideSpinner()
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    }
    )
  }
}
