import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {

  result: any
  pageName: any
  constructor(private service: CommonService) { }

  ngOnInit(): void {
    this.termsAndConditions()
  }
  termsAndConditions() {
    let url = "user/viewStaticContent?id=4"
    this.service.showSpinner()
    this.service.getApi(url, 0).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.result = res["result"]
        this.pageName = res["result"]["type"]
        this.pageName = String(this.pageName).split(/(?=[A-Z])/).join(" ")
        this.service.hideSpinner()
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    }
    )
  }
}
