import { Component, OnInit, ViewChild } from '@angular/core';
import { UserApplyJobComponent } from '../../user-apply-job/user-apply-job.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/provider/common.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.scss']
})
export class EmployeeProfileComponent implements OnInit {
  search_type = ['All', 'Job type', 'Equipment type', 'Duration', 'Start date', 'City', 'Payment type', 'Payment']
  displayedColumns: string[] = ['jobType', 'equipment', 'Duration', 'startDate', 'city', 'paymentType', 'payment', 'apply'];
  // joblist:any
  dataSource: any = []
  displaytable: string[]
  length: any;
  // id: any;
  startDate: any = []

  currentPage: number = 0;
  pageSize = 25;
  pageEvent: PageEvent;
  itemPerPage: number = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(public dialog: MatDialog, public commonService: CommonService, public router: Router) { }
  @ViewChild(MatPaginator) paginator: MatPaginator; //Material Paginator

  ngOnInit(): void {
    this.getJoblist()
    this.commonService.isLoggedIn()
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  employeeJobList() {

    let apiReqUrl = `jobs/allJobList?page=${this.currentPage + 1}&limit=${this.itemPerPage}`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.dataSource = res.result.results;
        this.length = res["result"]["count"]
        // this.commonService.successToast(res.responseMessage);
        let i = 0
        for (let item of res["result"]["results"]) {
          console.log(item.isApplied);

          this.startDate[i] = String(item["startDate"]).replace('Z', '')
          i++
        }
        this.commonService.hideSpinner();

      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    })
  }
  employeeJobListBeforeLogin() {
    let apiReqUrl = `jobs/allJobList?page=${this.currentPage + 1}&limit=${this.itemPerPage}`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.dataSource = res.result.results;
        this.length = res["result"]["count"]
        let i = 0
        for (let item of res["result"]["results"]) {
          this.startDate[i] = String(item["startDate"]).replace('Z', '')
          i++
        }
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    })
  }
  sortData(data) {
    let sortedArray = []
    switch (data) {
      case 'Job type':
        sortedArray = this.dataSource.sort((a, b) => (a.jobType.categoryName > b.jobType.categoryName) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'Equipment':
        sortedArray = this.dataSource.sort((a, b) => (a.equipmentType > b.equipmentType) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'Duration':
        sortedArray = this.dataSource.sort((a, b) => (a.duration > b.duration) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'Start date':
        sortedArray = this.dataSource.sort((a, b) => (a.startDate > b.startDate) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'City':
        sortedArray = this.dataSource.sort((a, b) => (a.city > b.city) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'Payment type':
        sortedArray = this.dataSource.sort((a, b) => (a.paymentTypes.paymentName > b.paymentTypes.paymentName) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;

      case 'Payment':
        sortedArray = this.dataSource.sort((a, b) => (a.payment > b.payment) ? 1 : -1);
        this.dataSource = []
        for (let i = 0; i < sortedArray.length; i++) {
          this.dataSource[i] = sortedArray[i]
        }
        break;
      default:
        this.getJoblist()

    }

  }

  getJoblist() {
    switch (this.commonService.header()) {
      case 'Applicant':
        this.employeeJobList()
        break;
      case '3rd_party_contractor':
        this.employeeJobList()
        break;
      default:
        this.employeeJobListBeforeLogin()
        break;
    }
  }


  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }


  changePage(event) {
    this.currentPage = this.pageEvent.pageIndex
    this.itemPerPage = this.pageEvent.pageSize
    this.getJoblist()
  }
  applyJobDialog(id, applyStatus) {
    const dialogRef = this.dialog.open(UserApplyJobComponent, {
      width: '450px',
      height: 'max-content',
      autoFocus: false,
      data: { dataKey: id, status: applyStatus }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.getJoblist()
      }
    });
  }


}
