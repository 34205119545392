<!-- start main-container coding -->
<div class="main-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout.sm="column"
    fxLayout.xs="column">
    <div class="heading">
        <h1><strong>Company Details</strong></h1>
    </div>
    <!-- start form coding  -->
    <!-- start company details form coding -->
    <form action="" [formGroup]="companyDetailsForm">
        <div class="company" fxLayout="column">
            <mat-label><strong>Company Name</strong></mat-label>
            <input placeholder="XYZ Corporation" class="input-field" formControlName='companyName' type="text" matInput>
        </div>
        <div class="validations">
            <span
                *ngIf="(this.companyDetailsForm.controls['companyName'].invalid &&  (this.companyDetailsForm.controls['companyName'].dirty || this.companyDetailsForm.controls['companyName'].touched))">
                *Company name is required.
            </span>
        </div>
        <div class="company" fxLayout="column">
            <mat-label><strong>Owner Name</strong></mat-label>
            <input placeholder="Jerry Write" class="input-field" formControlName='ownerName' type="text" matInput>
        </div>
        <div class="validations">
            <span
                *ngIf="(this.companyDetailsForm.controls['ownerName'].errors?.required &&  (this.companyDetailsForm.controls['ownerName'].dirty || this.companyDetailsForm.controls['ownerName'].touched))">
                *Owner name is required.
            </span>
            <span *ngIf="(this.companyDetailsForm.controls['ownerName'].errors?.pattern)">
                *Please enter valid owner name.
            </span>
        </div>
        <div class="company" fxLayout="column">
            <mat-label><strong>Phone</strong></mat-label>
            <input placeholder="970-581-4328" class="input-field" formControlName='phone' type="number" matInput>
        </div>
        <div class="validations">
            <span
                *ngIf="(this.companyDetailsForm.controls['phone'].errors?.required &&  (this.companyDetailsForm.controls['phone'].dirty || this.companyDetailsForm.controls['phone'].touched))">
                *Mobile number is required.
            </span>
            <span *ngIf="(this.companyDetailsForm.controls['phone'].errors?.pattern)">
                *Please enter valid mobile number.
            </span>
        </div>
        <div class="company" fxLayout="column">
            <mat-label><strong>Email</strong></mat-label>
            <input placeholder="JWrite99@gmail.com" class="input-field" formControlName='email' type="email" matInput>
        </div>
        <div class="validations">
            <span
                *ngIf="(this.companyDetailsForm.controls['email'].errors?.required &&  (this.companyDetailsForm.controls['email'].dirty || this.companyDetailsForm.controls['email'].touched))">
                *Email id is required.
            </span>
            <span *ngIf="(this.companyDetailsForm.controls['email'].errors?.pattern)">
                *Please enter valid email id.
            </span>
        </div>
        <div class="company" fxLayout="column">
            <mat-label><strong>Address</strong></mat-label>
            <input placeholder="1215, Whaley Way, Fort Collins, Colorado 80528" class="input-field"
                formControlName='address' type="text" matInput>
        </div>
        <div class="validations">
            <span
                *ngIf="(this.companyDetailsForm.controls['address'].errors?.required &&  (this.companyDetailsForm.controls['address'].dirty || this.companyDetailsForm.controls['address'].touched))">
                *Address is required.
            </span>
        </div>
        <!-- end company details form coding -->
        <!-- start buttons coding -->
        <div class="company" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
            fxLayoutAlign="space-between center" fxLayoutGap="20px">
            <button class="btns" mat-button color="primary">Cancel</button>
            <button class="btns" mat-raised-button color="primary">Update</button>
        </div>
    </form>
    <!-- end buttons coding -->
    <!-- end form coding  -->
</div>
<!-- end main-container coding -->