import { Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "src/app/provider/common.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteComponent } from "src/app/modal/delete/delete.component";
import { MatPaginator, PageEvent } from "@angular/material/paginator";

@Component({
  selector: "app-completed-jobs",
  templateUrl: "./completed-jobs.component.html",
  styleUrls: ["./completed-jobs.component.scss"],
})
export class CompletedJobsComponent implements OnInit {
  displayedColumns: string[] = [
    "JobType",
    "Duration",
    "Start_date",
    "City",
    "Payment_Type",
    "Payment",
    "blank",
    "delete",
  ];
  dataSource: any = [];
  currentPage: number = 0;
  itemPerPage: number = 25;
  length: any;
  pageSize = 25;
  pageEvent: PageEvent;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(private route: Router, private service: CommonService, public dialog: MatDialog) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.completeJobs()
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  deleteDialog(id) {
    let dialogConfig = { hasBackdrop: false, skipHide: true };
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: "430px",
      height: "290px",
      data: { dataKey: id, name: "deniedDelete" }
    })

  }
  map() {
    this.route.navigate(["/google-map"]);
  }
  returnPage() {
    this.route.navigate(["/profile-completion-alert"]);
  }

  completeJobs() {
    let url = "jobs/completeJobList?page=" + (this.currentPage + 1) + "&limit=" + this.itemPerPage
    this.service.showSpinner()
    this.service.getApi(url, 1).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.dataSource = res["result"]["rows"]
        this.length = res["result"]["count"]
        this.service.hideSpinner()
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    }
    )
  }
  setPageSizeOptions(setPageSizeOptionsInput: string) {


    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }
  changePage(event) {
    this.currentPage = this.pageEvent.pageIndex
    this.itemPerPage = this.pageEvent.pageSize
    this.completeJobs()
  }
}
