<!-- delete Modal -->
<mat-card class="mat-elevation-z0 bdr mat-dialog-container" aria-hidden="true">
    <div class="main">
        <!-- Heading -->
        <div class="top-text">
            <mat-card-title class="sign-text">Logout
            </mat-card-title>
        </div>
        <div class="input-field">
            <p>Are you sure you want to Logout this account?</p>
        </div>
        <!-- button -->
        <div class="down-button">
            <button mat-button class="cancel" mat-dialog-close="true">No</button>
            <button mat-button class="submit" (click)="LogOut()">Yes</button>
        </div>

    </div>
</mat-card>