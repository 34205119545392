import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogData } from 'src/app/pages/jobs/created-jobs/created-jobs.component';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-decline',
  templateUrl: './decline.component.html',
  styleUrls: ['./decline.component.scss']
})
export class DeclineComponent implements OnInit {
  dialogCancel: boolean = true;
  declineForm: FormGroup;
  constructor(
    public dialog: MatDialog,
    public service: CommonService,
    public dialogRef: MatDialogRef<DeclineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }
  ngOnInit(): void {
    this.declineForm = new FormGroup({
      description: new FormControl("", [Validators.required]),
    });

  }

  declineRequest() {
    let data = {
      jobApplyId: String(this.data.jobId),
      description: this.declineForm.value.description,
    };
    this.service.showSpinner();
    this.service
      .postApi("jobs/rejectJobRequest", data, 1)
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.closeDialog()
          this.service.hideSpinner();
          this.service.successToast("Applicant declined successfully");
        } else {
          this.service.hideSpinner();
          this.service.errorToast(res.responseMessage);
        }
      }
        , (err: any) => {
          this.service.errorToast(err.error.responseMessage);
        });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
