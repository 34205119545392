import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-log-out',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.scss']
})
export class LogOutComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LogOutComponent>, private service: CommonService) { }

  ngOnInit(): void {
  }

  // Logout Function 
  LogOut() {
    this.service.logout()
    this.dialogRef.close();
  }
  // Material Dialog Close After Open Second Dialog
  closeDialog() {
    this.dialogRef.close();
  }
}
