import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
// modules
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { GoogleMapsModule } from "@angular/google-maps";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxMaskModule, IConfig } from 'ngx-mask'
// components
import { AppComponent } from "./app.component";
import { SigninComponent } from "./modal/auth/signin/signin.component";
import { ForgotPasswordComponent } from "./modal/auth/forgot-password/forgot-password.component";
import { SignupComponent } from "./modal/auth/signup/signup.component";
import { JobSearchComponent } from "./modal/job-search/job-search.component";
import { HeaderComponent } from "./pages/sharing/header/header.component";
import { FooterComponent } from "./pages/sharing/footer/footer.component";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { CreateJobComponent } from "./pages/jobs/create-job/create-job.component";
import { EmployeeProfileComponent } from "./modal/employee/employee-profile/employee-profile.component";
import { ViewJobsComponent } from "./pages/jobs/view-jobs/view-jobs.component";
import { SearchJobsComponent } from "./pages/jobs/search-jobs/search-jobs.component";
import { GoogleMapComponent } from "./pages/google-map/google-map.component";
import { ProfileCompletionAlertComponent } from "./pages/alerts/profile-completion-alert/profile-completion-alert.component";
import { ClosedJobsComponent } from "./pages/jobs/closed-jobs/closed-jobs.component";
import { OpenJobsComponent } from "./pages/jobs/open-jobs/open-jobs.component";
import { ApprovedJobsComponent } from "./pages/jobs/approved-jobs/approved-jobs.component";
import { CompletedJobsComponent } from "./pages/jobs/completed-jobs/completed-jobs.component";
import { UserApplyJobComponent } from "./modal/user-apply-job/user-apply-job.component";
import { ChangePasswordComponent } from "./modal/auth/change-password/change-password.component";
import { PendingJobsComponent } from "./pages/jobs/pending-jobs/pending-jobs.component";
import { ApplicantsInfoComponent } from "./pages/applicants-info/applicants-info.component";
import { AboutUsComponent } from "./pages/static-content/about-us/about-us.component";
import { ApplicantDetailsComponent } from "./modal/applicant/applicant-details/applicant-details.component";
import { CreatedJobsComponent } from "./pages/jobs/created-jobs/created-jobs.component";
import { EmployeeUpdateDetailsComponent } from "./modal/employee/employee-update-details/employee-update-details.component";
import { CompanyDetailsComponent } from "./pages/company-details/company-details.component";
import { DeniedJobsComponent } from "./pages/jobs/denied-jobs/denied-jobs.component";
import { EmployeeDetailsComponent } from "./modal/employee/employee-details/employee-details.component";
import { TermsOfServiceComponent } from './pages/static-content/terms-of-service/terms-of-service.component';
import { ContactUsComponent } from './pages/static-content/contact-us/contact-us.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { HelpComponent } from './pages/static-content/help/help.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ReportComponent } from './modal/report/report.component';
import { DatePipe } from "@angular/common";
import { DeleteComponent } from './modal/delete/delete.component';
import { LogOutComponent } from './modal/auth/log-out/log-out.component';
import { AccountVerifiedComponent } from './pages/verifyAccount/account-verified/account-verified.component';
import { AccountVerifyModule } from "./VerifyAccount/account-verify/account-verify.module";
// import { NgxPaginationModule } from "ngx-pagination";
import { PrivacyPolicyComponent } from './static-content/privacy-policy/privacy-policy.component';
import { DeclineComponent } from './modal/decline/decline.component';
import { AdvertismentComponent } from './static-content/advertisment/advertisment.component';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SplitPipe } from './pipe/split.pipe';
import { CurrencyPipe } from "@angular/common";

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    ForgotPasswordComponent,
    SignupComponent,
    JobSearchComponent,
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    CreateJobComponent,
    EmployeeProfileComponent,
    ViewJobsComponent,
    SearchJobsComponent,
    GoogleMapComponent,
    ProfileCompletionAlertComponent,
    ClosedJobsComponent,
    OpenJobsComponent,
    ApprovedJobsComponent,
    CompletedJobsComponent,
    UserApplyJobComponent,
    ChangePasswordComponent,
    PendingJobsComponent,
    ApplicantsInfoComponent,
    AboutUsComponent,
    ApplicantDetailsComponent,
    CreatedJobsComponent,
    EmployeeUpdateDetailsComponent,
    CompanyDetailsComponent,
    DeniedJobsComponent,
    EmployeeDetailsComponent,
    TermsOfServiceComponent,
    ContactUsComponent,
    ProfileComponent,
    ResetPasswordComponent,
    HelpComponent,
    FaqComponent,
    ReportComponent,
    DeleteComponent,
    LogOutComponent,
    PrivacyPolicyComponent,
    DeclineComponent,
    AdvertismentComponent,
    SplitPipe,

  ],
  entryComponents: [EmployeeDetailsComponent, ApplicantDetailsComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    NgxSpinnerModule,
    HttpClientModule,
    GooglePlaceModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-right",
      maxOpened: 1,
      preventDuplicates: true,
    }),
    GoogleMapsModule,
    FlexLayoutModule,
    AccountVerifyModule,

  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, DatePipe, BnNgIdleService, CurrencyPipe],
  bootstrap: [AppComponent],

})
export class AppModule { }
