import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SignupComponent } from "../signup/signup.component";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";
import { Router } from "@angular/router";
import { CommonService } from "src/app/provider/common.service";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit {
  toggleEye: boolean = true;  // toggle eye
  vertical: any = "true";
  open: boolean = true;
  dialogRe: any;
  signinForm: FormGroup;
  userType: string;
  showSecondDialog: boolean = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SigninComponent>,
    private route: Router,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    // Form group and Form Control Name With Validations
    this.signinForm = new FormGroup({
      username: new FormControl("", [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/),
        Validators.maxLength(30),
      ]),
      pswd: new FormControl("", [
        Validators.required,
        Validators.maxLength(32),
      ]),
    });
  }

  // Signup Material Dialog Function
  signupDialog() {
    let dialogConfig = { hasBackdrop: false, skipHide: true };
    const dialogRef = this.dialog.open(SignupComponent, {
      width: "474px",
      height: "540px",
      autoFocus: false
    });
    this.showSecondDialog = true;
  }

  // Signin API Integration Function
  signIn() {
    let apiReqData = {
      email: this.signinForm.value.username,
      password: this.signinForm.value.pswd,
    };
    let apiReqUrl = "user/userLogin";
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
      // console.log(res)
      if (res.statusCode == 200) {
        this.commonService.hideSpinner();
        localStorage.setItem('token', res['result']['token']);
        localStorage.setItem('userType', res['result']['accountType']);
        this.commonService.showHeader.next(res.result.accountType);
        this.commonService.loginData.next(res.result)
        this.route.navigate(['/profile-completion-alert'])
        // this.commonService.successToast(res.responseMessage);
        this.closeDialog()

      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    }, (err: any) => {
      this.commonService.errorToast(err.error.responseMessage);
    })
  }
  // Material Dialog Close After Open Second Dialog
  closeDialog() {
    this.dialogRef.close();
  }
  // Forgot Password material Dialog Function
  forgotDialog() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: "645px",
      height: "300px",
      autoFocus: false
    });
    this.showSecondDialog = false;
  }
  // Navigation Function 
  navigateToTermsOfServices() {
    this.route.navigate(["/terms-of-service"]);
    this.dialog.closeAll();
  }
}
