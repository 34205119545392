import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CommonService } from 'src/app/provider/common.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
  toggleEye = true; // toggle eye
  toggleEye1 = true; // toggle eye1
  toggleEye2 = true; // toggle eye2
  changePasswordForm: FormGroup; // New Form Group

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ChangePasswordComponent>, private commonService: CommonService) { }

  ngOnInit(): void {
    // Form group and Form Control Name With Validations
    this.changePasswordForm = new FormGroup({
      oldPassword: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(?=^.{8,32}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/)
      ]),
      newPassword: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(?=^.{8,32}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/)
      ]),
      confirmPassword: new FormControl("", [
        Validators.required,

      ])
    });
  }

  // Change Password API Integration
  changePassword() {
    let apiReqData = {
      "oldPassword": this.changePasswordForm.value.oldPassword,
      "newPassword": this.changePasswordForm.value.confirmPassword,
    }
    let apiReqUrl = "user/changePassword"
    this.commonService.showSpinner();
    this.commonService.patchApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage)
        this.closeDialog()
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    }
      , (err: any) => {
        this.commonService.errorToast(err.error.responseMessage);
      })

  }
  // Material Dialog Close After Open Second Dialog
  closeDialog() {
    this.dialogRef.close();
  }
}
