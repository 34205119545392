import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CommonService } from 'src/app/provider/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SigninComponent } from '../signin/signin.component';


@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  toggleEye = true; //Toggle Eye
  toggleEye1 = true;//Toggle Eye1
  signupForm: FormGroup;
  code: string;
  // @Input()AccCode
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<SignupComponent>, public service: CommonService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    // Form group and Form Control Name With Validations
    // this.signInClose()
    this.signupForm = new FormGroup({
      password: new FormControl("", [
        Validators.required,
        Validators.pattern(

          /^(?=^.{8,32}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/
        ),

        Validators.maxLength(32),
      ]),
      confirmPassword: new FormControl("", [Validators.required]),
      number: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
      ]),
      userType: new FormControl("", [Validators.required]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/
        ),

      ])
    });
  }
  // Signup API Integration Function
  onSignUp() {
    let data = {
      "mobileNumber": this.signupForm.value.number,
      "email": this.signupForm.value.email,
      "confirmPassword": this.signupForm.value.confirmPassword,
      "password": this.signupForm.value.password,
      "userType": this.signupForm.value.userType,
      // "websiteUrl":this.service.websiteURL
    }

    this.service.showSpinner()
    this.service.postApi("user/signup", data, 0).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.service.hideSpinner()
        this.code = res.result.accountCode;
        // this.service.successToast(res["responseMessage"]);
        this.service.successToast("Sign up successfully, please check your email for verfication.");
        this.closeDialog();
        this.openDialog();
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res["responseMessage"]);
      }
    }, (err: any) => {
      this.service.errorToast(err.error.responseMessage);
    })

  }
  // Navigation Function
  navigateToTermsOfServices() {
    this.router.navigate(["/terms-of-service"]),
      this.dialog.closeAll();
  }

  // Material Dialog Close After Open Second Dialog
  closeDialog() {
    this.dialogRef.close();
  }

  // Signin Material Dialog Function
  openDialog() {
    let dialogConfig = { hasBackdrop: false, skipHide: true };
    const dialogRef = this.dialog.open(SigninComponent, {
      width: "540px",
      height: "370px",
      autoFocus: false

    });
    this.dialog.afterAllClosed;
  }

}
