import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(value:any, ...args:any[]):any {
    if(value=='3rd_party_contractor'){
      return "Sub-Contractor";
      }
      else if(value=='Hirer'){
        return "Employer/Contractor";
      }
      // else if(value=='privacyPolicy'){
      //   return "Privacy Policy";
      // }
      // else if(value=='aboutUs'){
      //   return "About Us";
      // }
      else{

        return  value;
      }
  }

}
