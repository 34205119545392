<div class="approved-job-table wrapper-content">
    <!-- <app-advertisment></app-advertisment> -->

    <div (click)="returnPage()" class="return_page">
        <img mat-card-xs-image src="/assets/images/coolicon.png" alt="" />
    </div>
    <h1 class="Approved_job">Completed Jobs</h1>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-describedby="Completed-jobs">
        <!-- JobType Column -->
        <ng-container matColumnDef="JobType">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Job Type</th>
            <td class="table_data" mat-cell *matCellDef="let element">
                {{ element?.jobType?.categoryName || '---'}}

            </td>
        </ng-container>

        <!-- Duration Column -->
        <ng-container matColumnDef="Duration">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Duration</th>
            <td class="table_data" mat-cell *matCellDef="let element">
                {{element?.duration || '---'}}
            </td>
        </ng-container>

        <!-- Start date Column -->
        <ng-container matColumnDef="Start_date">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>
                Start Date
            </th>
            <td class="table_data" mat-cell *matCellDef="let element">
                {{ (element?.startDate | date :'MM/dd/yyyy, hh:mm a') || '---'}}
            </td>
        </ng-container>
        <!-- City Column -->
        <ng-container matColumnDef="City">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>City</th>
            <td class="table_data" mat-cell *matCellDef="let element">
                {{ element?.city || '---'}}
            </td>

        </ng-container>

        <!-- Payment Type Column -->
        <ng-container matColumnDef="Payment_Type">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>
                Payment Type
            </th>
            <td class="table_data" mat-cell *matCellDef="let element">
                {{ element?.paymentTypes?.paymentName || '---'}}
            </td>
        </ng-container>

        <!-- Payment  Column -->
        <ng-container matColumnDef="Payment">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Payment</th>
            <td class="table_data" mat-cell *matCellDef="let element">
                {{ element?.payment || '---'}}
            </td>
        </ng-container>

        <!--Table Map Button

-->
        <ng-container matColumnDef="blank">
            <th class="table_heading" id="map" mat-header-cell *matHeaderCellDef></th>
            <td class="table_data" mat-cell *matCellDef="let element">
                <div class="Map_button_up">
                    <button (click)="map()" class="Map_button" mat-raised-button color="primary">
            Map
          </button>
                </div>
            </td>
        </ng-container>
        <!--Table delete Button-->

        <ng-container matColumnDef="delete">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef></th>
            <td class="table_data" mat-cell *matCellDef="let element">
                <div class="Map_button_up">
                    <button class="Map_button1" mat-raised-button click)="deleteDialog(element.id)">Delete</button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator (page)="pageEvent = $event" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="changePage($event)" aria-label="Select page">
    </mat-paginator>
</div>