import { CommonService } from "src/app/provider/common.service";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogData } from "src/app/pages/jobs/created-jobs/created-jobs.component";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent implements OnInit {
  dialogCancel: boolean = true;
  reportForm: FormGroup;
  showSecondDialog: boolean = false;
  reportedId: any
  constructor(
    public dialog: MatDialog,
    private router: Router,
    public service: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.reportForm = new FormGroup({
      description: new FormControl("", [Validators.required]),
    });

  }

  createReport() {
    let data = {
      jobId: String(this.data["jobId"]),
      comment: this.reportForm.value.description,
    };
    this.service.showSpinner();
    this.service
      .postApi("user/createReportOnJob", data, 1)
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.service.hideSpinner();
          this.service.successToast("Applicant reported successfully");
        } else {
          this.service.hideSpinner();
          this.service.errorToast(res.responseMessage);
        }
      }
        , (err: any) => {
          this.service.errorToast(err.error.responseMessage);
        });
  }

  navigateToTermsOfServices() {
    this.router.navigate(["/terms-of-service"]);
    this.dialog.closeAll();
  }
}
