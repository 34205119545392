<!-- start created jobs coding -->

<!-- <app-advertisment></app-advertisment> -->
<h1 class="Approved_job">My Jobs </h1>
<div class="approved-job-table wrapper-content">

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" aria-describedby="created-jobs">
        <!-- JobType Column -->
        <ng-container matColumnDef="JobType">

            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef mat-sort-header>Job Type</th>
            <td class="table_data" (click)="viewJob(element.id,element.jobStatus)" mat-cell *matCellDef="let element">
                {{ element?.jobType?.categoryName || '---'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="subJobType">

            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>
                Equipment Type</th>
            <td class="table_data" (click)="viewJob(element.id,element.jobStatus)" mat-cell *matCellDef="let element">
                {{ element?.subJobType?.subCatName || '---'}}
            </td>
        </ng-container>

        <!-- who can apply -->
        <ng-container matColumnDef="WhoCanApply">

            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>
                Job For</th>
            <td class="table_data" (click)="viewJob(element.id,element.jobStatus)" mat-cell *matCellDef="let element">
                {{ (element?.accountType | split) || '---'}}
            </td>
        </ng-container>
        <!-- Duration Column -->
        <ng-container matColumnDef="Duration">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Duration</th>
            <td class="table_data" (click)="viewJob(element.id,element.jobStatus)" mat-cell *matCellDef="let element">
                {{ element?.duration != '0' ? element?.duration:''}}
            </td>
        </ng-container>

        <!-- Start date Column -->
        <ng-container matColumnDef="Start_date">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>
                Start Date
            </th>
            <td class="table_data" (click)="viewJob(element.id,element.jobStatus)" mat-cell *matCellDef="let element;let i = index">
                {{ (startDate[i] | date :'MM/dd/yyyy, hh:mm a') || '---'}}
            </td>
        </ng-container>

        <!-- City Column -->
        <ng-container matColumnDef="City">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>City</th>
            <td class="table_data" (click)="viewJob(element.id,element.jobStatus)" mat-cell *matCellDef="let element">
                {{ element?.city || '---'}}
            </td>
        </ng-container>

        <!-- Payment Type Column -->



        <ng-container matColumnDef="Payment_Type">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>
                Payment Type
            </th>
            <td class="table_data" (click)="viewJob(element.id,element.jobStatus)" mat-cell *matCellDef="let element">
                {{ element?.paymentTypes?.paymentName || '---'}}
            </td>
        </ng-container>

        <!-- Payment  Column -->
        <ng-container matColumnDef="Payment">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Payment</th>
            <td class="table_data" (click)="viewJob(element.id,element.jobStatus)" mat-cell *matCellDef="let element">
                {{ (element?.payment | currency:'USD') || '---'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="JobStatus">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Job Status</th>
            <td class="table_data" mat-cell *matCellDef="let element">
                <span *ngIf="element?.jobStatus == 'OPEN'" style="color: rgb(12, 170, 12);" (click)="viewJob(element.id,element.jobStatus)">Active</span>
                <span *ngIf="element?.jobStatus == 'CLOSED'" style="color: rgb(243, 81, 60);">Closed</span>
            </td>
            <!-- <td  *ngIf="element?.jobStatus == 'CLOSED'" class="table_data" (click)="viewJob(element.id)" mat-cell *matCellDef="let element">
       jkfgf
      </td> -->
        </ng-container>
        <!-- applicant  -->
        <ng-container matColumnDef="Applicant">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Applicant
            </th>
            <td class="table_data" mat-cell *matCellDef="let element;let i = index" (click)="element?.noOfApplicants!=0?navigateToApplicantInformation(element?.id,startDate[i]):false">
                <p class="appli-deatils"> {{ element?.noOfApplicants || 0}} </p>
            </td>
        </ng-container>

        <!--Table Map Button-->
        <ng-container matColumnDef="blank">
            <th class="table_heading" id="map" mat-header-cell *matHeaderCellDef></th>
            <td class="table_data" mat-cell *matCellDef="let element">
                <div class="Map_button_up">
                    <button class="Map_button" mat-raised-button color="primary" (click)="map(element?.id)">
            Map
          </button>
                </div>
            </td>
        </ng-container>

        <!-- start Table delete Button-->
        <ng-container matColumnDef="delete">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef></th>
            <td class="table_data" mat-cell *matCellDef="let element">
                <div class="Map_button_up">
                    <button class="Map_button1" mat-raised-button (click)="deleteDialog(element.id)">Delete</button>
                </div>
            </td>
        </ng-container>
        <!-- end Table delete Button-->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event" (page)="changePage($event)" aria-label="Select page">
    </mat-paginator>

</div>
<!-- end created jobs coding -->