<!-- report Modal -->
<mat-card class="mat-elevation-z0 bdr mat-dialog-container">
    <div class="main">
        <div class="top-text">
            <mat-card-title class="sign-text">Why you want to decline ?</mat-card-title>
        </div>
        <div class="input-field">
            <form [formGroup]="declineForm">
                <textarea type="text" class="user-name" placeholder="Description" formControlName="description" rows="5"></textarea>
                <!-- validations -->
                <div class="error" *ngIf="
              declineForm.get('description').hasError('required') &&
              (declineForm.get('description').touched ||
                declineForm.get('description').dirty)">
                    *Description is required.
                </div>
            </form>
        </div>
        <div class="down-button">
            <!-- button -->
            <button mat-button [mat-dialog-close]="!dialogCancel" class="cancel">
        Cancel
      </button>
            <button mat-button class="submit" [mat-dialog-close]="dialogCancel" (click)="declineRequest()">Submit</button>
        </div>
    </div>
</mat-card>