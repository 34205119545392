import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/provider/common.service";

@Component({
  selector: "app-profile-completion-alert",
  templateUrl: "./profile-completion-alert.component.html",
  styleUrls: ["./profile-completion-alert.component.scss"],
})
export class ProfileCompletionAlertComponent implements OnInit {
  showPage: string;
  name: any
  ProfileStatus: any
  profileDetail: any = []
  approved: any
  pending: any
  denied: any
  open: any
  closed: any
  completed: any

  constructor(public rout: Router, private commonserice: CommonService, public activateRoute: ActivatedRoute) {
    this.activateRoute.queryParams.subscribe((res) => {
      this.ProfileStatus = res
      if (this.ProfileStatus.Status == 'false') {
        this.showPage = "sorry"
      }
      else {
        if (this.commonserice.header() == "Applicant") {
          this.showPage = "approved"
          this.getApllicantData()
        }
        else if (this.commonserice.header() == "3rd_party_contractor") {
          this.showPage = "approved"
          this.getApllicantData()
        }
        else if (this.commonserice.header() == "Hirer") {
          this.showPage = "hirer"
          this.getHirerData()
        }
      }
    })
  }

  ngOnInit(): void {
    this.getProfile()
    // this.getUserNAme()

  }

  getUserNAme() {

    this.name = localStorage.getItem("name")
  }

  getApllicantData() {
    this.getPendingJob()
    this.getDeniedJob()
    this.getApprovedJob()
  }

  getHirerData() {
    this.getOpenJob()
    this.getClosedJob()
    // this.getCompleteJob()
  }

  map(jobs) {
    this.rout.navigate(["/google-map"], { queryParams: { JobView: jobs } });
  }

  getProfile() {
    let url = "user/getProfile";
    this.commonserice.showSpinner();
    this.commonserice.getApi(url, 1).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.profileDetail = res["result"];
        this.commonserice.hideSpinner();
      } else {
        this.commonserice.hideSpinner();
      }
    });
  }
  getPendingJob() {
    let url = "user/pendingJobList"
    this.commonserice.showSpinner()
    this.commonserice.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.pending = res["result"]["count"]
        this.commonserice.hideSpinner()
        // this.commonserice.successToast(res['responseMessage'])
      }
      else {
        this.commonserice.hideSpinner()
        // this.commonserice.errorToast(res['responseMessage'])
      }
    })
  }
  getDeniedJob() {
    let url = "user/deniedJobList"
    this.commonserice.showSpinner()
    this.commonserice.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.denied = res["result"]["count"]
        this.commonserice.hideSpinner()
        // this.commonserice.successToast(res['responseMessage'])
      }
      else {
        this.commonserice.hideSpinner()
        // this.commonserice.errorToast(res['responseMessage'])
      }
    })
  }
  getApprovedJob() {
    let url = "user/approvedJobList"
    this.commonserice.showSpinner()
    this.commonserice.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.approved = res["result"]["count"]
        this.commonserice.hideSpinner()
        // this.commonserice.successToast(res['responseMessage'])
      }
      else {
        this.commonserice.hideSpinner()
        // this.commonserice.errorToast(res['responseMessage'])
      }
    })
  }

  getOpenJob() {
    let url = "jobs/openJobList"
    this.commonserice.showSpinner()
    this.commonserice.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.open = res["result"]["count"]
        this.commonserice.hideSpinner()
        // this.commonserice.successToast(res['responseMessage'])
      }
      else {
        this.commonserice.hideSpinner()
        // this.commonserice.errorToast(res['responseMessage'])
      }
    })
  }
  getClosedJob() {
    let url = "jobs/closedJobList"
    this.commonserice.showSpinner()
    this.commonserice.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.closed = res["result"]["count"]
        this.commonserice.hideSpinner()
        // this.commonserice.successToast(res['responseMessage'])
      }
      else {
        this.commonserice.hideSpinner()
        // this.commonserice.errorToast(res['responseMessage'])
      }
    })
  }

}
