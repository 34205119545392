import { Component, Inject, OnInit } from '@angular/core';
import { ReportComponent } from '../../report/report.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from 'src/app/pages/jobs/created-jobs/created-jobs.component';
import { CommonService } from 'src/app/provider/common.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: "app-applicant-details",
  templateUrl: "./applicant-details.component.html",
  styleUrls: ["./applicant-details.component.scss"],
})
export class ApplicantDetailsComponent implements OnInit {
  applicantData: any = []
  startDate: any
  city: any;
  status: any;
  zipCode: any;
  todayDate: any = new Date().toISOString().slice(0, 16)
  startdate: any
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public service: CommonService, public date: DatePipe) { }

  ngOnInit(): void {
    this.getApplicantProfile()
    this.status = this.data.interviewStatus
    this.startdate = this.date.transform(this.data["startDate"], 'yyyy-MM-ddThh:mm')
    // console.log("-=-=-=-=-=--=", this.startdate);

  }
  getApplicantProfile() {
    let url = "user/viewApplicant?id=" + this.data["applicantId"]
    this.service.showSpinner()
    this.service.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.applicantData = res["result"]
        this.zipCode = res["result"]['zipCode']
        // this.startDate = this.date.transform(res["result"]["createdAt"], 'yyyy-MM-ddThh:mm'),
        this.startDate = this.date.transform(String(res["result"]["updatedAt"]).replace('Z', ''), 'yyyy-MM-ddThh:mm')
        this.getZipCode(this.zipCode)
        this.service.hideSpinner();
        // this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    })
  }

  getZipCode(zipcode) {
    let apiCategoryUrl = "user/validateZipCode";
    const data = {
      zipCode: zipcode
    }
    let responseZipCode = []
    this.service.showSpinner()
    this.service.postApi(apiCategoryUrl, data, 0).subscribe((res: any) => {
      if (res.statusCode == 200) {
        responseZipCode = res["result"][`${zipcode}`].filter((res) => {
          return res?.country_code == "US"
        })
        this.city = responseZipCode[0]['city']
        this.service.hideSpinner();
        // this.service.successToast(res["responseMessage"]);
      }
      else {
        this.service.hideSpinner();
        // this.service.errorToast(res["responseMessage"]);
      }
    })
  }

  reportDialog(applicantId) {

    let dialogConfig = { hasBackdrop: false, skipHide: true };
    const dialogRef = this.dialog.open(ReportComponent, {
      width: '600px',
      height: 'max-content',
      autoFocus: false,
      data: { applicantId: applicantId }
    });

  }
  interview() {
    let url = "jobs/interviewSchedule"
    let data = {
      "applyId": this.data["id"],
      "date": new Date(this.startdate).toISOString(),
    }
    this.service.showSpinner()
    this.service.postFormDataApi(url, data, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        // this.approveJob()
        this.service.hideSpinner();
        //  this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    })
  }



}
