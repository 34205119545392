<div class="wrapper-content">
    <!-- <app-advertisment></app-advertisment> -->
    <mat-card class="mat-elevation-z0">
        <div class="contact">
            <h1 style="margin-bottom: 15px;">FAQ's</h1>
            <div class="contact-us">
                <div class="contact-icon">
                    <img src="/assets/images/faq1.svg" alt="">
                </div>
                <div class="accordian">
                    <cdk-accordion class="example-accordion">
                        <cdk-accordion-item *ngFor="let item of result" #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
                            <div class="example-accordion-item-header first-header" (click)="accordionItem.toggle()">
                                {{item?.question || '---'}}
                                <span class="example-accordion-item-description">
                                    Click to {{ accordionItem.expanded ? 'close' : 'open' }}
                                </span>
                            </div>
                            <div class="example-accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                                {{item?.answer || '---'}}
                            </div>
                        </cdk-accordion-item>
                    </cdk-accordion>
                </div>

            </div>

        </div>
        <mat-paginator id=mat-paginator-container-faq [length]="total" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page" (page)="changePage($event)">
        </mat-paginator>
    </mat-card>

</div>