<!-- forgot Modal -->
<mat-card class="mat-elevation-z0 bdr mat-dialog-container">
  <div class="main">
    <div class="top-text">
      <mat-card-title class="sign-text">Forgot Password </mat-card-title>
    </div>
    <div class="input-field">
      <form action="" [formGroup]="forgotPasswordForm">
        <!-- email Field -->
        <input type="text" class="user-name" placeholder="Email" formControlName="username" maxlength="60"
          autocomplete="off" />
        <!-- validations -->
        <div class="error" *ngIf="
            forgotPasswordForm.get('username').hasError('required') &&
            (forgotPasswordForm.get('username').touched ||
              forgotPasswordForm.get('username').dirty)
          ">
          *Email id is required.
        </div>
        <div class="error" *ngIf="
            forgotPasswordForm.get('username').hasError('pattern') &&
            (forgotPasswordForm.get('username').touched ||
              forgotPasswordForm.get('username').dirty)
          ">
          *Please enter valid email id.
        </div>
        <!-- button -->
        <div class="down-button">
          <button mat-button [mat-dialog-close]=!dialogCancel class="cancel">Cancel</button>
          <button mat-button [disabled]="!forgotPasswordForm.valid" (click)="forgetPassword()"
            class="submit">Submit</button>
        </div>
      </form>
    </div>

    <div class="term">
      <a (click)="navigateToTermsOfServices()">Terms of Service</a>
    </div>
  </div>

</mat-card>