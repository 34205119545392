import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { CommonService } from '../provider/common.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public commonService: CommonService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          this.commonService.hideSpinner();
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error["error"]["responseMessage"]}`;
          }
          else {
            // errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            switch (error.status) {
              case 400:
                // this.commonService.errorToast(error["error"]["responseMessage"]);
                // this.commonService.logout();
                break;
              case 401:
                this.commonService.errorToast('Your session has expired please login again.');
                this.commonService.logout()
                break;
              case 403:
                this.commonService.errorToast('Unauthorized Access.');
                this.commonService.logout()
                break;
              case 404:
                // this.commonService.errorToast(error["error"]["responseMessage"]);
                break;
              case 500:
                // this.commonService.errorToast('Internal Server Error.');
                break;
              case 417:
                // this.commonService.errorToast('Something went wrong.');
                break;
              default:
                // this.commonService.errorToast(error["error"]["responseMessage"] || 'Something went wrong');
                break;
            }
          }
          return throwError(error);
        })
      )
  }
}
