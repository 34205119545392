<div class="wrapper-content">
    <div class="search-inputs">
        <!-- <app-advertisment></app-advertisment> -->
        <div class="mat-input-fields">
            <mat-form-field appearance="outline">
                <input matInput type="search" placeholder="zipcode" class="search-input">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <input matInput type="search" placeholder="distance" class="search-input">
            </mat-form-field>
            <button mat-raised-button color="primary">Search</button>
        </div>
        <div class="result">
            <h1>Result</h1>
        </div>
        <div class="sort-type-search">
            <h1 class="sort-type">Sort type</h1>
            <mat-form-field appearance="none" class="mat-field">
                <mat-select class="input-fields " value="All">
                    <mat-option *ngFor="let types of search_type" [value]="types" (click)="sortData(types)">
                        {{types}}
                    </mat-option>
                </mat-select>
                <mat-divider vertical class="divider"></mat-divider>
            </mat-form-field>
        </div>
    </div>
    <div class="search-job-table">

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-describedby="employee-profile">


            <!-- job type Column -->
            <ng-container matColumnDef="jobType">
                <th id="" mat-header-cell *matHeaderCellDef> Job type </th>
                <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)">
                    {{element?.jobType?.categoryName || '---'}}
                </td>
            </ng-container>

            <!-- equipment Column -->
            <ng-container matColumnDef="equipment">
                <th id="" mat-header-cell *matHeaderCellDef> Equipment Type </th>
                <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)">
                    {{element?.subJobType?.subCatName || '---'}} </td>
            </ng-container>

            <!-- Duration Column -->
            <ng-container matColumnDef="Duration">
                <th id="" mat-header-cell *matHeaderCellDef> Duration </th>
                <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)">
                    {{element?.duration || '---'}}</td>
            </ng-container>

            <!-- start date column -->
            <ng-container matColumnDef="startDate">
                <th id="" mat-header-cell *matHeaderCellDef> start Date </th>
                <td (click)="applyJobDialog(element?.id,element?.isApplied)" mat-cell *matCellDef="let element;let i = index">
                    <!-- {{element?.startDate | date : 'dd/MM/yyyy, hh:mm a' || '---'}}</td> -->
                    {{ (startDate[i] |date :'MM/dd/yyyy, hh:mm a') || '---'}}
                </td>

                <!-- <td class="table_data" (click)="viewJob(element.id)" mat-cell *matCellDef="let element;let i = index">
            {{ startDate[i] | date: 'dd/MM/yyyy, hh:mm a' || '---'}}
          </td> -->
            </ng-container>

            <!-- city Column -->
            <ng-container matColumnDef="city">
                <th id="" mat-header-cell *matHeaderCellDef> city </th>
                <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)"> {{element?.city || '---'}} </td>
            </ng-container>

            <!-- payment type type Column -->
            <ng-container matColumnDef="paymentType">
                <th id="" mat-header-cell *matHeaderCellDef> payment type </th>
                <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)">
                    {{element?.paymentTypes?.paymentName || '---'}} </td>
            </ng-container>

            <!-- payment Column -->
            <ng-container matColumnDef="payment">
                <th id="" mat-header-cell *matHeaderCellDef> payment </th>
                <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)">
                    {{ (element?.payment | currency:'USD') || '---'}}
                </td>
            </ng-container>


            <ng-container matColumnDef="apply">
                <th id="" mat-header-cell *matHeaderCellDef> </th>

                <td mat-cell *matCellDef="let element">
                    <p *ngIf="commonService.isLoggedIn() && element.isApplied== true " style="color: rgb(7, 163, 7);">Submitted</p>
                    <button *ngIf="commonService.isLoggedIn() && element.isApplied == false " mat-raised-button color="primary" (click)="applyJobDialog(element?.id,element?.isApplied)">Submit</button>
                    <span *ngIf="!commonService.isLoggedIn()"></span>

                    <!-- <span *ngIf="!commonService.isLoggedIn()"></span> -->
                </td>

            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator (page)="pageEvent = $event" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="changePage($event)" aria-label="Select page">
        </mat-paginator>
    </div>
</div>