import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/provider/common.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  toggleEye = true; // toggle eye
  toggleEye1 = true;
  resetPasswordForm: FormGroup;
  email: any;
  constructor(
    public commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm = new FormGroup({
      newPassword: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(?=^.{8,32}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/),
        Validators.maxLength(30),
      ]),
      confirmPassword: new FormControl("", [Validators.required]),
    });
    this.getEmail();
  }
  getEmail() {
    this.email = this.route.snapshot.params.email;
  }

  resetPassword() {
    let apiReqData = {
      newPassword: this.resetPasswordForm.value.newPassword,
      confirmPassword: this.resetPasswordForm.value.confirmPassword,
    };
    let apiReqUrl = `user/resetPassword/${this.email}`;
    this.commonService.showSpinner();
    this.commonService
      .putApi(apiReqUrl, apiReqData, 0)
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          localStorage.removeItem("email");
          this.commonService.hideSpinner();
          this.router.navigate(['/homepage'])
          // this.commonService.successToast(res.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      }, (err: any) => {
        this.commonService.errorToast(err.error.responseMessage);
      });
  }
}
