<!-- report Modal -->
<mat-card class="mat-elevation-z0 bdr mat-dialog-container">
  <div class="main">
    <div class="top-text">
      <mat-card-title class="sign-text">Report </mat-card-title>
    </div>
    <div class="input-field">
      <form [formGroup]="reportForm">
        <textarea type="text" class="user-name" placeholder="Description" formControlName="description"
          rows="5"></textarea>
        <!-- validations -->
        <div class="error" *ngIf="
            reportForm.get('description').hasError('required') &&
            (reportForm.get('description').touched ||
              reportForm.get('description').dirty)">
          *Description is required.
        </div>
      </form>
    </div>
    <div class="down-button">
      <!-- button -->
      <button mat-button [mat-dialog-close]="!dialogCancel" class="cancel">
        Cancel
      </button>
      <button mat-button class="submit" [mat-dialog-close]="!dialogCancel" (click)="createReport()">Submit</button>
    </div>
    <div class="term" (click)="navigateToTermsOfServices()">
      <a>Terms of Service</a>
    </div>
  </div>
</mat-card>