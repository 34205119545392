<div class="wrapper-content">
    <div *ngIf="!jobsList">
        <div class="main" fxLayout="row" fxLayoutAlign="space-around center" fxLayout.sm="column" fxLayout.xs="column">
            <div class="job">
                <h1>Job Type:</h1>
                <p>{{viwejobs?.jobType?.categoryName || '---'}}</p>
            </div>
            <div class="start">
                <h1>Start Date:</h1>
                <h4>{{(viwejobs?.startDate | date :'MM/dd/yyyy, hh:mm a') || '---'}}</h4>
            </div>
            <div class="company">
                <h1>Company:</h1>
                <h4>{{viwejobs?.hirerDetails?.companyName || '---'}}</h4>
            </div>
            <div class="contact">
                <h1>Contact:</h1>
                <h4>{{viwejobs?.hirerDetails?.firstName || '---'}} {{viwejobs?.hirerDetails?.lastName || '---'}}</h4>
            </div>
            <div class="phone">
                <h1>Phone:</h1>
                <h4>{{(viwejobs?.hirerDetails?.mobileNumber | mask: '(000) 000 0000')|| '---'}}</h4>
            </div>
        </div>
        <div class="main-down" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.sm="column"
            fxLayout.xs="column">
            <div class="location">
                <h1>Location:</h1>
                <h4>{{address || '---'}}</h4>
            </div>
            <div class="email">
                <h1>Email:</h1>
                <h4>{{viwejobs?.hirerDetails?.email || '---'}}</h4>
            </div>
        </div>
    </div>
    <div *ngIf="jobsList">
        <div class="main-down" fxLayout="row" fxLayoutAlign="center" fxLayout.sm="column" fxLayout.xs="column">
            <h1>All {{JobHeading}} Job List</h1>
        </div>
    </div>
    <div *ngIf="!jobsList">
        <div class="map" #map>

            <google-map [center]="center" [zoom]="zoom" height="400px" width="100%">
                <map-marker [options]="marker"></map-marker>
            </google-map>
        </div>
    </div>
    <div *ngIf="jobsList">
        <div #map style="width:100%;height:450px">
            <google-map height="500px" width="100%" [zoom]="zoom" [center]="center" [options]="options">
                <map-marker *ngFor="let marker of markers" [options]="marker.options">
                </map-marker>
            </google-map>
        </div>
    </div>
</div>