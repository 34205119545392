import { Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "src/app/provider/common.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { UserApplyJobComponent } from "src/app/modal/user-apply-job/user-apply-job.component";
import { MatDialog } from "@angular/material/dialog";
declare var $
@Component({
  selector: "app-pending-jobs",
  templateUrl: "./pending-jobs.component.html",
  styleUrls: ["./pending-jobs.component.scss"],
})
export class PendingJobsComponent implements OnInit {
  displayedColumns: string[] = [
    "JobType",
    "Duration",
    "Start_date",
    "City",
    "Payment_type",
    "payment",
    "intreview",
    "blank",
  ];
  dataSource: any = [];
  pendingData: any = [];
  currentPage: number = 0;
  itemPerPage: number = 25;
  length: any;
  pageSize = 25;
  pageEvent: PageEvent;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  


  // pageSize = 10;


  constructor(private route: Router, private service: CommonService, public dialog: MatDialog) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.pendingJobs()
    $(document).ready(function() {
      $("body").tooltip({
          selector: '[data-toggle=tooltip]'
      });
  });
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  returnPage() {
    this.route.navigate(["/profile-completion-alert"]);
  }

  detailDialog(id) {
    const dialogRef = this.dialog.open(UserApplyJobComponent, {
      width: '450px',
      data: { dataKey: id, openJob: "openJobHirer" },
      height: 'max-content'
    });
  }

  map(id) {
    this.route.navigate(["/google-map"], { queryParams: { Id: id } });
  }

  pendingJobs() {
    let url = "user/pendingJobList?page=" + (this.currentPage + 1) + "&limit=" + this.itemPerPage
    this.service.showSpinner()
    this.service.getApi(url, 1).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.dataSource = res["result"]["rows"]
        this.length = res["result"]["count"]
        this.service.hideSpinner()
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    }
    )
  }
  setPageSizeOptions(setPageSizeOptionsInput: string) {


    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }
  changePage(event) {
    this.currentPage = this.pageEvent.pageIndex
    this.itemPerPage = this.pageEvent.pageSize
    this.pendingJobs()
  }

}
