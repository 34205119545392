<!-- delete Modal -->
<mat-card class="mat-elevation-z0 bdr mat-dialog-container" aria-hidden="true">
    <!-- heading -->
    <div class="main">
        <div class="top-text">
            <mat-card-title class="sign-text">Delete
            </mat-card-title>
        </div>
        <div class="input-field">
            <p>Are you sure you want to delete this job?</p>
        </div>
        <!-- button -->
        <div class="down-button">
            <button mat-button class="cancel" mat-dialog-close="false">No</button>
            <button mat-button class="submit" mat-dialog-close="true" (click)="deleteJob()">Yes</button>
        </div>

    </div>
</mat-card>