<!-- change modal -->
<mat-card class="mat-elevation-z0 bdr mat-dialog-container change-pass">
    <div class="main">
        <div class="top-text">
            <!-- upper Text -->
            <mat-card-title class="sign-text">Change Password
            </mat-card-title>
        </div>
        <div class="input-field">
            <form action="" [formGroup]="changePasswordForm">
                <!-- password Field -->
                <input type="password" class="user-name" placeholder="Old Password"
                    [type]="toggleEye ? 'password' : 'text'" formControlName='oldPassword' maxlength="32"
                     autocomplete="off">
                <!-- eye Password Protector  -->
                <mat-icon matSuffix (click)="toggleEye = !toggleEye">{{toggleEye ? 'visibility_off' :
                    'visibility'}}
                </mat-icon>
                <!-- validations Field-->
                <div class="error"
                    *ngIf="changePasswordForm.get('oldPassword').hasError('required') && (changePasswordForm.get('oldPassword').touched ||changePasswordForm.get('oldPassword').dirty)">
                    *Password is required.</div>
                <!--new Password Field-->
                <input type="password" class="user-name" placeholder="New Password"
                    [type]="toggleEye1 ? 'password' : 'text'" formControlName='newPassword' maxlength="32"
                    autocomplete="off">
                <!-- eye Password Protector  -->
                <mat-icon matSuffix (click)="toggleEye1 = !toggleEye1">{{toggleEye1 ? 'visibility_off' :
                    'visibility'}}
                </mat-icon>
                <!-- validations -->
                <div class="error"
                    *ngIf="changePasswordForm.get('newPassword').hasError('required') && (changePasswordForm.get('newPassword').touched ||changePasswordForm.get('newPassword').dirty)">
                    *Password is required.</div>
                <div class="error"
                    *ngIf="changePasswordForm.get('newPassword').hasError('pattern')&& (changePasswordForm.get('newPassword').touched||changePasswordForm.get('newPassword').dirty)">
                    *Password should have minimum 8 characters, at least 1 uppercase latter,
                    1 lowercase latter, 1 number and 1 special character.
                </div>
                <!-- confirm Password -->
                <input type="password" class="user-password" [type]="toggleEye2 ? 'password' : 'text'"
                    placeholder="Confirm Password" formControlName='confirmPassword' maxlength="32" autocomplete="off">
                <!-- eye Password Protector -->
                <mat-icon matSuffix (click)="toggleEye2 = !toggleEye2" class="second-icons">{{toggleEye2 ?
                    'visibility_off'
                    :
                    'visibility'}}
                </mat-icon>
                <!-- validations -->
                <div class="error"
                    *ngIf="(changePasswordForm.controls['newPassword'].value!==(changePasswordForm.controls['confirmPassword'].value) && (changePasswordForm.get('confirmPassword').touched || changePasswordForm.get('confirmPassword').dirty))">
                    *Password
                    and confirm password should be match.</div>
                <!-- button -->
                <div class="down-button">
                    <button mat-button mat-dialog-close="true" class="cancel">Cancel</button>
                    <button mat-button (click)="changePassword()" class="submit"
                        [disabled]="changePasswordForm.invalid">Submit</button>
                </div>
            </form>
        </div>

    </div>
</mat-card>