import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SigninComponent } from 'src/app/modal/auth/signin/signin.component';
import { JobSearchComponent } from 'src/app/modal/job-search/job-search.component';
import { CommonService } from 'src/app/provider/common.service';
import { LogOutComponent } from 'src/app/modal/auth/log-out/log-out.component';
declare var  $

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  showHeader: string;
  isLoggedIn: boolean = false;
  token: string;
  headerLogo: any;
  headerDetail: any = [];


  constructor(public commonService: CommonService, public dialog: MatDialog, public route: Router) {
  }

  ngOnInit(): void {
    this.commonService.showHeader.subscribe((res: any) => {
      if (this.commonService.isLoggedIn()) {
        this.getProfile()
      }
    });
    if (this.commonService.isLoggedIn()) {
      this.getProfile()
    }
    this.getHeaderLogo()
  }
 
  getProfile() {
    let url = "user/getProfile";
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe((res: any) => {

      if (res["statusCode"] == 200) {
        this.headerDetail = res["result"];
        // localStorage.setItem('name', res["result"]["firstName"] + " " + res["result"]["lastName"])
        // localStorage.setItem("profileStatus",res["result"]["profileComplete"])
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
      }
    });
  }

  getHeaderLogo() {
    let url = "user/viewLogo";
    this.commonService.showSpinner();
    this.commonService.getApi(url, 0).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.headerLogo = res["result"]["image"];
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
      }
    });
  }



  // open signin dialog
  openSignInDialog() {
    let dialogConfig = { hasBackdrop: false, skipHide: true };
    const dialogRef = this.dialog.open(SigninComponent, {
      width: "540px",
      height: "370px",
      autoFocus: false

    });
    // this.dialog.afterAllClosed
  }
  navigateToProfileComplitationAlert() {
    this.route.navigate(['/profile-completion-alert'])
  }

  // open search dialog
  openSearchDialog() {
    let dialogConfig = { hasBackdrop: false, skipHide: true };
    const dialogRef = this.dialog.open(JobSearchComponent, {
      width: "530px",
      height: "390px",
      autoFocus: false

    });
  }

  logOut() {
    let dialogConfig = { hasBackdrop: false, skipHide: true };
    const dialogRef = this.dialog.open(LogOutComponent, {
      width: "430px",
      height: "290px",
      autoFocus: false
    })
  }
}
