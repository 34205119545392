<!-- <app-advertisment></app-advertisment> -->

<div (click)="returnPage()" class="return_page">

    <img mat-card-xs-image src="/assets/images/coolicon.png" alt="" />
</div>
<h1 class="Approved_job">Approved Jobs</h1>
<div class="approved-job-table wrapper-content">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-describedby="approved-jobs">
        <!-- JobType Column -->
        <ng-container matColumnDef="JobType">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Job Type</th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.postedJobId)">
                {{ element?.jobDetails?.jobType?.categoryName || '---'}}
            </td>
        </ng-container>
        <!-- Phone Column -->
        <ng-container matColumnDef="Phone">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Phone</th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.postedJobId)">
                {{(element?.jobDetails?.hirerDetails?.mobileNumber | mask: '(000) 000-0000') || '---' }}
        </ng-container>
        <!-- email Column -->
        <ng-container matColumnDef="email">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Email</th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.postedJobId)">
                {{element?.jobDetails?.hirerDetails?.email || '---'}}
            </td>
        </ng-container>
        <!-- contact Column -->
        <ng-container matColumnDef="contact">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>contact</th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.postedJobId)">
                {{ element?.jobDetails?.hirerDetails?.firstName || '--'}} {{ element?.jobDetails?.hirerDetails?.lastName || '--'}}
            </td>
        </ng-container>

        <!-- Start date Column -->
        <ng-container matColumnDef="date">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>
                Start date
            </th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.postedJobId)">
                {{ (element?.jobDetails?.startDate | date :'MM/dd/yyyy, hh:mm a')|| '---' }}
            </td>
        </ng-container>
        <!--Table Button-->
        <ng-container matColumnDef="blank">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef></th>
            <td class="table_data" mat-cell *matCellDef="let element">
                <button (click)="map(element?.postedJobId)" class="Map_button" mat-raised-button color="primary">
          Map
        </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator (page)="pageEvent = $event" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="changePage($event)" aria-label="Select page">
    </mat-paginator>
</div>