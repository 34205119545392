import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "src/app/provider/common.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup; // New Form Group
  dialogCancel: boolean = true; // Material Dialog Cancel Variable Declare
  constructor(
    public dialog: MatDialogRef<ForgotPasswordComponent>,
    private router: Router,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    // Form group and Form Control Name With Validations
    this.forgotPasswordForm = new FormGroup({
      username: new FormControl("", [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/),
        Validators.maxLength(30),
      ]),
    });
  }
  // forget password API Integration
  forgetPassword() {
    let apiReqData = {
      email: this.forgotPasswordForm.value.username,
    };
    let apiReqUrl = "user/forgotPassword";
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
      if (res.statusCode == 200) {
        localStorage.setItem("email", this.forgotPasswordForm.value.username)
        this.commonService.hideSpinner();
        this.closeDialog()
        this.commonService.successToast(res.responseMessage);
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      this.commonService.errorToast(err.error.responseMessage);
    })
  }

  // Material Dialog Close After Open Second Dialog
  closeDialog() {
    this.dialog.close();
  }
  // Navigation Function 
  navigateToTermsOfServices() {
    this.router.navigate(["/terms-of-service"]);
    this.dialog.close();
  }
}
