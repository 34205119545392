<!-- <app-advertisment></app-advertisment> -->
<h1 class="Approved_job">Applicant Information</h1>
<div class="approved-job-table wrapper-content">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-describedby="applicants-information">
        <!-- Name Column -->
        <ng-container matColumnDef="Name">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Name</th>
            <td class="table_data" mat-cell *matCellDef="let element">
                {{ element['applicantDetails']['firstName'] || '---'}} {{ element['applicantDetails']['lastName'] || '---'}}
            </td>
        </ng-container>

        <!-- Experience Column -->
        <ng-container matColumnDef="Experience">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Experience</th>
            <td class="table_data" mat-cell *matCellDef="let element">
                {{ element['applicantDetails']['yearsOfExperience'] || '---'}}
            </td>
        </ng-container>


        <!-- Location Column -->
        <ng-container matColumnDef="Location">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Location</th>
            <td class="table_data" mat-cell *matCellDef="let element">
                {{ element?.applicantDetails?.address || '---' }}
            </td>
        </ng-container>

        <!-- Interviewing  Column -->
        <ng-container matColumnDef="Interviewing">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Hiring</th>
            <td class="table_data" mat-cell *matCellDef="let element">
                <!-- <input  type="checkbox" class="interviewing" (click)="element.interviewing==true?approveJob(element.id):inteviewerr()" [checked]="element?.applyStatus=='HIRED'" [disabled]="element?.applyStatus=='HIRED' "> -->
                <div class="Map_button_up" *ngIf="element?.applyStatus != 'HIRED'">
                    <button type="button" class="Map_button1" mat-raised-button color="primary" style=" background-color: rgb(255, 255, 255);width: 35%;border: 1px solid #192e98; color: #192e98;" (click)="element.interviewing==true?approveJob(element.id):inteviewerr()">
                     Hire
                    </button>
                </div>
                <div class="Map_button_up" *ngIf="element?.applyStatus == 'HIRED'">
                    <button type="button" class="Map_button" mat-raised-button color="primary" style=" background-color: green;width: 35%;">
                     Hired
                    </button>
                </div>
            </td>
        </ng-container>
        <!--Table Map Button -->
        <ng-container matColumnDef="blank">
            <th id="" class="table_heading" id="map" mat-header-cell *matHeaderCellDef></th>
            <td class="table_data" mat-cell *matCellDef="let element">
                <div class="Map_button_up" *ngIf="element?.applyStatus == 'PENDING' ">
                    <button type="button" class="Map_button" mat-raised-button color="primary" (click)="element.jobDetails.jobStatus == 'OPEN'?navigaetToApplicantsInfo(element?.applicantId,element.id,element?.interviewing):joberr()">
            View
          </button>

                </div>
                <!-- <div class="Map_button_up" *ngIf="element?.applyStatus == 'HIRED'">
                    <button type="button" class="Map_button" mat-raised-button color="primary" style=" background-color: green;width: 80%;">
            Hire
          </button>
                </div> -->
            </td>
        </ng-container>
        <!--Table delete Button-->

        <ng-container matColumnDef="delete">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef></th>
            <td class="table_data" mat-cell *matCellDef="let element">
                <div *ngIf="element?.applyStatus == 'PENDING'" class="Map_button_up">
                    <button type="button" class="Map_button1" mat-raised-button (click)="element.jobDetails.jobStatus == 'OPEN'?DeclineJob(element.id):joberr()">Decline</button>
                </div>
                <div *ngIf="element?.applyStatus == 'DENIED'" class="Map_button_up">
                    <button type="button" *ngIf="element?.applyStatus=='DENIED'" class="Map_button1" mat-raised-button style="width: 80%">Declined</button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event" (page)="changePage($event)" aria-label="Select page">
    </mat-paginator>
</div>