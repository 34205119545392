<!-- <app-advertisment></app-advertisment> -->

<div (click)="returnPage()" class="return_page">
    <img mat-card-xs-image src="/assets/images/coolicon.png" alt="" />
</div>
<h1 class="Approved_job">Pending Jobs</h1>
<div class="approved-job-table wrapper-content">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-describedby="pending-jobs">
        <!-- JobType Column -->
        <ng-container matColumnDef="JobType">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Job Type</th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.postedJobId)">
                {{ element?.jobDetails?.jobType?.categoryName || '---'}}
            </td>
        </ng-container>

        <!-- Duration Column -->
        <ng-container matColumnDef="Duration">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Duration</th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.postedJobId)">
                {{ element?.jobDetails?.duration || '---'}}
            </td>
        </ng-container>

        <!-- Start  date Type Column -->
        <ng-container matColumnDef="Start_date">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>
                Start Date
            </th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.postedJobId)">
                {{ (element?.jobDetails?.startDate | date :'MM/dd/yyyy, hh:mm a')||'--'}}
            </td>
        </ng-container>

        <!-- City Column -->
        <ng-container matColumnDef="City">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>City</th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.postedJobId)">
                {{ element?.jobDetails?.city || '---'}}
            </td>
        </ng-container>

        <!-- Payment_type Column -->
        <ng-container matColumnDef="Payment_type">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>
                Payment Type
            </th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.postedJobId)">
                {{ element?.jobDetails?.paymentTypes?.paymentName || '---'}}
            </td>
        </ng-container>

        <!-- Payment  Column -->
        <ng-container matColumnDef="payment">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef (click)="detailDialog(element?.postedJobId)">
                Payment
            </th>
            <td class="table_data" mat-cell *matCellDef="let element">
                {{ element?.jobDetails?.payment || '---'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="intreview">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef (click)="detailDialog(element?.postedJobId)">
                Interviewed
            </th>
            <td class="table_data" mat-cell *matCellDef="let element">
                <!-- <input type="checkbox" class="interviewing" [checked]="element?.interviewing" data-placement="top" [title]="element?.interviewing ? 'Interview Schedule!' : 'Interview Pending!'" onmouseenter="$(this).tooltip('show')" [ngStyle]="{'background': element?.interviewing ? 'green' : 'null' }"
                    disabled /> -->
                <i class="fa fa-check right-dat " *ngIf="element?.interviewing" data-toggle="tooltip" data-placement="top" title=" Interview Schedule!" aria-hidden="true"></i>
                <i class="fa fa-times cross-dat" *ngIf="!element?.interviewing" data-toggle="tooltip" data-placement="top" title="Interview Pending!" aria-hidden="true"></i>
            </td>

        </ng-container>
        <!-- JobStaus Column -->
        <!-- <ng-container matColumnDef="JobStatus">
      <th id="" class="table_heading" mat-header-cell *matHeaderCellDef (click)="detailDialog(element?.postedJobId)">
        Job Status
      </th>
      <td class="table_data" mat-cell *matCellDef="let element" style="color: green;">
             Interviewing Status in process..
      </td>
    </ng-container> -->

        <!--Table Map Button -->
        <ng-container matColumnDef="blank">
            <th class="table_heading" id="map" mat-header-cell *matHeaderCellDef></th>
            <td class="table_data" mat-cell *matCellDef="let element">
                <div class="Map_button_up">
                    <button class="Map_button" (click)="map(element?.postedJobId)" mat-raised-button color="primary">
            Map
          </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator (page)="pageEvent = $event" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="changePage($event)" aria-label="Select page">
    </mat-paginator>

</div>