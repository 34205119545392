import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {
  companyDetailsForm:FormGroup
  regExemail= /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i;
  regExname=/^[a-zA-Z]{3,20}/;
  regExphone=/^([+]\d{2})?\d{10}$/;

  constructor() { }

  ngOnInit(): void {
    this.companyDetailsForm=new FormGroup({
      'companyName':new FormControl("",Validators.required),
      'ownerName':new FormControl("",[Validators.required,Validators.pattern(this.regExname)]),
      'phone':new FormControl("",[Validators.required,Validators.pattern(this.regExphone)]),
      'email':new FormControl("",[Validators.required,Validators.pattern(this.regExemail)]),
      'address':new FormControl("",[Validators.required]),
    })
  }

}
