<mat-card class="mat-elevation-z0 bdr mat-dialog-container">
    <div class="main">
        <div>
            <label for="">Name</label>
            <br /><input type="text" [value]="applicantData.firstName + ' ' + applicantData.lastName" readonly autocomplete="off" />
        </div>
        <div>
            <label for="">Location</label>
            <br /><input type="text" [value]="city || 'Not Available'" readonly autocomplete="off" />
        </div>
        <div>
            <label for="">Phone</label>
            <br /> <input type="text" [value]="applicantData.mobileNumber |  mask: '(000) 000-0000'" readonly autocomplete="off" />
        </div>
        <div>
            <label for="">Email</label>
            <br /><input type="text" [value]="applicantData.email" readonly autocomplete="off" />
        </div>
        <div>
            <label for="">Year of experience</label>
            <br /><input type="text" [value]="applicantData.yearsOfExperience" readonly autocomplete="off" />
        </div>
        <label for="">Certifications</label>
        <input type="text" [value]="applicantData?.certificate || 'No Certficate Availabe'" readonly autocomplete="off" />
        <div>
            <label for="">Start Date</label>
            <br /><input type="datetime-local" placeholder="$4000" [min]="todayDate" [(ngModel)]="startdate" (keypress)="$event.preventDefault()" style="text-transform: lowercase !important;" autocomplete="off" />
        </div>

    </div>
    <div class="buttons">
        <div class="interview">
            <button mat-button mat-dialog-close="true" color="primary" class="cancel">Cancel</button>
            <button mat-raised-button color="primary" [mat-dialog-close]="'1'" [disabled]="!startDate||status==true" mat-dialog-close="false" (click)="interview()">Interview</button>
        </div>
    </div>
</mat-card>