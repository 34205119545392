<!-- user Apply job Modal -->
<mat-card style="overflow-y: scroll !important;
height: 500px;" class="mat-elevation-z0 bdr mat-dialog-container">
    <div class="main">
        <!-- top Text -->
        <div class="top-text details-new">
            <mat-card-title class="sign-text">Details </mat-card-title>
        </div>
        <div class="input-field">

            <mat-card class="bord" *ngIf="userType == 'Hirer'">
                <div class="top-text">
                    <h2>Contacts</h2>
                </div>
                <mat-card-header>
                    <mat-card-title>Company:<span class="sub-head">{{viwejobs?.hirerDetails?.companyName || '---'}}</span>
                    </mat-card-title>
                    <mat-card-title>Phone:<span class="sub-head">{{(viwejobs?.hirerDetails?.mobileNumber | mask: '(000) 000-0000')
              || '---'}}</span>
                    </mat-card-title>
                    <mat-card-title>Email:<span class="sub-head">
              {{viwejobs?.hirerDetails?.email || '---'}}</span></mat-card-title>
                    <mat-card-title>Contact Person:<span class="sub-head">
              {{viwejobs?.hirerDetails?.firstName || '--'}} {{viwejobs?.hirerDetails?.lastName || '--'}}</span>
                    </mat-card-title>
                </mat-card-header>


            </mat-card>
            <mat-card class="bord" *ngIf="userType != 'Hirer' && viwejobs?.applyStatus != 'DENIED' ">
                <div class="top-text">
                    <h2>Contacts</h2>
                </div>
                <mat-card-header *ngIf="(viwejobs?.applyStatus != 'PENDING' && viwejobs?.applyStatus != 'DENIED' ) || showContact">
                    <div *ngIf="viwejobs?.isApplied">
                        <mat-card-title>Company:<span class="sub-head">{{viwejobs?.hirerDetails?.companyName || '---'}}</span>
                        </mat-card-title>
                        <mat-card-title>Phone:<span class="sub-head">{{(viwejobs?.hirerDetails?.mobileNumber | mask: '(000)
                000-0000')
                || '---'}}</span>
                        </mat-card-title>
                        <mat-card-title>Email:<span class="sub-head">
                {{viwejobs?.hirerDetails?.email || '---'}}</span></mat-card-title>
                        <mat-card-title>Contact Person:<span class="sub-head">
                {{viwejobs?.hirerDetails?.firstName || '--'}} {{viwejobs?.hirerDetails?.lastName || '--'}}</span>
                        </mat-card-title>
                    </div>

                </mat-card-header>
                <mat-card-header *ngIf="false">

                    <mat-card-title>Company:<span class="sub-head">{{viwejobs?.hirerDetails?.companyName || '---'}}</span>
                    </mat-card-title>
                    <mat-card-title>Phone:<span class="sub-head">{{(viwejobs?.hirerDetails?.mobileNumber | mask: '(000)
              000-0000')
              || '---'}}</span>
                    </mat-card-title>
                    <mat-card-title>Email:<span class="sub-head">
              {{viwejobs?.hirerDetails?.email || '---'}}</span></mat-card-title>
                    <mat-card-title>Contact Person:<span class="sub-head">
              {{viwejobs?.hirerDetails?.firstName || '--'}} {{viwejobs?.hirerDetails?.lastName || '--'}}</span>
                    </mat-card-title>

                </mat-card-header>
                <mat-card-header *ngIf="!viwejobs?.isApplied">
                    <mat-card-title><span>This information will display if they hire you for the job.</span>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-header *ngIf="viwejobs?.isApplied && viwejobs?.applyStatus == 'PENDING' && !currUrl?.includes('employee-profile')">
                    <!-- <mat-card-title><span>You have been selected for an interview, the employer will contact you shortly. </span>
                    </mat-card-title> -->
                    <mat-card-title><span>Your contact information has been sent to the contractor. <br>
                                          Please wait for them to contact you. </span>
                    </mat-card-title>

                </mat-card-header>


            </mat-card>
            <!-- <mat-card class="bord"
        *ngIf="(viwejobs?.applyStatus != 'PENDING' && viwejobs?.applyStatus != 'DENIED') || showContact">
        <div class="top-text">
          <h2>Contacts</h2>
        </div>
        <mat-card-header *ngIf="viwejobs?.isApplied==true  || userType == 'Hirer'">
          <mat-card-title>Company:<span class="sub-head">{{viwejobs?.hirerDetails?.companyName || '---'}}</span>
          </mat-card-title>
          <mat-card-title>Phone:<span class="sub-head">{{(viwejobs?.hirerDetails?.mobileNumber | mask: '(000) 000-0000')
              || '---'}}</span>
          </mat-card-title>
          <mat-card-title>Email:<span class="sub-head">
              {{viwejobs?.hirerDetails?.email || '---'}}</span></mat-card-title>
          <mat-card-title>Contact Person:<span class="sub-head">
              {{viwejobs?.hirerDetails?.firstName || '--'}} {{viwejobs?.hirerDetails?.lastName || '--'}}</span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-header *ngIf="!viwejobs?.isApplied && userType != 'Hirer'">
          <mat-card-title><span>This information will display if they hire you for the job.</span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-header *ngIf="viwejobs?.applyStatus == 'PENDING' &&  userType != 'Hirer' && !viwejobs?.isApplied">
          <mat-card-title><span>You have been selected for an interview, the employer will contact you shortly. </span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-header *ngIf="  userType != 'Hirer' && viwejobs?.isApplied">
          <mat-card-title><span>You have been selected for an interview, the employer will contact you shortly. </span>
          </mat-card-title>
        </mat-card-header>

      </mat-card>
      <mat-card class="bord" *ngIf="viwejobs?.applyStatus == 'PENDING' &&  userType != 'Hirer' && !viwejobs?.isApplied">
        <div class="top-text">
          <h2>Contacts</h2>
        </div>


        <mat-card-header>
          <mat-card-title><span>You have been selected for an interview, the employer will contact you shortly. </span>
          </mat-card-title>
        </mat-card-header>

      </mat-card> -->
        </div>
        <div class="next-border">
            <mat-card class="next-bord">
                <div class="top-text">
                    <h2>Job</h2>
                </div>
                <mat-card-header>
                    <mat-card-title class="next-head">Job Type:<span class="sub-head">{{viwejobs?.jobType?.categoryName ||
              '---'}}</span></mat-card-title>
                    <mat-card-title class="next-head">Equipment Type:<span class="sub-head">{{viwejobs?.subJobType?.subCatName ||
              '---'}}</span>
                    </mat-card-title>
                    <mat-card-title class="next-head">Duration:<span class="sub-head">{{viwejobs?.duration || '---'}}
              </span></mat-card-title>
                    <mat-card-title class="next-head">Start Date:<span class="sub-head">{{ (startDate |
              date :'MM/dd/yyyy, hh:mm a' )|| '---'}}</span>
                    </mat-card-title>
                    <mat-card-title class="next-head">Location:<span class="sub-head">{{viwejobs?.city || '---'}}</span>
                    </mat-card-title>
                    <!-- <mat-card-title class="next-head">Equipment Type:<span class="sub-head">{{viwejobs?.equipmentType ||
              '---'}}</span>
                    </mat-card-title> -->
                    <mat-card-title class="next-head">Quantity:<span class="sub-head">{{viwejobs?.quantity || '---'}}</span>
                    </mat-card-title>
                    <mat-card-title class="next-head">Price:<span class="sub-head">{{ (viwejobs?.payment | currency:'USD') || '---'}}</span>
                    </mat-card-title>
                    <mat-card-title class="next-head">Payment Type:<span class="sub-head">{{viwejobs?.paymentTypes?.paymentName ||
              '---'}}</span>
                    </mat-card-title>
                    <mat-card-title class="next-head notes-tite">Notes:<span class="sub-head">{{viwejobs?.notes ||
              '---'}}</span>
                    </mat-card-title>
                </mat-card-header>
            </mat-card>
        </div>
        <span *ngIf="data.openJob != 'openJobHirer'">
      <div class="down-button mb-3">
        <button mat-button class="cancel" mat-dialog-close="true">Cancel</button>
        <button *ngIf="viwejobs.isApplied==false || !viwejobs.isApplied" mat-button class="submit"
          (click)="applyJob();alertApplyJob()" [mat-dialog-close]="apply">Submit</button>
        <button *ngIf="viwejobs.isApplied==true" mat-button class="submit" (click)="alertApplyJob()">Submitted</button>
      </div>
      <div class="term" *ngIf="commonService.header() == 'Applicant'">
        <button *ngIf="viwejobs.isReported==false" mat-button class="report" mat-dialog-close
          (click)="reportDialog(viwejobs?.id)">
          Report
        </button>
        <button *ngIf="viwejobs.isReported==true" mat-button class="report" mat-dialog-close>
          Reported
        </button>
      </div>
    </span>
        <div class="down-button mb-3" *ngIf="data.openJob == 'openJobHirer'">
            <button mat-button class="cancel" mat-dialog-close="true">
        Cancel
      </button>

        </div>
    </div>
</mat-card>