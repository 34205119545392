<div class="wrapper-content">
    <mat-card class="mat-elevation-z0">
        <div class="contact">
            <div class="contact-us">
                <div class="contact-icon">
                    <img src="/assets/images/contact.png" alt="">
                </div>
                <div class="main">
                    <div class="top-text">
                        <!-- upper Text -->
                        <mat-card-title class="sign-text">Reset Password
                        </mat-card-title>
                    </div>
                    <div class="input-field">
                        <form action="" [formGroup]="resetPasswordForm">
                            <!-- new Password -->
                            <input type="password" class="user-name" placeholder="New Password"
                                [type]="toggleEye ? 'password' : 'text'" formControlName='newPassword' maxlength="32">
                            <!-- eye Password Protector  -->
                            <mat-icon matSuffix (click)="toggleEye = !toggleEye">{{toggleEye ? 'visibility_off' :
                                'visibility'}}
                            </mat-icon>
                            <!-- validations -->
                            <div class="error"
                                *ngIf="resetPasswordForm.get('newPassword').hasError('required') && (resetPasswordForm.get('newPassword').touched ||resetPasswordForm.get('newPassword').dirty)">
                                *New password is required.</div>
                            <div class="error"
                                *ngIf="resetPasswordForm.get('newPassword').hasError('pattern')&& (resetPasswordForm.get('newPassword').touched||resetPasswordForm.get('newPassword').dirty)">
                                *Password should have minimum 8 characters, at least 1 uppercase latter,
                                1 lowercase latter, 1 number and 1 special character.
                            </div>
                            <!-- confirm Password -->
                            <input type="password" class="user-password" [type]="toggleEye1 ? 'password' : 'text'"
                                placeholder="Confirm Password" formControlName='confirmPassword' maxlength="32">
                            <!-- eye Password Protector -->
                            <mat-icon matSuffix (click)="toggleEye1 = !toggleEye1">{{toggleEye1 ?
                                'visibility_off'
                                :
                                'visibility'}}
                            </mat-icon>
                            <!-- validations -->
                            <div class="error"
                                *ngIf="(resetPasswordForm.controls['newPassword'].value!==(resetPasswordForm.controls['confirmPassword'].value) && (resetPasswordForm.get('confirmPassword').touched || resetPasswordForm.get('confirmPassword').dirty))">
                                *Password
                                and confirm password should be match.</div>
                        </form>
                    </div>
                    <!-- button -->
                    <div class="down-button">
                        <button mat-button class="cancel" routerLink="/homepage">Cancel</button>
                        <button mat-button (click)="resetPassword()" class="submit">Submit</button>
                    </div>
                </div>

            </div>
        </div>
    </mat-card>
</div>