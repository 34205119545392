<!-- <app-advertisment></app-advertisment> -->

<div (click)="returnPage()" class="return_page">
    <img src="/assets/images/coolicon.png" alt="" />
</div>
<h1 class="Approved_job">Open Jobs</h1>
<div class="approved-job-table wrapper-content">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-describedby="open-jobs">
        <!-- JobType Column -->
        <ng-container matColumnDef="JobType">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Job Type</th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.id)">
                {{ element?.jobType?.categoryName || "---"}}
            </td>
        </ng-container>

        <!-- Phone Column -->
        <ng-container matColumnDef="Phone">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Phone</th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.id)">
                {{(element?.hirerDetails?.mobileNumber | mask: '(000) 000-0000')|| '---'}}
            </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="Email">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Email</th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.id)">
                {{ element?.hirerDetails?.email || '---'}}
            </td>
        </ng-container>

        <!-- Contact Column -->
        <ng-container matColumnDef="Contact">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>Contact</th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.id)">
                {{ element?.hirerDetails?.firstName || '--'}} {{ element?.hirerDetails?.lastName || '--'}}
            </td>
        </ng-container>

        <!-- Start  date Type Column -->
        <ng-container matColumnDef="Start_date">
            <th id="" class="table_heading" mat-header-cell *matHeaderCellDef>
                Start Date
            </th>
            <td class="table_data" mat-cell *matCellDef="let element" (click)="detailDialog(element?.id)">
                {{ (element?.startDate | date :'MM/dd/yyyy, hh:mm a')|| '---'}}
            </td>
        </ng-container>

        <!--Table Map Button  
-->
        <ng-container matColumnDef="blank">
            <th class="table_heading" id="map" mat-header-cell *matHeaderCellDef></th>
            <td class="table_data" mat-cell *matCellDef="let element">
                <div class="Map_button_up">
                    <button (click)="map(element?.id)" class="Map_button" mat-raised-button color="primary">
            Map
          </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator (page)="pageEvent = $event" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="changePage($event)" aria-label="Select page">
    </mat-paginator>
</div>