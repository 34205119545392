import { AfterViewInit, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var H: any;

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, AfterViewInit {
  map: any;
  @ViewChild('map') mapElement: any;
  jobId: any;
  lat = 43.879078;
  lng = -103.4615581;
  longitude: any;
  latitude: any;
  address: any;
  jobsList: any;
  viwejobs: any;
  center: any;
  JobHeading: any;
  approved: any = []
  pending: any = []
  denied: any = []
  open: any = []
  closed: any = []
  completed: any = []
  // markers:any = [];
  zoom = 14;
  markers: any = [];
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,



  }

  key = "AIzaSyD33xHDSplTdBqeH6ZSx1UOObrsuCzfmnQ"

  constructor(private commonService: CommonService, private route: ActivatedRoute) {

    this.route.queryParams.subscribe((res) => {
      this.jobId = res.Id
      this.jobsList = res.JobView
    })

  }

  mapProperties = {}
  ngOnInit(): void {
    // this.getAllJoblist()
    if (this.jobsList) {
      this.JobHeading = this.jobsList.charAt(0).toUpperCase() + this.jobsList.slice(1);
    }
  }
  ngAfterViewInit(): void {
    this.getAllJoblist()
  }
  getAllJoblist() {
    switch (this.jobsList) {
      case 'approved':
        this.getApprovedJob()
        break;
      case 'denied':
        this.getDeniedJob()
        break;
      case 'pending':
        this.getPendingJob()
        break;
      case 'open':
        this.getOpenJob()
        break;
      case 'closed':
        this.getClosedJob()
        break;
      default:
        this.viewApplyJob()
        break;
    }
  }


  getPendingJob() {
    let url = "user/pendingJobList"

    this.commonService.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.pending = res["result"]["rows"]
        this.markers = this.pending
        const mapProperties = {
          center: new google.maps.LatLng(this.lat, this.lng),
          zoom: 4,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
        this.markers.forEach(location => {
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(location.jobDetails.latitude, location.jobDetails.longitude),
            map: this.map
          });
        });
        this.commonService.hideSpinner()
        // this.commonService.successToast(res['responseMessage'])
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res['responseMessage'])
      }
    }, (err: any) => {
      const mapProperties = {
        center: new google.maps.LatLng(this.lat, this.lng),
        zoom: 2,
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    })
  }
  getDeniedJob() {
    let url = "user/deniedJobList"

    this.commonService.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.denied = res["result"]["rows"]
        this.markers = this.denied
        const mapProperties = {
          center: new google.maps.LatLng(this.lat, this.lng),
          zoom: 4,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
        this.markers.forEach(location => {
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(location.jobDetails.latitude, location.jobDetails.longitude),
            map: this.map
          });
        });
        this.commonService.hideSpinner()
        // this.commonService.successToast(res['responseMessage'])
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res['responseMessage'])
      }
    }, (err: any) => {
      const mapProperties = {
        center: new google.maps.LatLng(this.lat, this.lng),
        zoom: 2,
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    })
  }
  getApprovedJob() {
    let url = "user/approvedJobList"
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.approved = res["result"]["rows"]
        this.markers = this.approved
        const mapProperties = {
          center: new google.maps.LatLng(this.lat, this.lng),
          zoom: 4,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
        this.markers.forEach(location => {
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(location.jobDetails.latitude, location.jobDetails.longitude),
            map: this.map
          });
        });
        this.commonService.hideSpinner()
        // this.commonService.successToast(res['responseMessage'])
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res['responseMessage'])
      }
    }, (err: any) => {
      const mapProperties = {
        center: new google.maps.LatLng(this.lat, this.lng),
        zoom: 2,
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    })
  }

  // get open job list
  getOpenJob() {
    let url = "jobs/openJobList"

    this.commonService.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.open = res["result"]["rows"]
        this.markers = this.open
        const mapProperties = {
          center: new google.maps.LatLng(this.lat, this.lng),
          zoom: 4,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
        this.markers.forEach(location => {
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(location.latitude, location.longitude),
            map: this.map
          });
        });
        this.commonService.hideSpinner()
        // this.commonService.successToast(res['responseMessage'])
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res['responseMessage'])
      }
    }, (err: any) => {
      const mapProperties = {
        center: new google.maps.LatLng(this.lat, this.lng),
        zoom: 2,
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    })
  }

  getClosedJob() {
    let url = "jobs/closedJobList"

    this.commonService.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.closed = res["result"]["rows"]
        this.markers = this.closed
        const mapProperties = {
          center: new google.maps.LatLng(this.lat, this.lng),
          zoom: 4,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
        this.markers.forEach(location => {
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(location.latitude, location.longitude),
            map: this.map
          });
        });
        this.commonService.hideSpinner()
        // this.commonService.successToast(res['responseMessage'])
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res['responseMessage'])
      }
    }, (err: any) => {
      const mapProperties = {
        center: new google.maps.LatLng(this.lat, this.lng),
        zoom: 2,
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    })
  }

  marker: any = []
  getmapdetails(lat, long) {

    this.center = new google.maps.LatLng(lat, long)

    this.marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, long),
      map: this.map,
      // animation: google.maps.Animation.BOUNCE,

      icon:// 'https://icon-library.com/images/current-location-icon/current-location-icon-8.jpg'
      {
        url: './assets/images/map-mark.png',
        size: new google.maps.Size(36, 50),
        scaledSize: new google.maps.Size(36, 50),
        anchor: new google.maps.Point(0, 50),
      }
    })
    this.marker.setMap()
  }

  getAddress(lat, long) {
    let apiReqUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyD33xHDSplTdBqeH6ZSx1UOObrsuCzfmnQ`
    this.commonService.getThirdPartyApi(apiReqUrl).subscribe((res: any) => {
      this.address = res["body"]["results"][0]["formatted_address"];
    })
  }
  viewApplyJob() {
    let apiReqUrl = `jobs/viewJobsByUser?id=${this.jobId}`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.viwejobs = res.result;
        this.latitude = res.result.latitude;
        this.longitude = res.result.longitude;
        this.getmapdetails(this.latitude, this.longitude)
        this.getAddress(this.latitude, this.longitude)
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    })
  }

}
