import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, } from "@angular/forms";
import { CommonService } from 'src/app/provider/common.service';
import { Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
@Component({
  selector: 'app-job-search',
  templateUrl: './job-search.component.html',
  styleUrls: ['./job-search.component.scss']
})
export class JobSearchComponent implements OnInit {
  jobSearchForm: FormGroup;
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  categoryId: any;
  dataSource: any = [];
  distance: any;
  latitude: any;
  longitude: any;
  jobId: any;
  city: any;
  options: any;
  zipCode: any;
  status: boolean;
  subjobId: any;
  submitted: boolean = false;
  category: any = [];
  subCategory: any = [];
  profileDetail: any = []
  submit: boolean = false


  public handleAddressChange(address: Address) {

    this.options = []
    // console.log(address.formatted_address);
    this.longitude = address.geometry.location.lng()
    this.latitude = address.geometry.location.lat()

    // console.log('lat ', this.latitude, 'long', this.longitude);
    this.options.push(address.formatted_address);
  }
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<JobSearchComponent>, public commonService: CommonService, public router: Router) {
    var a = JSON.parse(localStorage.getItem("searchDetails"))
    this.options = (a?.location == 'undefined') ? '' : a?.location
    this.radius = (a?.radius == 'undefined') ? '' : a?.radius
    this.latitude = a?.lat
    this.longitude = a?.lang
  }

  radius: any
  ngOnInit(): void {

    this.jobSearchForm = new FormGroup({
      jobType: new FormControl("", [Validators.required,]),
      jobSubType: new FormControl("", [Validators.required,]),
      distance: new FormControl("", [Validators.required,]),
      city: new FormControl("", [Validators.required,
      ]),
    });
    this.getProfile()
    this.getCategory();

    // if(!this.commonService.isLoggedIn()){
    //   this.jobSearchForm.value.city=localStorage.getItem('Location')
    // }
    // this.jobSearchForm.valueChanges.subscribe((res) => {
    //   if (res.city != '' && res.city != undefined) {
    //    this.jobSearchForm.value.city=''
    //   }

    // })
  }

  getCategory() {
    let apiCategoryUrl = "jobs/categoryList";
    this.commonService.showSpinner()
    this.commonService.getApi(apiCategoryUrl, 0).subscribe((res: any) => {
      console.log(res)
      if (res.statusCode == 200) {
        this.category = res["result"]
        // this.getSubCategory(res["result"]["id"])
        this.getSubCategory(0)
        this.commonService.hideSpinner();
      }
      else {
        this.commonService.hideSpinner();
      }
    })
  }

  getSubCategory(id) {


    let apiSubCategoryUrl = ""
    console.log(this.category);


    if (id == 0) {
      apiSubCategoryUrl = `jobs/searchCategoryList?jobField=${this.job_Type}`
    } else {
      apiSubCategoryUrl = `jobs/searchCategoryList?jobField=${id}`
    }


    this.jobSearchForm.controls['jobSubType'].reset()
    this.commonService.showSpinner()
    this.commonService.getApi(apiSubCategoryUrl, 0).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.subCategory = res["result"]
        // console.log(this.subCategory);
        if (id == 0) {
          this.jobSearchForm.patchValue({

            jobSubType: this.job_subType
            // city: `dsfgdsf${this.city}`
          })

        }

        this.commonService.hideSpinner();
      }
      else {
        // this.subCategory = [];
        this.commonService.hideSpinner();
      }
    }, (error: any) => {
      this.subCategory = [];
    })
  }



  // getSubCatJobId(sub_id) {
  //   this.subjobId = sub_id;
  // }
  getCatJobId(id) {
    this.jobId = id;
  }


  closeDialog() {
    this.dialogRef.close();
  }

  search() {
    if (this.jobId || this.subjobId || this.jobSearchForm.value.city || this.jobSearchForm.value.distance) {
      this.closeDialog()
      if (this.status == false) {
        this.router.navigate(["/profile-completion-alert"], { queryParams: { Status: this.status } });

      }
      else {
        this.router.navigate(['/search-jobs'], {
          queryParams: {
            "maximumDistance": this.jobSearchForm.value.distance,
            "city": String(this.jobSearchForm.value.city || ''),
            "latitude": this.latitude,
            "longitude": this.longitude,
            "jobId": this.jobSearchForm.value.jobType,
            // "subJobTypeId": this.subjobId,
            "subJobTypeId": this.jobSearchForm.value.jobSubType,
            "withSearch": true
          }

        })
      }

    }


  }
  job_Type: any
  job_subType: any

  getProfile() {
    let url = "user/getProfile";
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res["statusCode"] == 200) {
        this.profileDetail = res["result"];
        this.zipCode = res["result"]["zipCode"]
        this.getZipCode(this.zipCode)
        // console.log(this.city);
        this.jobSearchForm.patchValue({
          distance: res["result"]["RadiusSearch"],
          jobType: res["result"]["jobField"],
          jobSubType: res["result"]["expertise"],
          // city: `dsfgdsf${this.city}`
        })
        this.job_Type = res["result"]["jobField"]
        this.job_subType = res["result"]["expertise"],
          this.getCategory();

        console.log(res["result"]["expertise"])
        console.log(this.category)
        let categoryId = this.category.findIndex(x => x.categoryName == res["result"]["expertise"])
        console.log(categoryId)
        // this.getSubCategory(res["result"]["expertise"])
        // console.log(res["result"]["expertise"]);

        this.status = this.profileDetail.profileComplete
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
      }
    });
  }

  getZipCode(zipcode) {
    let apiCategoryUrl = "user/validateZipCode";
    const data = {
      zipCode: zipcode
    }
    let responseZipCode = []
    this.commonService.showSpinner()
    this.commonService.postApi(apiCategoryUrl, data, 0).subscribe((res: any) => {
      if (res.statusCode == 200) {
        console.log(res["result"][`${zipcode}`]);
        responseZipCode = res["result"][`${zipcode}`].filter((res) => {
          return res?.country_code == "US"
        })
        console.log(responseZipCode);

        this.city = responseZipCode[0]['city']
        this.latitude = responseZipCode[0]['latitude']
        this.longitude = responseZipCode[0]['longitude']
        this.jobSearchForm.patchValue({
          city: this.city

        })
        this.commonService.hideSpinner();
        // this.commonService.successToast(res["responseMessage"]);
      }
      else {
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res["responseMessage"]);
      }
    })
  }
  onPress() {

    if (!this.options) {
      this.radius = ""
    }
  }


  onSubmit() {

    if (this.jobSearchForm.value.categoryName || this.jobSearchForm.value.subCatName || this.jobSearchForm.value.city || this.jobSearchForm.value.distance) {
      this.submit = true
    }
    else {
      this.submit = false
    }
  }

}
