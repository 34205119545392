import { RouterModule } from "@angular/router";
import { Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { UserApplyJobComponent } from "src/app/modal/user-apply-job/user-apply-job.component";
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from "src/app/provider/common.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";


@Component({
  selector: "app-approved-jobs",
  templateUrl: "./approved-jobs.component.html",
  styleUrls: ["./approved-jobs.component.scss"],
})
export class ApprovedJobsComponent implements OnInit {
  displayedColumns: string[] = [
    "JobType",
    "Phone",
    "email",
    "contact",
    "date",
    "blank",
  ];
  dataSource: any = [];
  currentPage: number = 0;
  itemPerPage: number = 25;
  length: any;
  pageSize = 25;
  pageEvent: PageEvent;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(private route: Router, public dialog: MatDialog, private service: CommonService) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit(): void {
    this.approvedJobList()
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  map(id) {
    this.route.navigate(["/google-map"], { queryParams: { Id: id } });
  }
  returnPage() {
    this.route.navigate(["/profile-completion-alert"]);
  }

  detailDialog(id) {
    const dialogRef = this.dialog.open(UserApplyJobComponent, {
      width: '450px',
      data: { dataKey: id, openJob: "openJobHirer" },
      height: 'max-content'
    });
  }

  approvedJobList() {
    let url = "user/approvedJobList?page=" + this.currentPage + 1 + "&limit=" + this.itemPerPage
    this.service.showSpinner()
    this.service.getApi(url, 1).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.dataSource = res["result"]["rows"]
        this.length = res["result"]["count"]
        this.service.hideSpinner()
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    }
    )
  }
  setPageSizeOptions(setPageSizeOptionsInput: string) {


    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }
  changePage(event) {
    this.currentPage = this.pageEvent.pageIndex
    this.itemPerPage = this.pageEvent.pageSize
    this.approvedJobList()
  }

}
