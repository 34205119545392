import { AfterContentChecked, Component } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { CommonService } from './provider/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentChecked {
  ngAfterContentChecked() {
    // console.clear()
  }
  
  title = 'site-development';
  currUrl: any
  showHeader: boolean = true
  constructor(private routes: Router, public service: CommonService, private bnIdle: BnNgIdleService) {
    this.bnIdle.startWatching(1800).subscribe((res) => {
      if (res) {
        // console.log("session expired");
        this.service.logout()
      }
    });
    this.routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
        if (this.currUrl?.includes('verfy-account')) {
          this.showHeader = false
        } else {
          this.showHeader = true
        }
      }
    })
  }
}
