import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SignupComponent } from "./modal/auth/signup/signup.component";
import { JobSearchComponent } from "./modal/job-search/job-search.component";
import { SigninComponent } from "./modal/auth/signin/signin.component";
import { ForgotPasswordComponent } from "./modal/auth/forgot-password/forgot-password.component";
import { EmployeeProfileComponent } from "./modal/employee/employee-profile/employee-profile.component";
import { GoogleMapComponent } from "./pages/google-map/google-map.component";
import { SearchJobsComponent } from "./pages/jobs/search-jobs/search-jobs.component";
import { ViewJobsComponent } from "./pages/jobs/view-jobs/view-jobs.component";
import { TermsOfServiceComponent } from "./pages/static-content/terms-of-service/terms-of-service.component";
import { EmployeeDetailsComponent } from "./modal/employee/employee-details/employee-details.component";
import { DeniedJobsComponent } from "./pages/jobs/denied-jobs/denied-jobs.component";
import { CompletedJobsComponent } from "./pages/jobs/completed-jobs/completed-jobs.component";
import { ClosedJobsComponent } from "./pages/jobs/closed-jobs/closed-jobs.component";
import { ProfileCompletionAlertComponent } from "./pages/alerts/profile-completion-alert/profile-completion-alert.component";
import { ApprovedJobsComponent } from "./pages/jobs/approved-jobs/approved-jobs.component";
import { UserApplyJobComponent } from "./modal/user-apply-job/user-apply-job.component";
import { ChangePasswordComponent } from "./modal/auth/change-password/change-password.component";
import { PendingJobsComponent } from "./pages/jobs/pending-jobs/pending-jobs.component";
import { ApplicantsInfoComponent } from "./pages/applicants-info/applicants-info.component";
import { AboutUsComponent } from "./pages/static-content/about-us/about-us.component";
import { ApplicantDetailsComponent } from "./modal/applicant/applicant-details/applicant-details.component";
import { OpenJobsComponent } from "./pages/jobs/open-jobs/open-jobs.component";
import { HomepageComponent } from './pages/homepage/homepage.component';
import { CreateJobComponent } from './pages/jobs/create-job/create-job.component';
import { CreatedJobsComponent } from './pages/jobs/created-jobs/created-jobs.component';
import { EmployeeUpdateDetailsComponent } from './modal/employee/employee-update-details/employee-update-details.component';
import { CompanyDetailsComponent } from './pages/company-details/company-details.component';
import { ContactUsComponent } from './pages/static-content/contact-us/contact-us.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { HelpComponent } from "./pages/static-content/help/help.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { ReportComponent } from "./modal/report/report.component";
import { DeleteComponent } from "./modal/delete/delete.component";
import { AccountVerifiedComponent } from "./pages/verifyAccount/account-verified/account-verified.component";
import { PrivacyPolicyComponent } from "./static-content/privacy-policy/privacy-policy.component";


const routes: Routes = [
  { path: "", redirectTo: "homepage", pathMatch: "full" },
  { path: "homepage", component: HomepageComponent },
  // dialog
  { path: "signup", component: SignupComponent },
  { path: "signin", component: SigninComponent },
  { path: "forgot", component: ForgotPasswordComponent },
  { path: "job-search", component: JobSearchComponent },
  { path: "apply-job", component: UserApplyJobComponent },
  { path: "change-pass", component: ChangePasswordComponent },
  // jobs management
  { path: "search-jobs", component: SearchJobsComponent },
  { path: "employee-profile", component: EmployeeProfileComponent },
  { path: "view-jobs", component: ViewJobsComponent },
  { path: "google-map", component: GoogleMapComponent },
  { path: "profile-completion-alert", component: ProfileCompletionAlertComponent },
  { path: "closed-jobs", component: ClosedJobsComponent },
  { path: "open-jobs", component: OpenJobsComponent },
  { path: "approved-jobs", component: ApprovedJobsComponent },
  { path: "completed-jobs", component: CompletedJobsComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'applicant-details', component: ApplicantDetailsComponent },
  { path: "pending-jobs", component: PendingJobsComponent },
  { path: "applicants-info", component: ApplicantsInfoComponent },
  { path: "create-job", component: CreateJobComponent },
  { path: "created-jobs", component: CreatedJobsComponent },
  { path: "update-details", component: EmployeeUpdateDetailsComponent },
  { path: "company-details", component: CompanyDetailsComponent },
  { path: "denied-jobs", component: DeniedJobsComponent },
  { path: "employee-details", component: EmployeeDetailsComponent },
  // static content
  { path: "terms-of-service", component: TermsOfServiceComponent },
  { path: 'contactUs', component: ContactUsComponent },
  { path: "profile", component: ProfileComponent },
  { path: "reset-password/:email", component: ResetPasswordComponent },
  { path: 'help', component: HelpComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'report', component: ReportComponent },
  { path: 'delete', component: DeleteComponent },
  { path: 'verfy-account', component: AccountVerifiedComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
