import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonService } from "src/app/provider/common.service";

@Component({
  selector: "app-employee-details",
  templateUrl: "./employee-details.component.html",
  styleUrls: ["./employee-details.component.scss"],
})
export class EmployeeDetailsComponent implements OnInit {
  viwejobs: any;
  constructor(public dialog: MatDialog, public commonService: CommonService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.viewApplyJob()
  }

  // View Apply Job API Integration
  viewApplyJob() {
    let apiReqUrl = `jobs/viewJobsByUser?id=${this.data.dataKey}`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.viwejobs = res.result;
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    })
  }
}
