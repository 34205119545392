<!-- signup Modal -->
<mat-card class="mat-elevation-z0 bdr mat-dialog-container" aria-hidden="true">
    <div class="main">
        <div class="top-text">
            <mat-card-title class="sign-text">Sign Up
            </mat-card-title>
        </div>
        <div class="input-field">
            <form [formGroup]="signupForm">
                <!-- email field -->
                <input type="text" class="user-email" placeholder="Email" formControlName='email' maxlength="60" autocomplete="new-password">
                <!-- validations -->
                <div class="error" *ngIf="signupForm.get('email').hasError('required') && (signupForm.get('email').touched ||signupForm.get('email').dirty)">
                    *Email id is required.</div>
                <div class="error" *ngIf="signupForm.get('email').hasError('pattern')&& (signupForm.get('email').touched||signupForm.get('email').dirty)">
                    *Please enter valid email id.
                </div>
                <!-- number Field  -->
                <input type="text" class="user-number" (keypress)="service.numberOnly($event)" placeholder="Phone" name="hidden" formControlName='number' mask="(000) 000 0000" maxlength="17" autocomplete="new-password">
                <!-- validation -->
                <div class="error" *ngIf="signupForm.get('number').hasError('required') && (signupForm.get('number').touched || signupForm.get('number').dirty)">
                    *Number is required.</div>
                <div class="error" *ngIf="signupForm.get('number').hasError('pattern') && (signupForm.get('number').touched || signupForm.get('number').dirty)">
                    *Please enter valid number.
                </div>
                <!-- password Field -->
                <input type="password" class="user-name" [type]="toggleEye ? 'password' : 'text'" placeholder="Password" formControlName='password' maxlength="32" autocomplete="new-password">
                <!-- eye Password Protector -->
                <mat-icon matSuffix (click)="toggleEye = !toggleEye">{{toggleEye ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <!-- validation -->
                <div class="error" *ngIf="signupForm.get('password').hasError('required') && (signupForm.get('password').touched ||signupForm.get('password').dirty)">
                    *Password is required.</div>
                <div class="error" *ngIf="signupForm.get('password').hasError('pattern') && (signupForm.get('password').touched||signupForm.get('password').dirty)">
                    Password should have minimum 8 characters, at least 1 uppercase latter, 1 lowercase latter, 1 number and 1 special character.</div>

                <!-- confirm Password Field -->
                <input type="password" class="user-password" [type]="toggleEye1 ? 'password' : 'text'" placeholder="Confirm Password" formControlName='confirmPassword' maxlength="32" autocomplete="new-password">
                <!-- eye Password Protector -->
                <mat-icon matSuffix (click)="toggleEye1 = !toggleEye1">{{toggleEye1 ? 'visibility_off' : 'visibility'}}
                </mat-icon>

                <div class="error" *ngIf="(signupForm.controls['password'].value!==(signupForm.controls['confirmPassword'].value) && (signupForm.get('confirmPassword').touched || signupForm.get('confirmPassword').dirty))">
                    *Password and confirm password should be match.</div>

                <select class="user-select" formControlName="userType">
                    <option value="" class="opt">Select User Type</option>
                    <option value="Hirer" class="opt">Employer/Contractor</option>
                    <option value="Applicant" class="opt">Applicant</option>
                    <option value="3rd_party_contractor" class="opt">Sub-Contractor</option>
                </select>
                <!-- button -->
                <div class="down-button">
                    <button mat-dialog-close="true" class="cancel">Cancel</button>
                    <button class="submit" (click)="onSignUp()" [disabled]="signupForm.invalid">Submit</button>
                </div>
            </form>
        </div>

        <div class="term">
            <a (click)="navigateToTermsOfServices()">Terms of Service</a>
        </div>
    </div>
</mat-card>