<div class="search-inputs">
    <!-- <app-advertisment></app-advertisment> -->

    <div class="mat-input-fields">
        <mat-form-field appearance="none" class="mat-field-first">
            <input matInput type="search" placeholder="City" (input)="urlRoute()" [(ngModel)]="city" class="search-input" autocomplete="off" ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"
                maxlength="60" />
        </mat-form-field>
        <mat-form-field appearance="none" class="mat-field-first">
            <input matInput type="search" placeholder="Distance In Miles" [(ngModel)]="distance" class="search-input" maxlength="5" />
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="searchJob()">Search</button>
        <button mat-raised-button color="primary" (click)="reset()">Reset</button>
    </div>
    <div *ngIf="jobId" class="result-search d-flex justify-content-left">
        <p class="seraching-result">Search Result : {{jobId}} </p>
        <p class="seraching-result" *ngIf="subjobId"><span class="sub-type"> | {{subjobId}}</span></p>
    </div>
    <!-- <mat-form-field *ngIf="jobId" appearance="none" class="mat-field-second">
    <input matInput type="search" placeholder="Job type : Sub job type" [(ngModel)]="job_subJobType"
      class="search-input" />
  </mat-form-field> -->
    <!-- <div class="result">
    <h1>Result</h1>
  </div> -->
    <div class="sort-type-search">
        <h1 class="sort-type">Sort type</h1>
        <mat-form-field appearance="none" class="mat-field">
            <mat-select class="input-fields" value="All">
                <mat-option [value]="types" *ngFor="let types of search_type" (click)="sortData(types)">
                    {{ types }}
                </mat-option>
            </mat-select>
            <mat-divider vertical class="divider"></mat-divider>
        </mat-form-field>
    </div>
</div>
<div class="search-job-table">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-describedby="search-job">

        <!-- job type Column -->
        <ng-container matColumnDef="jobType">
            <th id="" mat-header-cell *matHeaderCellDef class="job-type-search">Job type</th>
            <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)">
                {{ element?.jobType?.categoryName || '---' }}
            </td>
        </ng-container>

        <!-- sub type Column -->
        <ng-container matColumnDef="subType">
            <th id="" mat-header-cell *matHeaderCellDef class="jobsub-type-search">Equipment Type</th>
            <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)" class="jobsub-type-search">
                {{ element?.subJobType?.subCatName || '---' }}
            </td>
        </ng-container>

        <!-- equipment Column -->
        <ng-container matColumnDef="equipment">
            <th id="" mat-header-cell *matHeaderCellDef>Company Name</th>
            <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)">
                {{ element?.hirerDetails?.companyName ||'---' }}
            </td>
        </ng-container>

        <!-- Duration Column -->
        <ng-container matColumnDef="Duration">
            <th id="" mat-header-cell *matHeaderCellDef>
                Duration

            </th>
            <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)" class="durat-det">
                {{ element?.duration || '--'}}
            </td>
        </ng-container>

        <!-- start date column -->
        <ng-container matColumnDef="startDate">
            <th id="" mat-header-cell *matHeaderCellDef>
                start Date

            </th>
            <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)">
                {{ (element?.startDate | date :'MM/dd/yyyy, hh:mm a') || '---'}}
            </td>
        </ng-container>

        <!-- city Column -->
        <ng-container matColumnDef="city">
            <th id="" mat-header-cell *matHeaderCellDef>city</th>
            <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)">
                {{ element?.city || '---'}}
            </td>
        </ng-container>

        <!-- payment type Column -->
        <ng-container matColumnDef="paymentType">
            <th id="" mat-header-cell *matHeaderCellDef>payment type</th>
            <td mat-cell *matCellDef="let element" (click)="applyJobDialog(element?.id,element?.isApplied)">
                {{ element?.paymentTypes?.paymentName || '---' }}
            </td>
        </ng-container>

        <!-- payment Column -->
        <ng-container matColumnDef="payment">
            <th id="" mat-header-cell *matHeaderCellDef>
                payment

            </th>
            <td mat-cell *matCellDef="let element">
                {{ (element?.payment | currency:'USD') || '---'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="apply">
            <th id="" mat-header-cell *matHeaderCellDef> </th>

            <td mat-cell *matCellDef="let element">
                <p *ngIf="commonService.isLoggedIn() && element.isApplied== true " style="color: rgb(7, 163, 7);">Submitted</p>
                <button *ngIf="commonService.isLoggedIn() && element.isApplied == false " mat-raised-button color="primary" (click)="applyJobDialog(element?.id,element?.isApplied)">Submit</button>
                <span *ngIf="!commonService.isLoggedIn()"></span>
            </td>

        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <!-- <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="changePage($event)" aria-label="Select page">
    </mat-paginator> -->
    <mat-paginator class="serach-job-page-hit" (page)="pageEvent = $event" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="changePage($event)" aria-label="Select page">
    </mat-paginator>
</div>