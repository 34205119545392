<div class="wrapper-content">
    <!-- <app-advertisment></app-advertisment> -->
    <div class="title">
        <h1>Created Job Status</h1>
    </div>
    <div class="search-job-table">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngFor="let item of viewJobsData" aria-describedby="view-jobs">
            <!-- job type Column -->
            <ng-container matColumnDef="jobType">
                <th id="" mat-header-cell *matHeaderCellDef>
                    Job type
                    <img src="/assets/images/dropdown.png" alt="" class="dropdown-image" />
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ item?.jobType?.categoryName || '---'}}
                </td>
            </ng-container>

            <!-- Duration Column -->
            <ng-container matColumnDef="Duration">
                <th id="" mat-header-cell *matHeaderCellDef>
                    Duration
                    <img src="/assets/images/dropdown.png" alt="" class="dropdown-image" />
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ item?.duration || '---'}}
                </td>
            </ng-container>

            <!-- start date column -->
            <ng-container matColumnDef="startDate">
                <th id="" mat-header-cell *matHeaderCellDef>
                    Start Date
                    <img src="/assets/images/dropdown.png" alt="" class="dropdown-image" />
                </th>
                <td mat-cell *matCellDef="let element">{{ (item?.startDate | date :'MM/dd/yyyy, hh:mm a') || '---'}}</td>
            </ng-container>

            <!-- city Column -->
            <ng-container matColumnDef="city">
                <th id="" mat-header-cell *matHeaderCellDef>
                    City
                    <img src="/assets/images/dropdown.png" alt="" class="dropdown-image" />
                </th>
                <td mat-cell *matCellDef="let element">{{ item?.city || '---'}}</td>
            </ng-container>

            <!-- payment type type Column -->
            <ng-container matColumnDef="paymentType">
                <th id="" mat-header-cell *matHeaderCellDef>
                    Payment Type
                    <img src="/assets/images/dropdown.png" alt="" class="dropdown-image" />
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ item?.paymentTypes?.paymentName || '---'}}
                </td>
            </ng-container>

            <!-- payment Column -->
            <ng-container matColumnDef="payment">
                <th id="" mat-header-cell *matHeaderCellDef>
                    Payment
                    <img src="/assets/images/dropdown.png" alt="" class="dropdown-image" />
                </th>
                <td mat-cell *matCellDef="let element">{{ item?.payment || '---'}}</td>
            </ng-container>

            <!-- applicants Column -->
            <ng-container matColumnDef="applicants">
                <th id="" mat-header-cell *matHeaderCellDef>Applicants</th>
                <td mat-cell *matCellDef="let element">{{ item?.applicants || '---'}}</td>
            </ng-container>

            <!-- view Column -->
            <ng-container matColumnDef="view">
                <th id="" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="table-data">
                    <button mat-raised-button color="primary" (click)="navigateToEmployeeDetails()">
            View
          </button>
                    <button (click)="map()" mat-button color="primary" class="map">
            Map
          </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event" (page)="changePage($event)" aria-label="Select page">
        </mat-paginator>
    </div>
</div>