<!-- start main-container -->
<div class="main-container wrapper-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" fxLayout.sm="column" fxLayout.xs="column">
    <div class="heading">
        <h1><strong>Profile</strong></h1>
    </div>
    <!-- start form  -->
    <!-- start company details form -->
    <form action="" class="company-details" [formGroup]="profileForm">
        <div class="user-profile">
            <div class="image-box">
                <img *ngIf="image!='null'" [src]="image || 'assets/images/avatar 1.png'" class="profile-pic" alt="">
                <img *ngIf="image=='null'" src="assets/images/avatar 1.png" class="profile-pic" alt="">
                <label class="upload-label">
          <input type="file" class="hidden" accept=".png, .jpg, .jpeg" (change)="uploadImg($event)"
            autocomplete="off" />
          <mat-icon aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
        </label>
            </div>
        </div>
        <div class="user-type-deatils">
            <h1 class="text-center"><strong>User Type : {{myAccount | split}}</strong></h1>
        </div>
        <span *ngIf="commonService.header() == 'Hirer'">
      <div class="company" fxLayout="column">
        <mat-label><strong>Company Name</strong></mat-label>
        <input placeholder="XYZ Ltd" class="input-field" formControlName="company_name" type="text" matInput
          autocomplete="off" />
      </div>
      <div class="validations">
        <span *ngIf="
          this.profileForm.controls['company_name'].invalid &&
          (this.profileForm.controls['company_name'].dirty ||
            this.profileForm.controls['company_name'].touched)
        ">
          *Company name is required.
        </span>
</div>
</span>
<div class="company" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
    <mat-label><strong>First Name</strong></mat-label>
    <input placeholder="John" class="input-field" formControlName="first_name" type="text" matInput autocomplete="off" />
</div>
<div class="validations">
    <span *ngIf="
          this.profileForm.controls['first_name'].errors?.required &&
          (this.profileForm.controls['first_name'].dirty ||
            this.profileForm.controls['first_name'].touched)
        ">
        *First name is required.
      </span>

</div>
<div class="company" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
    <mat-label><strong>Last Name</strong></mat-label>
    <input placeholder="Doe" class="input-field" formControlName="last_name" type="text" matInput autocomplete="off" />
</div>
<div class="validations">
    <span *ngIf="
          this.profileForm.controls['last_name'].errors?.required &&
          (this.profileForm.controls['last_name'].dirty ||
            this.profileForm.controls['last_name'].touched)
        ">
        *Last name is required.
      </span>
</div>
<div class="company" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
    <mat-label><strong>Email</strong></mat-label>
    <input placeholder="johndoe@gmail.com" formControlName="email" class="input-field" type="email" matInput readonly autocomplete="off" />
</div>
<div class="validations">
    <span *ngIf="
          this.profileForm.controls['email'].errors?.required &&
          (this.profileForm.controls['email'].dirty ||
            this.profileForm.controls['email'].touched)
        ">
        *Email id is required.
      </span>
    <span *ngIf="this.profileForm.controls['email'].errors?.pattern">
        *Please enter valid email id.
      </span>
</div>
<div class="company" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
    <mat-label><strong>Phone</strong></mat-label>
    <input placeholder="(248)001-1234" formControlName="phone" mask="(000) 000 0000" class="input-field" type="text" matInput (keypress)="commonService.numberOnly($event)" autocomplete="off" />
</div>
<div class="validations">
    <span *ngIf="
          this.profileForm.controls['phone'].invalid &&
          (this.profileForm.controls['phone'].dirty ||
            this.profileForm.controls['phone'].touched)
        ">
        *Phone number is required.
      </span>
</div>
<!-- certification -->
<div *ngIf="commonService.header() != 'Hirer'" class="company" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
    <mat-label><strong>Certification</strong></mat-label>
    <input placeholder="Certificate" formControlName="certficate" class="input-field" type="text" matInput autocomplete="off" />

</div>
<!-- zip code -->
<div class="company" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
    <mat-label><strong>Zip Code</strong></mat-label>
    <input placeholder="248001" formControlName="zipcode" class="input-field" type="text" matInput maxlength="10" (input)="getZipCode()" autocomplete="off" />
    <i *ngIf="!zipCodeValidStatus" class="fa fa-close ico1"></i>
    <i *ngIf="zipCodeValidStatus" class="fa fa-check ico"></i>
</div>
<div class="validations">
    <span *ngIf="
          this.profileForm.controls['zipcode'].invalid &&
          (this.profileForm.controls['zipcode'].dirty ||
            this.profileForm.controls['zipcode'].touched)
        ">
        *Zipcode is required.
      </span>

</div>
<div class="company" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
    <span *ngIf="commonService.header() != 'Hirer' && commonService.isLoggedIn()">
        <mat-label><strong>Radius Search</strong></mat-label>
        <div class="input-field">
          <input class="input-field" placeholder="20" formControlName="raduisSearch" type="text" matInput
            (keypress)="commonService.numberOnly($event)" autocomplete="off" />

          <mat-form-field appearance="none" class="input-value">
            <mat-select [(value)]="selected" class="input-fill">
              <mat-option *ngFor="let distances of distance" [value]="distances.value">
                {{ distances.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="validations">
          <span *ngIf="
              this.profileForm.controls['raduisSearch'].invalid &&
              (this.profileForm.controls['raduisSearch'].dirty ||
                this.profileForm.controls['raduisSearch'].touched)
            ">
            *Radius is required.
          </span>

</div>
</span>


<div class="company" id="password" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
    <mat-label><strong>Password</strong></mat-label>
    <input placeholder="*********" class="input-field" formControlName="password" type="password" matInput autocomplete="off">
    <a (click)="changePasswordDialog()">Change Password</a>
</div>
<div class="validations" id="validations-message">
    <span *ngIf="(this.profileForm.controls['password'].errors?.required && (this.profileForm.controls['password'].dirty || this.profileForm.controls['password'].touched) )">
          *Password is required.
        </span>

</div>
</div>

</form>
<div *ngIf="commonService.header() == 'Hirer' && commonService.isLoggedIn()" class="button text-center col-md-12">
    <button mat-raised-button class="create-btn" [disabled]="!zipCodeValidStatus" color="primary" (click)="verifyPassword()">
      Update
    </button>
</div>
<!-- end company details form -->
<!-- end form  -->
</div>
<!-- end main-container -->

<div *ngIf="commonService.header() != 'Hirer' && commonService.isLoggedIn()" class="main-occupation" fxLayout.sm="column" fxLayout.xs="column">
    <hr *ngIf="commonService.header() != 'Hirer' && commonService.isLoggedIn()" />
    <div class="occupation-contatiner" fxLayout.sm="column" fxLayout.xs="column">
        <div class="inside-occupation">
            <h1>Occupation</h1>
        </div>
        <form action="" [formGroup]="profileForm">
            <div class="blocks upper">
                <div class="details" fxLayout="column">
                    <mat-label class="occupation"><strong>Job Field</strong></mat-label>
                    <mat-form-field appearance="none" class="input-field">

                        <mat-select formControlName="job_field">
                            <mat-option (click)="subCatField(item?.id)" [value]="item?.categoryName" *ngFor="let item of jobfield">
                                {{item?.categoryName}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="details" fxLayout="column" fxLayoutAlign="center" fxLayoutAlign.sm="center" fxLayoutAlign.xs="center" fxLayout.sm="row" fxLayout.xs="row">
                    <mat-label class="occupation"><strong>Expertise</strong></mat-label>
                    <mat-form-field appearance="none" class="input-field">

                        <mat-select formControlName="skills">
                            <mat-option [value]="item?.subCatName" *ngFor="let item of skill">
                                {{item?.subCatName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="blocks inner">
                <div fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
                    <mat-label class="occupation"><strong>Years of Experience</strong></mat-label>
                    <input placeholder="2" formControlName="year" maxlength="2" class="input-field" type="text" matInput (keypress)="commonService.numberOnly($event)" autocomplete="off" />
                </div>

                <div fxLayout="column" fxLayoutAlign="center" fxLayout.sm="column" fxLayout.xs="column">
                    <mat-label class="occupation"><strong>Equipment</strong></mat-label>
                    <input placeholder="equipment" formControlName="equipment" class="input-field" type="text" matInput autocomplete="off" />
                </div>

            </div>

        </form>

    </div>
    <div *ngIf="commonService.header() != 'Hirer' && commonService.isLoggedIn()" class="button text-center col-md-12">
        <button mat-raised-button class="create-btn" [disabled]="!zipCodeValidStatus" color="primary" (click)="verifyPassword()">
      Update
    </button>
    </div>
</div>