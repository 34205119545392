import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/provider/common.service";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {

  expandedIndex = 0;
  result: any
  pageName: any
  currentPage: any = 1

  pageSize = 5;
  total

  constructor(private service: CommonService) { }

  ngOnInit(): void {
    this.faq()
  }
  faq() {
    let url = `static/faqList?page=${this.currentPage}&limit=${this.pageSize}`

    this.service.showSpinner()
    this.service.getApi(url, 0).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.result = res["result"]["rows"]
        this.total = res["result"]["count"]
        this.pageName = res["result"]["type"]
        this.pageName = String(this.pageName).split(/(?=[A-Z])/).join(" ")
        this.service.hideSpinner()
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    }
    )
  }
  changePage(event) {
    this.pageSize = event.pageSize
    this.currentPage = event.pageIndex + 1
    this.faq()
  }

}
