import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignupComponent } from 'src/app/modal/auth/signup/signup.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/provider/common.service';
import { JobSearchComponent } from 'src/app/modal/job-search/job-search.component';
import { SigninComponent } from 'src/app/modal/auth/signin/signin.component';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  images: any;
  temp: any = [];
  userType = localStorage.getItem("")
  constructor(public rout: Router, public dialog: MatDialog, public service: CommonService) { }
  // myid=1
  ngOnInit(): void {


  }



  navigateToSearchJob(id: any) {
    if (this.service.header() == 'Hirer') {
      this.rout.navigate(['/created-jobs'])
    }
    else {
      // this.rout.navigate(['/search-jobs'])
      this.openSearchDialog()
    }

  }
  // open search dialog
  openSearchDialog() {
    // let dialogConfig = { hasBackdrop: false, skipHide: true };
    const dialogRef = this.dialog.open(JobSearchComponent, {
      width: "530px",
      height: "390px",
      autoFocus: false
    });
  }
  signupDialog() {
    const dialogRef = this.dialog.open(SignupComponent,
      {
        width: '474px',
        height: '540px',
        autoFocus: false
      });
  }

   // open signin dialog
   openSignInDialog() {
     if(!this.service.isLoggedIn()){

 
    let dialogConfig = { hasBackdrop: false, skipHide: true };
    const dialogRef = this.dialog.open(SigninComponent, {
      width: "540px",
      height: "370px",
      autoFocus: false

    });
  }
  else{
    this.rout.navigate(['/employee-profile'])
  }
    // this.dialog.afterAllClosed
  }
}
