<!-- start basic search header -->
<mat-toolbar class="header" *ngIf="!commonService.isLoggedIn()">
    <button mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
    <img src="assets/images/menu.png" alt="">
  </button>
    <div class="heading" fxLayoutAlign="end" routerLink='/homepage'>
        <img *ngIf="headerLogo" mat-card-xs-image [src]="headerLogo" class="headerimg" alt="Blue Collar Connect">
        <h1 *ngIf="!headerLogo">Blue Collar Connect</h1>
    </div>
    <span class="fill-remaining-space"></span>
    <div fxLayout="row" fxShow="false" fxShow.gt-sm class="upper-header">
        <button mat-button (click)="openSearchDialog()"><span>Basic Search</span></button>

        <button mat-button routerLink="/homepage" routerLinkActive="active"><span routerLinkActive="active">Home</span></button>
        <button mat-button (click)="openSignInDialog()"><span>Login</span></button>
    </div>
    <mat-menu x-position="before" #menu="matMenu" class="upper-header">
        <button mat-button class="header-button" (click)="openSearchDialog()"><span>Basic Search</span></button>

        <button mat-button class="header-button" routerLink="/homepage"><span>Home</span></button>
        <button mat-button class="header-button" (click)="openSignInDialog()"><span>Login</span></button>
    </mat-menu>
</mat-toolbar>

<mat-toolbar class="header" *ngIf="commonService.header() == 'Hirer' && commonService.isLoggedIn()">
    <button mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
    <img src="assets/images/menu.png" alt="">
  </button>
    <div class="heading" fxLayoutAlign="end" routerLink='/homepage'>
        <img *ngIf="headerLogo" [src]="headerLogo" class="headerimg" alt="Blue Collar Connect">
        <h1 *ngIf="!headerLogo">Blue Collar Connect</h1>

    </div>
    <span class="fill-remaining-space"></span>
    <div fxLayout="row" fxShow="false" fxShow.gt-sm class="upper-header">
        <button mat-button routerLink='/created-jobs' routerLinkActive="active"><span routerLinkActive="active">My Jobs</span></button>
        <button mat-button routerLink="/create-job" routerLinkActive="active"><span routerLinkActive="active">Create Jobs</span></button>
        <button mat-button routerLink='/profile-completion-alert' routerLinkActive="active"><span routerLinkActive="active">Dashboard</span></button>
        <button mat-button routerLink="/profile" routerLinkActive="active"><span routerLinkActive="active">Profile</span></button>
        <button mat-button>
      <!-- <img [src]="headerDetail?.profilePicc ||'assets/images/Mask.png'" class="image-rossie"  alt=""> -->
      <img *ngIf="headerDetail?.profilePic!='null'" [src]="headerDetail?.profilePic || 'assets/images/avatar 1.png'"
        class="image-rossie" alt="">
      <img *ngIf="headerDetail?.profilePic=='null'" src="assets/images/avatar 1.png" class="image-rossie" alt="">
      <span>{{headerDetail?.firstName || '---'}} {{headerDetail?.lastName || '---'}} </span>
    </button>
        <button mat-button (click)="logOut()" routerLinkActive="active"><span routerLinkActive="active">Logout</span></button>
    </div>
    <mat-menu x-position="before" #menu="matMenu" class="mobile upper-header">
        <button mat-button class="header-button">
      <!-- <img class="Mask" [src]="headerDetail?.profilePic||'assets/images/Mask.png'" alt=""> -->
      <img *ngIf="headerDetail?.profilePic!='null'" [src]="headerDetail?.profilePic || 'assets/images/avatar 1.png'"
        class="Mask" alt="">
      <img *ngIf="headerDetail?.profilePic=='null'" src="assets/images/avatar 1.png" class="Mask" alt="">
    </button>
        <div class="header-text">
            <span>{{headerDetail?.firstName || '---' }} {{headerDetail?.lastName || '---'}}</span>
        </div>
        <button class="logout header-button" mat-button (click)="logOut()" color="primary"><span>Logout</span></button>
        <hr class="line">
        <button mat-button class="header-button" routerLink='/created-jobs'><span>My Jobs</span></button>
        <button mat-button class="header-button" routerLink="/create-job"><span>Create Jobs</span></button>
        <button mat-button class="job header-button" (click)="navigateToProfileComplitationAlert()"><span>Dashboard</span></button>
        <button mat-button class="job header-button" routerLink="/profile"><span>Profile</span></button>
    </mat-menu>
</mat-toolbar>

<mat-toolbar class="header" *ngIf="commonService.header() == 'Applicant' && commonService.isLoggedIn()">
    <button mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
    <img src="assets/images/menu.png" alt="">
  </button>
    <div class="heading" fxLayoutAlign="end" routerLink='/homepage'>
        <img *ngIf="headerLogo" [src]="headerLogo" class="headerimg" alt="Blue Collar Connect">
        <h1 *ngIf="!headerLogo">Blue Collar Connect</h1>

    </div>
    <span class="fill-remaining-space"></span>
    <div fxLayout="row" fxShow="false" fxShow.gt-sm class="upper-header">
        <button mat-button (click)="openSearchDialog()"><span>Search Jobs</span></button>
        <button mat-button routerLink='/profile-completion-alert' routerLinkActive="active"><span routerLinkActive="active">Dashboard</span></button>
        <button mat-button routerLink="/profile" routerLinkActive="active"><span routerLinkActive="active">Profile</span></button>
        <button mat-button>
      <img *ngIf="headerDetail?.profilePic!='null'" [src]="headerDetail?.profilePic || 'assets/images/avatar 1.png'"
        class="image-rossie" alt="">
      <img *ngIf="headerDetail?.profilePic=='null'" src="assets/images/avatar 1.png" class="image-rossie" alt="">
      <span>{{headerDetail?.firstName || '---' }} {{headerDetail?.lastName || '---'}}</span>
    </button>
        <button mat-button (click)="logOut()"><span>Logout</span></button>
    </div>
    <mat-menu x-position="before" #menu="matMenu" class="mobile" class="upper-header">
        <button mat-button class="header-button">
      <img *ngIf="headerDetail?.profilePic!='null'" [src]="headerDetail?.profilePic || 'assets/images/avatar 1.png'"
        class="Mask" alt="">
      <img *ngIf="headerDetail?.profilePic=='null'" src="assets/images/avatar 1.png" class="Mask" alt="">
    </button>
        <div class="header-text">
            <span>{{headerDetail?.firstName || '---' }} {{headerDetail?.lastName || '---'}}</span>
        </div>
        <button class="logout header-button" mat-button (click)="logOut()" color="primary"><span>Logout</span></button>
        <hr class="line">
        <button mat-button class="job header-button" (click)="openSearchDialog()"><span>Search Jobs</span></button>
        <button mat-button class="job header-button" (click)="navigateToProfileComplitationAlert()"><span>Dashboard</span></button>
        <button mat-button class="job header-button" routerLink="/profile"><span>Profile</span></button>
    </mat-menu>
</mat-toolbar>
<mat-toolbar class="header" *ngIf="commonService.header() == '3rd_party_contractor' && commonService.isLoggedIn() ">
    <button mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
    <img src="assets/images/menu.png" alt="">
  </button>
    <div class="heading" fxLayoutAlign="end" routerLink='/homepage'>
        <img *ngIf="headerLogo" [src]="headerLogo" class="headerimg" alt="Blue Collar Connect">
        <h1 *ngIf="!headerLogo">Blue Collar Connect</h1>

    </div>
    <span class="fill-remaining-space"></span>
    <div fxLayout="row" fxShow="false" fxShow.gt-sm class="upper-header">
        <button mat-button (click)="openSearchDialog()"><span>Search Jobs</span></button>
        <button mat-button (click)="navigateToProfileComplitationAlert()"><span>Dashboard</span></button>
        <button mat-button routerLink="/profile"><span>Profile</span></button>
        <button mat-button>
      <img *ngIf="headerDetail?.profilePic!='null'" [src]="headerDetail?.profilePic || 'assets/images/avatar 1.png'"
        class="image-rossie" alt="">
      <img *ngIf="headerDetail?.profilePic=='null'" src="assets/images/avatar 1.png" class="image-rossie" alt="">
      <span>{{headerDetail?.firstName || '---' }} {{headerDetail?.lastName || '---'}}</span>
    </button>
        <button mat-button (click)="logOut()"><span>Logout</span></button>
    </div>
    <mat-menu x-position="before" #menu="matMenu" class="mobile" class="upper-header">
        <button mat-button class="header-button">
      <img *ngIf="headerDetail?.profilePic!='null'" [src]="headerDetail?.profilePic || 'assets/images/avatar 1.png'"
        class="Mask" alt="">
      <img *ngIf="headerDetail?.profilePic=='null'" src="assets/images/avatar 1.png" class="Mask" alt="">
    </button>
        <div class="header-text">
            <span>{{headerDetail?.firstName || '---' }} {{headerDetail?.lastName || '---'}}</span>
        </div>
        <button class="logout header-button" mat-button (click)="logOut()" color="primary"><span>Logout</span></button>
        <hr class="line">
        <button mat-button class="job header-button" (click)="openSearchDialog()"><span>Search Jobs</span></button>
        <button mat-button class="job header-button" (click)="navigateToProfileComplitationAlert()"><span>Dashboard</span></button>
        <button mat-button class="job header-button" routerLink="/profile"><span>Profile</span></button>
    </mat-menu>
</mat-toolbar>
<!-- end create-job header -->