import { Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "src/app/provider/common.service";
import { DeleteComponent } from "src/app/modal/delete/delete.component";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { UserApplyJobComponent } from "src/app/modal/user-apply-job/user-apply-job.component";

@Component({
  selector: "app-denied-jobs",
  templateUrl: "./denied-jobs.component.html",
  styleUrls: ["./denied-jobs.component.scss"],
})
export class DeniedJobsComponent implements OnInit {
  displayedColumns: string[] = [
    "JobType",
    "Duration",
    "Start_date",
    "City",
    "Payment_Type",
    "Payment",
    "blank",
    "delete",
  ];
  dataSource: any = [];
  currentPage: number = 0;
  itemPerPage: number = 25;
  length: any;
  pageSize = 25;
  pageEvent: PageEvent;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  constructor(private route: Router, private service: CommonService, public dialog: MatDialog) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.deniedListJobs()

  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  map(id) {
    this.route.navigate(["/google-map"], { queryParams: { Id: id } });
  }
  returnPage() {
    this.route.navigate(["/profile-completion-alert"]);
  }

  deleteDialog(id) {
    let dialogConfig = { hasBackdrop: false, skipHide: true };
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: "430px",
      height: "290px",
      autoFocus: false,
      data: { dataKey: 'job', isSuccesfullyDeleted: false, url: "user/deleteDeniedJobs?id=" + id }

    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deniedListJobs()
      }
    });
  }
  detailDialog(id) {
    const dialogRef = this.dialog.open(UserApplyJobComponent, {
      width: '450px',
      data: { dataKey: id, openJob: "openJobHirer" },
      height: '515px'
    });

  }
  deniedListJobs() {
    let url = "user/deniedJobList?page=" + (this.currentPage + 1) + "&limit=" + this.itemPerPage
    this.service.showSpinner()
    this.service.getApi(url, 1).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.dataSource = res["result"]["rows"]
        this.length = res["result"]["count"]
        this.service.hideSpinner()
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    }
    )
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {


    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }
  changePage(event) {
    this.currentPage = this.pageEvent.pageIndex
    this.itemPerPage = this.pageEvent.pageSize
    this.deniedListJobs()
  }

}
