import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Address } from "ngx-google-places-autocomplete/objects/address";

import { DialogData } from "src/app/pages/jobs/created-jobs/created-jobs.component";
import { CommonService } from "src/app/provider/common.service";

@Component({
  selector: "app-employee-update-details",
  templateUrl: "./employee-update-details.component.html",
  styleUrls: ["./employee-update-details.component.scss"],
})
export class EmployeeUpdateDetailsComponent implements OnInit {
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  updateForm: FormGroup
  jobDetail: any = []
  selected = 'two';
  latitude: any;
  jobStatus: any;
  longitude: any;
  jobList: any = []
  closeId: any;
  options: any[];
  subJobList: any = []
  // durationType: any = []
  paymentType: any = []
  individual: boolean = false;
  company: boolean = false;
  showmodal: boolean = false
  myDate = new Date()
  todayDate: any = new Date().toISOString().slice(0, 16)

  constructor(public dialog: MatDialog, public service: CommonService,
    public dialogRef: MatDialogRef<EmployeeUpdateDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public date: DatePipe) { }

  ngOnInit(): void {
    // console.log(this.myDate);

    this.updateForm = new FormGroup({
      quantity: new FormControl(""),
      notes: new FormControl(""),
      jobType: new FormControl("", Validators.required),
      jobSubType: new FormControl(""),
      apply: new FormControl("", Validators.required),
      // equipmentType: new FormControl("", Validators.required),
      duration: new FormControl(""),
      startDate: new FormControl(this.myDate,[Validators.required]),
      paymentType: new FormControl("", Validators.required),
      // durationType: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      payment: new FormControl(""),
    })
    this.getJobList()
    this.getJobDetail()
    // this.getDurationType();
    this.getPaymentType()
  }


  public handleAddressChange(address: Address) {
    this.options = []
    this.longitude = address.geometry.location.lng()
    this.latitude = address.geometry.location.lat()
    // console.log('lat ', this.latitude, 'long', this.longitude);
    this.options.push(address.formatted_address);
  }
  showOptions(applyType) {
    // this.individual = applyType == "INDIVIDUAL";
    // this.company = applyType == "COMPANY";
    this.individual = applyType == "INDIVIDUAL";
    this.company = applyType == "COMPANY";
  }

  jobData: any;
  subJobCategoryGet: any
  getJobDetail() {
    let url = "jobs/viewJobs?id=" + this.data.jobId
    this.service.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      this.jobData = res.result
      console.log(this.jobData)
      console.log(this.jobData.jobType.categoryName)
      // check if category blocked or not
      let jobCatIndex = this.jobList.findIndex(x => x.id === this.jobData.jobId);
      console.log(jobCatIndex);
      if (jobCatIndex) {

        this.jobCategoryGet = true
      }
      // check if sub category blocked or not
      let subJobCatIndex = this.jobList.findIndex(x => x.id === this.jobData.subJobId);
      console.log(subJobCatIndex);
      if (subJobCatIndex) {

        this.subJobCategoryGet = true
      }


      console.log(this.jobCategoryGet);
      console.log(this.subJobCategoryGet);

      if (res["statusCode"] == 200) {
        var a = new Date(res["result"]["startDate"]).toLocaleTimeString('en-US',
          { timeZone: 'UTC', hour12: false, hour: 'numeric', minute: 'numeric' }
        );
      

        let str = String(a);
        // str = str.substring(0, str.length - 3);
        str = str.trim()
        if (str.includes('24') ) {
          // str = "0" + str
        
          str = str.replace('24','00')
        }
        

        let date = this.date.transform(res["result"]["startDate"], 'yyyy-MM-dd') + "T" + str

        this.updateForm.patchValue({
          // jobType: res["result"]["jobType"]["categoryName"],
          // jobSubType: res["result"]["subJobType"] ? res["result"]["subJobType"]["subCatName"] : '',
          jobType: res["result"]["jobId"],
          // jobSubType: res["result"]["subJobId"] ? res["result"]["subJobId"] : '',
          // durationType: res["result"]["durationTypes"]["durationName"],
          // durationType: res["result"]["durationTypeId"],
          duration: res["result"]["duration"],
          city: res["result"]["city"],
          // location: res["result"]["location"],
          // price : res["result"]["location"]
          startDate: date,

          // equipmentType: res["result"]["equipmentType"],
          quantity: res["result"]["quantity"],
          // paymentType: res["result"]["paymentTypes"]["paymentName"],
          paymentType: res["result"]["paymentTypeId"],
          payment: res["result"]["payment"],
          notes: res["result"]["notes"],
        })
        this.jobDetail = res["result"]["hirerDetails"]
        this.jobStatus = res["result"]["jobStatus"]
        // if (String(res["result"]["applyType"]).toLowerCase() == 'individual') {
        //   this.individual = true
        // }
        // else {
        //   this.company = true
        // }
        // if (String(res["result"]["accountType"]).toLowerCase() == 'individual') {
        if (String(res["result"]["accountType"]).toLowerCase() == 'applicant') {
          this.individual = true
        }
        else {
          this.company = true
        }

        // console.log(res["result"]["accountType"])
        this.getSubJobList(res["result"]["jobId"])
        this.updateForm.patchValue({
          jobSubType: res["result"]["subJobId"] ? res["result"]["subJobId"] : '',
        })

        // latitude longitude patch
        this.latitude = res.result.latitude ? res.result.latitude : 0
        this.longitude = res.result.longitude ? res.result.longitude : 0

        this.closeId = res["result"]["id"]
        this.service.hideSpinner()
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    })
  }
  jobCategoryGet: any
  getJobList() {
    let url = "jobs/categoryList";
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.jobList = res["result"];
        this.service.hideSpinner();
        // this.service.successToast(res["responseMessage"]);


      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    });
  }

  getSubJobList(id) {
    console.log(id)
    this.subJobList = []
    // this.updateForm.setValue('jobSubType':)
    // this.updateForm.patchValue({ jobSubType: '' })
    this.updateForm.controls['jobSubType'].reset(); // reset the form fields on job type change

    let url = "jobs/subCategoryList?id=" + id;
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.subJobList = res["result"];
        console.log(this.subJobList)
        // this.getDurationType();
        this.service.hideSpinner();
        // this.service.successToast(res["responseMessage"]);
      } else {
        this.subJobList = []
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    }, (error: any) => {
      this.subJobList = []
    });
  }
  // getDurationType() {
  //   let url = "jobs/durationTypeList";
  //   this.service.showSpinner();
  //   this.service.getApi(url, 1).subscribe((res) => {
  //     if (res["statusCode"] == 200) {
  //       this.durationType = res["result"];
  //       // this.getPaymentType();
  //       this.service.hideSpinner();
  //       // this.service.successToast(res["responseMessage"]);
  //     } else {
  //       this.service.hideSpinner();
  //       this.service.errorToast(res["responseMessage"]);
  //     }
  //   });
  // }
  getPaymentType() {
    let url = "jobs/paymentTypeList";
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.paymentType = res["result"];

        this.service.hideSpinner();
        // this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    });
  }
  onUpdate() {
    if (this.individual) {
      // this.updateJobDetail('jobs/updateJobsForIndividual?id=', 'INDIVIDUAL')
      this.updateJobDetail('jobs/updateJobsForIndividual?id=', 'Applicant')

    } else {
      // this.updateJobDetail('jobs/updateJobsForCompany?id=', 'COMPANY')
      this.updateJobDetail('jobs/updateJobsForCompany?id=', '3rd_party_contractor')

    }
  }
  updateJobDetail(url, applytype) {
    var date = new Date(this.updateForm.value.startDate).toString()

    date = date.replace("GMT+0530", "UTC")
    const data = {
      "jobId": this.updateForm.value.jobType,
      // "jobSubType": this.updateForm.value.jobSubtype,
      "subJobId": this.updateForm.value.jobSubType,
      "accountType": applytype,
      "notes": this.updateForm.value.notes,
      "duration": this.updateForm.value.duration,
      // "durationTypeId": this.updateForm.value.durationType,
      // "equipmentType": this.updateForm.value.equipmentType,
      // "paymentType": this.updateForm.value.paymentName,
      "paymentTypeId": this.updateForm.value.paymentType,
      "city": String(this.updateForm.value.city),
      "latitude": this.latitude,
      "longitude": this.longitude,
      // extra key add 
      "payment": this.updateForm.value.payment,
      "quantity": String(this.updateForm.value.quantity ? this.updateForm.value.quantity : 0),

      startDate: new Date(date).toISOString(),
    }
    console.log(data);
    this.service.showSpinner();
    this.service.putApi(url + this.data.jobId, data, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.showmodal = true
        this.service.hideSpinner()
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    }, (err: any) => {
      this.service.errorToast(err.error.responseMessage);
    })
  }
  openDialog() {
    const dialogRef = this.dialog.open(EmployeeUpdateDetailsComponent, {
      width: "600px",
      height: "fit-content",
      autoFocus: false,
    });
  }

  closejobs(closeId) {
    let url = "jobs/closeJob";
    let data = {
      "jobId": closeId
    }
    this.service.showSpinner();
    this.service.postApi(url, data, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.paymentType = res["result"];

        this.service.hideSpinner();
        // this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    });
  }
  countChar() {
    return String(this.updateForm.value.notes).length;
  }
}

