import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event?) {

    this.screenWidth = window.innerWidth;
    if (localStorage.getItem('userType') == 'Hirer' && window.innerWidth < 768) {
      this.showClass = true
    }
    else {
      this.showClass = false
    }

  }
  screenWidth: any
  footerLogo: any;
  userType: any
  showClass: boolean = false
  constructor(private rout: Router, public commonService: CommonService) {
    this.screenWidth = window.innerWidth
    this.onResize();
  }

  ngOnInit(): void {
    this.getSocialLinks()
    this.getFooterLogo()
    this.userType = localStorage.getItem('userType')
  }
  navigateToHomePage() {
    this.rout.navigate(['/homepage'])
  }
  getFooterLogo() {
    let url = "user/viewLogo";
    this.commonService.showSpinner();
    this.commonService.getApi(url, 0).subscribe((res: any) => {
      if (res["statusCode"] == 200) {

        this.footerLogo = res["result"]["image"];
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
      }
    });
  }
  socialLinksList : any  = []
  getSocialLinks(){
    let url = "admin/listSocialLink"
    this.commonService.getApi(url,0).subscribe((res)=>{
      if(res["statusCode"] == 200){
        this.socialLinksList = res["result"]["rows"]
      }
    })
  }
}
